<template>
  <v-tooltip
    top
    v-if="date"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        no-gutters
        v-bind="attrs"
        v-on="on"
        @click="toggleDateFormat"
      >
        <timeago
          v-if="date_format.ago"
          :datetime="date"
          :auto-update="60"
          class="align-self-center"
        />
        <span
          v-else
          class="align-self-center"
        >{{ date_full }}
          <template v-if="showtime">
            <br
              v-if="breakline"
            >{{ time_full }}
          </template>
        </span>
      </span>
    </template>
    <span>
      <span v-if="!date_format.ago">{{ date_ago }}</span>
      <span v-else>
        {{ date_full }}
        <template v-if="showtime">
          <br>{{ time_full }}
        </template>
      </span>
    </span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    date: {
      type: [Date, Number, String],
      default: null
    },
    showtime: {
      type: Boolean,
      default: false
    },
    breakline: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('setting', [
      'date_format'
    ]),
    date_full () {
      return this.moment(this.date).format('YYYY-MM-DD')
    },
    time_full () {
      return this.moment(this.date).format('HH:mm:ss')
    },
    date_ago () {
      return this.moment(this.date).fromNow()
    }
  },
  methods: {
    ...mapActions('setting', [
      'toggleDateFormat'
    ])
  }
}
</script>

<style>

</style>
