import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))
/* global moment */

var requestTimeout = null

// initial state
const state = {
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: [
      'acc_bank',
      'acc_type',
      'acc_number',
      'acc_currency',
      'acc_open_date',
      'acc_close_date',
      // 'acc_status',
      'acc_remarks',
      'action'
    ],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      // 'orderBy.ascending': 1,
      // 'orderBy.column': '',
      orderBy: {
        ascending: true,
        column: null
      },
      columnsClasses: {
        file_name: 'col-xs-2',
        description: 'col-xs-4',
        create_date: 'col-xs-1',
        file_mime: 'col-xs-2',
        action: 'col-xs-1'
      },
      sortable: [
        'acc_bank',
        'acc_type',
        'acc_number',
        'acc_currency',
        'acc_open_date',
        'acc_close_date',
        // 'acc_status',
        'acc_remarks'
      ],
      filterable: [
        'acc_bank',
        'acc_type',
        'acc_number',
        'acc_currency',
        'acc_open_date',
        'acc_close_date',
        // 'acc_status',
        'acc_remarks'
      ],
      filterByColumn: true,
      dateColumns: ['acc_open_date', 'acc_close_date']
    }
  }
}

// getters
const getters = {
  options (state, unusedGetters, unusedRootState, rootGetters) {
    const { i18n } = rootGetters
    const { initFilters, initialPage, perPage, orderBy, columnsClasses, sortable, filterable, filterByColumn, dateColumns } = state.listing.options

    return ({ company_id, person_id }) => ({
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction (request) {
        clearTimeout(requestTimeout)
        return new Promise((resolve, reject) => {
          requestTimeout = setTimeout(async () => {
            try {
              var criteria = {
                // params: {
                where: ((query) => {
                  var _where = { }

                  if (query.acc_bank) _where.acc_bank = { contains: query.acc_bank }
                  if (query.acc_type) _where.acc_type = { contains: query.acc_type }
                  if (query.acc_number) _where.acc_number = { contains: query.acc_number }
                  if (query.acc_currency) _where.acc_currency = { contains: query.acc_currency }
                  if (query.acc_remarks) _where.acc_remarks = { contains: query.acc_remarks }
                  if (query.acc_open_date) {
                    _where.and = [
                      { acc_open_date: { '>': new Date(query.acc_open_date.start).getTime() } },
                      { acc_open_date: { '<': new Date(query.acc_open_date.end).getTime() } }
                    ]
                  }
                  if (query.acc_close_date) {
                    _where.and = [
                      { acc_close_date: { '>': new Date(query.acc_close_date.start).getTime() } },
                      { acc_close_date: { '<': new Date(query.acc_close_date.end).getTime() } }
                    ]
                  }

                  return _where
                })(request.query),
                sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                limit: request.limit,
                skip: request.limit * (request.page - 1)
                // }
              }

              Promise.all([
                company_id ? client.corporate.bank_information.count(company_id, criteria) : (person_id ? client.individual.bank_information.count(person_id, criteria) : client.bank_information.count(criteria)),
                company_id ? client.corporate.bank_information.findWhere(company_id, criteria) : (person_id ? client.individual.bank_information.findWhere(person_id, criteria) : client.bank_information.findWhere(criteria))
                // API.get( company_id ? `company_v2/${company_id}/bank_infos/count` : ( person_id ? `naturalperson_v2/${person_id}/bank_infos/count` : 'bank_infos/count'), criteria),
                // API.get( company_id ? `company_v2/${company_id}/bank_infos` : ( person_id ? `naturalperson_v2/${person_id}/bank_infos` : 'bank_infos' ), criteria)
              ])
                .then(([{ data: { count } }, { data }]) => {
                  resolve({ data, count })
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (error) {
              reject(error)
            }
          }, 1500)
        }).catch((e) => {
          this.dispatch('error', e)
        })
      },
      columnsClasses,
      sortable,
      headings: {
        acc_bank: i18n.t('company.details.form_bankinfo.acc_bank.label'),
        acc_type: i18n.t('company.details.form_bankinfo.acc_type.label'),
        acc_number: i18n.t('company.details.form_bankinfo.acc_number.label'),
        acc_currency: i18n.t('company.details.form_bankinfo.acc_currency.label'),
        acc_open_date: i18n.t('company.details.form_bankinfo.acc_open_date.label'),
        acc_close_date: i18n.t('company.details.form_bankinfo.acc_close_date.label'),
        // acc_status: i18n.t('company.details.form_bankinfo.acc_status.label'),
        acc_remarks: i18n.t('company.details.form_bankinfo.acc_remarks.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      dateColumns,
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      },
      listColumns: {}
    })
  }
}

// actions
const actions = {
  create (unusedContext, { capacity_type, capacity_id, acc_bank, acc_type, acc_number, acc_currency, acc_open_date, acc_close_date, acc_status, acc_remarks }) {
    const dateStringToInt = (date_string) => {
      date_string = date_string || 0 // default to 0
      return typeof date_string === 'string' && date_string && !isNaN(new Date(date_string)) ? new Date(date_string).getTime() : date_string
    }

    return client.bank_information.create({
      [capacity_type === 1 ? 'company' : 'person']: capacity_id,
      acc_bank,
      acc_type,
      acc_number,
      acc_currency,
      acc_open_date: dateStringToInt(acc_open_date),
      acc_close_date: dateStringToInt(acc_close_date),
      acc_status,
      acc_remarks
    })
  },
  update (context, { id, acc_bank, acc_type, acc_number, acc_currency, acc_open_date, acc_close_date, acc_status, acc_remarks }) {
    const dateStringToInt = (date_string) => {
      date_string = date_string || 0 // default to 0
      return typeof date_string === 'string' && date_string && !isNaN(new Date(date_string)) ? new Date(date_string).getTime() : date_string
    }

    return client.bank_information.update(id, {
      // [capacity_type == 1 ? 'company' : 'person']: capacity_id,
      acc_bank,
      acc_type,
      acc_number,
      acc_currency,
      acc_open_date: dateStringToInt(acc_open_date),
      acc_close_date: dateStringToInt(acc_close_date),
      acc_status,
      acc_remarks
    })
  },
  remove (context, payload) {
    // return API.delete(`/v2/bankinfo/${payload}`)
    return client.bank_information.destroy(payload)
  }
}

// mutations
const mutations = {
  'bankinfoTable/SET_DATA' (state, data) {
    console.log('bankinfoTable/SET_DATA', data)
  },
  'bankinfoTable/PAGINATION' (state, data) {
    console.log('bankinfoTable/PAGINATION', data)
  },
  'bankinfoTable/FILTER' (state, data) {
    console.log('bankinfoTable/FILTER', data)
  },
  'bankinfoTable/SORTED' (state, data) {
    console.log('bankinfoTable/SORTED', data)
  },
  'bankinfoTable/LOADING' (state, payload) {
    console.log('bankinfoTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.create_date) {
      state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start)
      state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    state.listing.requestLoading = true
  },
  'bankinfoTable/LOADED' (state, data) {
    console.log('bankinfoTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'bankinfoTable/LIMIT' (state, data) {
    console.log('bankinfoTable/LIMIT', data)
  },
  'bankinfoTable/ERROR' (state, data) {
    console.log('bankinfoTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'bankinfoTable/ROW_CLICK' (state, data) {
    console.log('bankinfoTable/ROW_CLICK', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
