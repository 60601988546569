// initial state
import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))

const state = {
  records: []
}

// getters
const getters = {
  risks: state => {
    return state.records
  }
}

// actions
const actions = {
  lists: () => {

  },
  get_risk_info: (unusedContext, payload) => {
    return client.screen.riskScreening(payload)
  }
}

// mutations
const mutations = {
  SET_RECORDS (state, payload) {
    state.records = payload
  }
  // SET_SORT_COLUMN (state, payload) {
  //     state.orderby = payload
  // },
  // SET_SORT_DIRECTION (state, payload) {
  //     state.order = payload
  // },
  // SET_PAGE_NUM (state, payload) {
  //     state.page_num = payload
  // },
  // ['companyTable/PAGINATION'] (state, data) {
  //     console.log('companyTable/PAGINATION', data);
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
