<template>
  <v-menu
    offset-y
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row
        no-gutters
        class="align-center"
        style="white-space:nowrap"
      >
        <v-col cols="auto">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            :icon="!!selected"
            small
          >
            <v-icon
              :left="!selected"
              :icon="!!selected"
            >
              mdi-calendar
            </v-icon>
            {{ !selected ? props.placeholder : '' }}
          </v-btn>
          <template v-if="selected">
            <label>{{ `${selected.start} ~ ${selected.end}` }}</label>
            <v-icon
              icon
              @click.prevent="clear"
            >
              mdi-close
            </v-icon>
          </template>
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-row no-gutters>
        <v-col>
          <v-list dense>
            <v-list-item
              link
              v-for="(range, key) in ranges"
              :key="key"
              @click="search({ start: range[0], end: range[1] })"
            >
              <v-list-item-title>{{ key }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link @click="show_custom = !show_custom">
                            <v-list-item-title>Custom</v-list-item-title>
                        </v-list-item> -->
          </v-list>
        </v-col>
        <v-col v-if="show_custom">
          <v-date-picker
            v-model="custom_range"
            range
          />
          <v-row
            no-gutters
            class="align-self-center"
          >
            <span class="my-3">{{ `${custom_range[0] || ''} ~ ${custom_range[1] || ''}` }}</span>
            <v-spacer />
            <!-- <v-btn small class="my-3" @click="show_custom = false">Cancel</v-btn> -->
            <v-btn
              small
              color="primary"
              class="ma-3"
              @click="search({start: custom.start, end: custom.end})"
              :disabled="custom.start == null || custom.end == null"
            >
              {{ $t('listing.table.datefilter.apply') }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'VtDateFilter',
  props: {
    props: {
      type: Object,
      default: null
    }
  },
  data () {
    var table = this.getTable()
    var { start, end } = table.query[this.props.column]

    return {
      table,
      show_custom: true,
      custom: {
        start: start || null,
        end: end || null
      }
    }
  },
  computed: {
    ranges () {
      return {
        [this.$t('listing.table.datepicker.today')]: [this.moment(), this.moment()],
        [this.$t('listing.table.datepicker.yesterday')]: [this.moment().subtract(1, 'days'), this.moment().subtract(1, 'days')],
        [this.$t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [this.moment().subtract(6, 'days'), this.moment()],
        [this.$t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [this.moment().subtract(29, 'days'), this.moment()],
        [this.$t('listing.table.datepicker.this_month')]: [this.moment().startOf('month'), this.moment().endOf('month')],
        [this.$t('listing.table.datepicker.last_month')]: [this.moment().subtract(1, 'month').startOf('month'), this.moment().subtract(1, 'month').endOf('month')]
      }
    },
    selected () {
      if (this.table) {
        var values = this.table.query[this.props.column]
        if (values !== '') {
          if (values.start && values.end) {
            return {
              start: this.moment(values.start).format('YYYY/MM/DD'),
              end: this.moment(values.end).format('YYYY/MM/DD')
            }
          }
        }
      }

      return null
    },
    custom_range: {
      get () {
        // console.log(arguments);
        return [
          this.custom.start ? this.custom.start.format('YYYY-MM-DD') : null,
          this.custom.end ? this.custom.end.format('YYYY-MM-DD') : null
        ].filter(Boolean)
      },
      set (_value) {
        var [start, end] = _value || []
        this.custom.start = start ? this.moment(start) : null
        this.custom.end = end ? this.moment(end) : null
      }
    }
  },
  methods: {
    getTable (component) {
      var _component = component || this
      // console.log(_component, _component.name);
      if (_component.name) {
        return _component
      } else if (_component.$parent) {
        return this.getTable(_component.$parent)
      }
    },
    search ({ start, end }) {
      // console.log(this.getTable());
      // console.log(this.props.column, start.toDate(), end.toDate());
      this.custom.start = start
      this.custom.end = end

      // var table = this.getTable();
      if (this.table) {
        var _query = this.table.vuex ? JSON.parse(JSON.stringify(this.table.query)) : this.table.query
        var _search = (query, e) => {
          return this.table.source === 'client' ? this.table.search(this.table.data, e) : this.table.serverSearch(query, e)
        }

        _query[this.props.column] = {
          start: start.startOf('D').format('YYYY-MM-DD HH:mm:ss'),
          end: end.endOf('D').format('YYYY-MM-DD HH:mm:ss')
        }

        this.table.updateState('query', _query)
        _search(_query, { target: { name: this.table._getColumnName(this.props.column), value: _query[this.props.column] } })
      }
    },
    clear () {
      var _query = this.table.vuex ? JSON.parse(JSON.stringify(this.table.query)) : this.table.query
      var _search = (query, e) => {
        return this.table.source === 'client' ? this.table.search(this.table.data, e) : this.table.serverSearch(query, e)
      }

      this.custom.start = null
      this.custom.end = null

      if (_query[this.props.column] === '') return

      _query[this.props.column] = ''

      this.table.updateState('query', _query)
      _search(_query, { target: { name: this.table._getColumnName(this.props.column), value: _query[this.props.column] } })
    }
  }
  // mounted () {
  //     // console.log(this);
  // }
}
</script>
