import moment from 'moment'

export default (Vue) => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '< 1 min', // "a few seconds",
      ss: '%d seconds',
      m: '1 min', // "a minute",
      mm: '%d mins', // "%d minutes",
      h: '1 hour', // "an hour",
      hh: '%d hrs', // "%d hours",
      d: '1 day', // "a day",
      dd: '> 1 day',
      w: '1 week', // "a week",
      ww: '%d weeks',
      M: '1 month', // "a month",
      MM: '%d months',
      y: '1 year', // "a year",
      yy: '%d years'
    }
  })

  moment.updateLocale('zh-tw', {
    relativeTime: {
      future: '%s後',
      past: '%s前',
      s: '< 1 分鐘', // '幾秒',
      ss: '%d 秒',
      m: '1 分鐘',
      mm: '%d 分鐘',
      h: '1 小時',
      hh: '%d 小時',
      d: '1 天',
      dd: '> 1 天',
      M: '1 個月',
      MM: '%d 個月',
      y: '1 年',
      yy: '%d 年'
    }
  })

  // moment.relativeTimeThreshold('ss'); // 44
  moment.relativeTimeThreshold('s', 60) // 45
  // moment.relativeTimeThreshold('m');  // 45
  // moment.relativeTimeThreshold('h');  // 22
  // moment.relativeTimeThreshold('d');  // 26
  // moment.relativeTimeThreshold('w');  // null (disabled)
  // moment.relativeTimeThreshold('M');  // 11

  Vue.prototype.moment = moment
}
