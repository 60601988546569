<template>
  <div
    data-cy="bank_info_container"
  >
    <loading
      :active.sync="listing_existing_loading"
      :is-full-page="false"
    />
    <div
      data-cy="bank_info_table"
    >
      <v-server-table
        class="pt-0"
        ref="tableBankInfo"
        :name="'bankinfo/bankinfoTable'"
        :columns="listing_existing_columns"
        :options="listing_existing_options({ [parentType == 'company' ? 'company_id' : (parentType == 'person' ? 'person_id' : 'all')]: parentId })"
      >
        <div slot="afterFilterWrapper">
          <v-dialog width="800">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                class="mr-3"
                outlined
                v-on="on"
                :loading="add_dialog.loading"
                data-cy="add_bank_info_btn"
              >
                {{ $t('company.details.form_bankinfo.buttons.add') }}
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card :loading="add_dialog.loading">
                <v-card-title>
                  <div
                    data-cy="bank_info_title"
                  >
                    {{ $t('company.details.form_bankinfo.buttons.add') }}
                  </div>
                  <v-spacer />
                  <v-btn
                    @click="() => {
                      if ($refs.acc_open_date.correctDate && $refs.acc_close_date.correctDate) {
                        add_dialog.loading= true
                        $store.dispatch('bankinfo/create', { ...add_dialog.bank_info, capacity_type: parentType == 'company' ? 1 : 2, capacity_id: parentId }).then(() => {

                        }).catch(() => {

                        }).finally(() => {
                          add_dialog.loading = false;
                          dialog.value = false;
                          add_dialog.bank_info = getBankInfoForm();
                          $refs.tableBankInfo.getData();
                        })
                      }
                    }"
                    outlined
                    color="primary"
                    data-cy="bank_info_save_btn"
                  >
                    {{ $t('company.details.buttons.save') }}
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="add_dialog.bank_info.acc_bank"
                        :label="$t('company.details.form_bankinfo.acc_bank.label')"
                        data-cy="acc_bank"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="add_dialog.bank_info.acc_type"
                        :label="$t('company.details.form_bankinfo.acc_type.label')"
                        data-cy="acc_type"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="add_dialog.bank_info.acc_number"
                        :label="$t('company.details.form_bankinfo.acc_number.label')"
                        data-cy="acc_number"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="add_dialog.bank_info.acc_currency"
                        :label="$t('company.details.form_bankinfo.acc_currency.label')"
                        data-cy="acc_currency"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <InputDate
                        v-model="add_dialog.bank_info.acc_open_date"
                        :label="$t('company.details.form_bankinfo.acc_open_date.label')"
                        ref="acc_open_date"
                        data-cy="acc_open_date"
                      />
                      <InputDate
                        v-model="add_dialog.bank_info.acc_close_date"
                        :label="$t('company.details.form_bankinfo.acc_close_date.label')"
                        ref="acc_close_date"
                        data-cy="acc_close_date"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-textarea
                        :label="$t('company.details.form_bankinfo.acc_remarks.label')"
                        counter
                        v-model="add_dialog.bank_info.acc_remarks"
                        auto-grow
                        rows="1"
                        row-height="15"
                        data-cy="acc_remarks"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <div
          slot="acc_open_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.acc_open_date"
            :showtime="false"
          />
        </div>
        <div
          slot="acc_close_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.acc_close_date"
            :showtime="false"
          />
        </div>
        <div
          slot="acc_remarks"
          slot-scope="props"
        >
          <TextareaSafeDisplay :value="props.row.acc_remarks" />
        </div>
        <div
          slot="action"
          slot-scope="props"
        >
          <BankInfoEditDialog
            :bank-info="props.row"
            :parent-type="parentType"
            :parent-id="parentId"
            @updated="() => {
              $refs.tableBankInfo.getData();
            }"
            :data-cy="props.index"
          />
          <PopupDelete
            v-slot:default="{ on, loading }"
            @yes="(done) => {
              $store.dispatch('bankinfo/remove', props.row.id)
                .then(() => {
                  $refs.tableBankInfo.getData();
                })
                .catch((error) => { console.warn(error) })
                .finally(done); // notify popup stop loading and close
            }"
            data-cy="bank_info"
          >
            <v-btn
              icon
              color="error"
              class="ma-1"
              v-on="on"
              :loading="loading"
              :data-cy="`delete_bank_info_btn_${props.index}`"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </PopupDelete>
        </div>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import InputDate from '@/components/forms/InputDate'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'
import BankInfoEditDialog from '@/components/forms/BankInfoEditDialog'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'
import * as helper from '@/store/helper'

export default {
  props: {
    parentId: {
      type: [String, Number],
      required: true
    },
    parentType: {
      type: String,
      required: true,
      default: 'company',
      validator: function (value) {
        // The value must match one of these strings
        return ['company', 'person'].indexOf(value) !== -1
      }
    }
  },
  components: {
    DateFormat,
    InputDate,
    TextareaSafeDisplay,
    BankInfoEditDialog,
    PopupDelete
  },
  computed: {
    ...mapState('bankinfo', {
      listing_existing_loading: state => state.listing.requestLoading,
      listing_existing_columns: state => state.listing.columns
    }),
    ...mapGetters('bankinfo', {
      listing_existing_options: 'options'
    })
  },
  data () {
    return {
      add_dialog: {
        loading: false,
        bank_info: this.getBankInfoForm()
      }
    }
  },
  methods: {
    ...helper
  },
  mounted () {
  }
}
</script>
