<template>
  <div>
    <loading
      :active.sync="listing_existing_loading"
      :is-full-page="false"
    />
    <v-server-table
      class="pt-0"
      ref="tableExisting"
      :name="'attachment/attachmentTable'"
      :columns="listing_existing_columns"
      :options="listing_existing_options({ [parentType == 'company' ? 'company_id' : (parentType == 'person' ? 'person_id' : (parentType == 'alert' ? 'alert_id' : 'all'))]: parentId })"
    >
      <div slot="afterFilterWrapper">
        <v-btn
          color="primary"
          class="mr-3"
          outlined
          @click="onUploadClick"
          :loading="file_selecting || uploading"
          data-cy="upload_att_btn"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('attachment.listing.buttons.add') }}
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="*/*"
          @change="onFileChanged"
          v-if="file_selecting"
        >
      </div>
      <div
        slot="file_name"
        slot-scope="props"
      >
        <v-checkbox
          v-if="mode == 'selecting'"
          v-model="selected_items_local"
          multiple
          :key="props.row.id"
          :disabled="!!unselectable(props.row)"
          :messages="unselectable(props.row) || ''"
          @change="onSelect"
          :value="props.row.id"
          :label="props.row.file_name"
        >
          <template v-slot:label>
            {{ props.row.file_name }}
          </template>
          <!-- <template v-slot:message>
						{{ unselectable(props.row) }}
					</template> -->
        </v-checkbox>
        <span v-else>{{ props.row.file_name }}</span>
      </div>
      <div
        slot="create_date"
        slot-scope="props"
      >
        <DateFormat
          :date="props.row.create_date"
          :showtime="true"
        />
      </div>
      <div
        slot="description"
        slot-scope="props"
      >
        <TextareaSafeDisplay :value="props.row.description" />
      </div>
      <div
        slot="action"
        slot-scope="props"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="view_document(props.row)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('attachment.listing.buttons.preview') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="download_document(props.row)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('attachment.listing.buttons.download') }}</span>
        </v-tooltip>
      </div>
    </v-server-table>
    <v-dialog
      v-model="dialog_upload"
      width="500"
    >
      <v-card :loading="uploading">
        <v-card-title>
          {{ $t('attachment.listing.buttons.add') }}
          <v-spacer />
          <v-btn
            @click="onUpload"
            outlined
            color="primary"
            :loading="uploading"
          >
            <v-icon left>
              mdi-upload
            </v-icon>
            {{ $t('attachment.listing.buttons.upload') }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              {{ $t('attachment.listing.table.file_name.label') }}
            </v-col>
            <v-col
              cols="auto"
              class="primary--text"
            >
              {{ file_selected ? file_selected.name : '' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                :label="$t('attachment.listing.table.description.label')"
                v-model="file_description"
                auto-grow
                rows="1"
                row-height="15"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'

export default {
  props: {
    parentId: {
      type: [String, Number],
      required: true
    },
    parentType: {
      type: String,
      required: true,
      default: 'all',
      validator: function (value) {
        // The value must match one of these strings
        return ['company', 'person', 'alert', 'all'].indexOf(value) !== -1
      }
    },
    mode: {
      type: String,
      default: 'editing',
      validator: function (value) {
        // The value must match one of these strings
        return ['selecting', 'editing'].indexOf(value) !== -1
      }
    },
    selectedItems: { // for "selecting" mode only
      type: Array,
      default: () => ([])
    },
    unselectable: {
      type: Function,
      default: (unusedItem) => {
        return false
      }
    }
  },
  components: {
    DateFormat,
    TextareaSafeDisplay
  },
  computed: {
    ...mapState('attachment', {
      listing_existing_loading: state => state.listing.requestLoading,
      listing_existing_columns: state => state.listing.columns
    }),
    ...mapGetters('attachment', {
      listing_existing_options: 'options'
    })
  },
  data () {
    return {
      // upload
      file_selected: null,
      file_description: '',
      file_selecting: false,
      uploading: false,
      dialog_upload: false,
      // selecting mode
      selected_items_local: []
    }
  },
  methods: {
    onFileChanged (e) {
      this.file_selected = e.target.files[0]
      console.log(this.file_selected)
      // upload
      if (this.file_selected) {
        this.dialog_upload = true
      }
    },
    onUploadClick () {
      this.file_selecting = true
      this.$nextTick(() => {
        this.file_selected = null
        window.addEventListener('focus', () => { this.file_selecting = false }, { once: true })
        this.$refs.uploader.click()
      })
    },
    onUpload () {
      this.uploading = true
      this.$store.dispatch('attachment/upload', Object.assign({
        file: this.file_selected,
        description: this.file_description
      }, this.parentType !== 'all' ? { [`${this.parentType}_ids`]: this.parentId } : undefined))
        .then((results) => {
          if (results.status === 200 && results.data) {
            this.$refs.tableExisting.getData()

            return this.$notify({
              type: 'success',
              title: 'File Upload',
              text: 'The file uploaded successfully'
            })
          } else {
            this.$notify({
              type: 'error',
              title: 'File Upload',
              text: 'Failed to upload file'
            })
          }
        }).catch((error) => {
          if (error.response) {
            // console.log(error.response)
            this.$notify({
              type: 'error',
              title: 'File Upload',
              text: error.response.data.message || error.message
            })
          } else {
            this.$notify({
              type: 'error',
              title: 'File Upload',
              text: error.message
            })
          }
        }).finally(() => {
          this.file_selected = null
          this.file_description = ''
          this.uploading = false
          this.dialog_upload = false
        })
    },
    onSelect (selected) {
      this.$emit('selected', selected)
      // console.log(arguments);
    },
    view_document (attacmhent) {
      if (attacmhent) {
        window.open(attacmhent.view_path, '_blank')
      }
    },
    download_document (attacmhent) {
      if (attacmhent) {
        window.open(attacmhent.download_path, '_blank')
      }
    }
  },
  mounted () {
    this.selected_items_local = this.selectedItems
  }
}
</script>

<style>

</style>
