import VueRouter from 'vue-router'

export default (Vue, unusedStore) => {
  Vue.use(VueRouter)

  const router = new VueRouter({
    routes: [
      {
        path: '',
        component: () => import('@/components/layouts/Auth'),
        children: [
          { name: 'Login', path: '', component: () => import('@/components/pages/Login') },
          { name: 'ForgotPassword', path: '/ForgotPassword', component: () => import('@/components/pages/ForgotPassword') },
          { name: 'ResetPassword', path: '/ResetPassword', component: () => import('@/components/pages/ResetPassword') }
        ]
      },
      {
        path: '*',
        redirect: { name: 'Login' }
      },
      {
        path: '/sys',
        component: () => import('@/components/layouts/System'),
        children: [
          { name: 'Dashboard', path: '', component: () => import('@/components/pages/Dashboard') },
          { name: 'NaturalPerson', path: 'NaturalPerson', component: () => import('@/components/pages/NaturalPerson') },
          { name: 'NaturalPersonCreate', path: 'NaturalPerson/Info', component: () => import('@/components/pages/NaturalPersonAdd') },
          { name: 'NaturalPersonEdit', path: 'NaturalPerson/Info/:np_id', component: () => import('@/components/pages/NaturalPersonInfo') },
          { name: 'Companies', path: 'Companies', component: () => import('@/components/pages/Companies') },
          { name: 'CompanyCreate', path: 'Companies/Info', component: () => import('@/components/pages/CompanyInfo') },
          { name: 'CompanyEdit', path: 'Companies/Info/:customer_id', component: () => import('@/components/pages/CompanyInfo') },
          { name: 'AlertsHistory', path: 'Alerts', component: () => import('@/components/pages/AlertsHistory') },
          { name: 'AlertCasePool', path: 'Cases', component: () => import('@/components/pages/AlertCasePool') },
          { name: 'AlertCaseDetails', path: 'Cases/:case_id', component: () => import('@/components/pages/AlertCaseDetails') },
          // { name: 'Option', path: 'Option', component: Option },
          { name: 'SingleScan', path: 'SingleScan', component: () => import('@/components/pages/SingleScan') },
          { name: 'SingleScan2', path: 'OnboardingScreening', component: () => import('@/components/pages/SingleScan2') },
          { name: 'BatchScreening', path: 'BatchScreening', component: () => import('@/components/pages/BatchScan') },
          { name: 'BatchScreeningLog', path: 'BatchScreeningLog', component: () => import('@/components/pages/BatchScanLog') },
          { name: 'AuditLog', path: 'AuditLog', component: () => import('@/components/pages/AuditLog') },
          { name: 'Transaction', path: 'Transaction', component: () => import('@/components/pages/OnboardTransaction') },
          { name: 'Transaction2', path: 'Transaction2', component: () => import('@/components/pages/OnboardTransaction2') },
          { name: 'eKYCAlliance', path: 'eKYC-Alliance', component: () => import('@/components/pages/eKycAlliance') },
          { name: 'eKYCAllianceNew', path: 'eKYC-Alliance/New', component: () => import('@/components/pages/eKycAllianceNew') },
          { name: 'eKYCAllianceInfo', path: 'eKYC-Alliance/Info/:ekyc_client_id', component: () => import('@/components/pages/eKycAllianceInfo') },
          { name: 'Import', path: 'Import', component: () => import('@/components/pages/Import') },
          { name: 'Export', path: 'Export', component: () => import('@/components/pages/Export') }
        ]
      },
      {
        path: '/report',
        component: () => import('@/components/layouts/Report'),
        children: [
          { name: 'ReportScreeningEntity', path: 'ScreeningEntity', component: () => import('@/components/reports/ScreeningEntity') },
          { name: 'ReportScreening', path: 'Screening', component: () => import('@/components/reports/Screening') }
        ]
      }
    ]
  })

  router.beforeEach((to, from, next) => {
    // console.log(to, from)

    // if (!store.getters["auth/is_logged_in"] && to.name != 'Login') {
    //   return next({ name: 'Login' });
    // }
    return next()

    // if (store.getters["auth/is_logged_in"]) {
    //   next()
    // }
    // else if (to.name == 'Login') {
    //   next();
    // }
    // else
    //   next({ name: 'Login' });

    // next();
  })

  return router
}
