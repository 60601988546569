import { RBAClient } from 'rba-client'
import moment from 'moment'
const client = RBAClient.getInstance(require('@/api/config'))

// initial state
const state = {
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: [
      'tran_ref',
      'tran_date',
      'names',
      'currency',
      'amount',
      'alert_id',
      'alert_status',
      'action'
    ],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      // 'orderBy.ascending': 1,
      // 'orderBy.column': '',
      orderBy: {
        ascending: true,
        column: null
      },
      requestFunction (request) {
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            try {
              Promise.all([
                request.query.names ? client.name.findWhere({
                  where: {
                    $OR: request.query.names.split(' ').reduce((arr_names, name) => {
                      arr_names.push({ sur: { contains: name } })
                      arr_names.push({ other: { contains: name } })
                      return arr_names
                    }, []),
                    person: { $NotEqual: 0 }
                  },
                  limit: 500,
                  select: 'person'
                }) : null,
                request.query.names ? client.name.findWhere({
                  where: {
                    $OR: request.query.names.split(' ').map(x => {
                      return { other: { contains: x } }
                    }),
                    company: { $NotEqual: 0 }
                  },
                  limit: 500,
                  select: 'company'
                }) : null
              ]).then(async ([naturalperson_search, company_search]) => {
                var criteria = {
                  where: (((query) => {
                    var _where = {}

                    if (naturalperson_search || company_search) _where.or = []
                    if (naturalperson_search) _where.or.push({ person: { in: naturalperson_search.data.map(x => x.person ? x.person.id : 0) } })
                    if (company_search) _where.or.push({ company: { in: company_search.data.map(x => x.company ? x.company.id : 0) } })
                    if (query.currency) _where.currency = query.currency
                    if (query.tran_ref) {
                      _where.tran_ref = {
                        contains: query.tran_ref
                      }
                    }
                    if (query.amount) _where.amount = query.amount
                    if (query.tran_date) {
                      _where.and = [
                        { tran_date: { '>': new Date(query.tran_date.start).getTime() } },
                        { tran_date: { '<': new Date(query.tran_date.end).getTime() } }
                      ]
                    }

                    return _where
                  })(request.query)),
                  sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                  limit: request.limit,
                  skip: request.limit * (request.page - 1),
                  populate: 'person,company,alert_case'
                }
                var count = await client.transaction.count(criteria)
                var results = await client.transaction.findWhere(criteria)

                return {
                  results,
                  count
                }
              })
                .then(async ({ results, count }) => {
                  // content enricher
                  const [
                    { data: all_naturalperson = [] },
                    { data: all_company = [] },
                    { data: all_alert_case = [] }
                  ] = await Promise.all([
                    // enriches the results with the naturalperson name and the company name
                    client.individual.findWhere({ where: { id: { $In: results.data.filter(x => x.person != null).map(x => x.person.id) } }, populate: 'names,idens' }),
                    client.corporate.findWhere({ where: { id: { $In: results.data.filter(x => x.company != null).map(x => x.company.id) } }, populate: 'names' }),
                    // ensure old transaction record still have alert_case record
                    client.alert_case.findWhere({
                      where: {
                        $OR: [
                          { person: { $In: results.data.filter(x => x.person != null && x.alert_case == null).map(x => x.person.id) } },
                          { company: { $In: results.data.filter(x => x.company != null && x.alert_case == null).map(x => x.company.id) } }
                        ]
                      },
                      populate: ''
                    })
                  ])

                  resolve({
                    data: results.data.map(x => {
                      if (x.person) {
                        x.person = all_naturalperson.find(y => y.id === x.person.id) || x.person
                        x.alert_case = all_alert_case.find(y => y.person.id === x.person.id) || x.alert_case
                      } else if (x.company) {
                        x.company = all_company.find(y => y.id === x.company.id) || x.company
                        x.alert_case = all_alert_case.find(y => y.company.id === x.company.id) || x.alert_case
                      }

                      return x
                    }),
                    count: count.data.count
                  })
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (error) {
              reject(error)
            }
          }, 800)
        }).catch((e) => {
          this.dispatch('error', e)
        })
      },
      /* responseAdapter: function(resp) {
        // console.log('responseAdapter', resp);
        return { data: resp.data[0], count: resp.data[1] };
      }, */
      columnsClasses: {
        names: 'col-xs-3',
        currency: 'col-xs-1',
        amount: 'col-xs-2',
        tran_date: 'col-xs-1',
        action: 'col-xs-1'
      },
      sortable: [
        'tran_ref',
        // 'names',
        'currency',
        'amount',
        'tran_date'
      ],
      // headings: {
      //   names: 'Name',
      //   tran_date: 'Transaction Date',
      // },
      filterable: [
        'tran_ref',
        'names',
        'currency',
        'amount',
        'tran_date'
      ],
      filterByColumn: true,
      dateColumns: ['tran_date'],
      dateFormat: 'DD/MMM/YYYY'
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     'Today': [moment(), moment()],
      //     'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //     'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //     'This Month': [moment().startOf('month'), moment().endOf('month')],
      //     'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // }
      // see the options API
    }

  }
}

// getters
const getters = {
  options (state, getters, rootState, rootGetters) {
    const { i18n } = rootGetters
    const { initFilters, initialPage, perPage, orderBy, requestFunction, columnsClasses, sortable, filterable, filterByColumn, dateColumns, dateFormat } = state.listing.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction,
      columnsClasses,
      sortable,
      headings: {
        // coi_number: i18n.t('transaction.details.tran_date.label'),
        names: i18n.t('transaction.listing.name.label'),
        tran_ref: i18n.t('transaction.details.tran_ref.label'),
        tran_date: i18n.t('transaction.details.tran_date.label'),
        currency: i18n.t('transaction.details.currency.label'),
        amount: i18n.t('transaction.details.amount.label'),
        alert_id: i18n.t('transaction.listing.alert_id.label'),
        alert_status: i18n.t('transaction.listing.alert_status.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      dateFormat,
      dateColumns,
      // datepickerOptions,
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     [i18n.t('listing.table.datepicker.today')]: [moment(), moment()],
      //     [i18n.t('listing.table.datepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [moment().subtract(6, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [moment().subtract(29, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
      //     [i18n.t('listing.table.datepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // },
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      }
      // listColumns: {
      //   status: [
      //     {
      //       id: 0,
      //       text: i18n.t('company.details.form.status.options.inactive')// 'Inactive'
      //     },
      //     {
      //       id: 1,
      //       text: i18n.t('company.details.form.status.options.active') // 'Active',
      //       // hide:true
      //     },
      //   ]
      // }
    }
  }
}

// actions
const actions = {
  findOne (unusedContext, unusedPayload) {

  },
  async save ({ unusedContext, dispatch }, payload) {
    var transaction = JSON.parse(JSON.stringify(payload))
    var dateStringToInt = (date_string) => {
      date_string = date_string || 0 // default to 0
      return typeof date_string === 'string' && date_string && !isNaN(new Date(date_string)) ? new Date(date_string).getTime() : date_string
    }

    if (payload.alert && typeof payload.alert === 'object') {
      var alert = await dispatch('alerts/save', payload.alert, { root: true })
      transaction.alert = alert.id
    }

    transaction.tran_date = dateStringToInt(transaction.tran_date)
    transaction.person = (typeof transaction.person === 'object' && transaction.person && transaction.person.id) ? transaction.person.id : transaction.person
    transaction.company = (typeof transaction.company === 'object' && transaction.company && transaction.company.id) ? transaction.company.id : transaction.company

    return transaction.id ? client.transaction.update(transaction.id, transaction) : client.transaction.create(transaction)
  },
  remove (context, payload) {
    return client.transaction.destroy(payload)
  }
}

// mutations
const mutations = {
  'transactionTable/SET_DATA' (state, data) {
    console.log('transactionTable/SET_DATA', data)
  },
  'transactionTable/PAGINATION' (state, data) {
    console.log('transactionTable/PAGINATION', data)
  },
  'transactionTable/FILTER' (state, data) {
    console.log('transactionTable/FILTER', data)
  },
  'transactionTable/SORTED' (state, data) {
    console.log('transactionTable/SORTED', data)
  },
  'transactionTable/LOADING' (state, payload) {
    console.log('transactionTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.tran_date) {
      state.listing.options.initFilters.tran_date.start = moment(state.listing.options.initFilters.tran_date.start)
      state.listing.options.initFilters.tran_date.end = moment(state.listing.options.initFilters.tran_date.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    state.listing.requestLoading = true
  },
  'transactionTable/LOADED' (state, data) {
    console.log('transactionTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'transactionTable/LIMIT' (state, data) {
    console.log('transactionTable/LIMIT', data)
  },
  'transactionTable/ERROR' (state, data) {
    console.log('transactionTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'transactionTable/ROW_CLICK' (state, data) {
    console.log('transactionTable/ROW_CLICK', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
