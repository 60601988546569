<template>
  <!-- <modal name="member_form" height="auto" :scrollable="true" width="70%"> -->
  <v-form
    id="pop-up"
    @submit.prevent="$refs.member.onComplete()"
    :data-cy="dataCy"
  >
    <!-- <v-card white> -->
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
        data-cy="member_detail_title"
      >
        <v-toolbar-title>{{ $t('dialogs.member.title') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          class="ma-2"
          color="primary"
          outlined
          @click="$refs.member.onComplete()"
          v-if="((form.company && form.company.id) || (form.person && form.person.id))"
        >
          {{ $t('dialogs.member.buttons.update') }}
        </v-btn>
        <v-btn
          class="ma-2"
          outlined
          @click="$emit('close')"
          data-cy="member_detail_close_btn"
        >
          {{ $t('dialogs.member.buttons.close') }}
        </v-btn>
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          v-if="!((form.company && form.company.id) || (form.person && form.person.id))"
        >
          <v-radio-group
            v-model="form.is_company"
            mandatory
            row
          >
            <template v-slot:label>
              {{ $t('dialogs.member.form.type.label') }}
            </template>
            <div
              data-cy="person_radio"
            >
              <v-radio
                :label="$t('dialogs.member.form.type.options.person')"
                :value="false"
              />
            </div>
            <div
              data-cy="company_radio"
            >
              <v-radio
                :label="$t('dialogs.member.form.type.options.company')"
                :value="true"
              />
            </div>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          {{ $t('dialogs.member.form.roles.label') }}
          <v-row no-gutters>
            <div
              data-cy="founder_checkbox"
            >
              <v-checkbox
                v-model="form.is_founder"
                :label="$t('dialogs.member.form.roles.options.founder')"
                hide-details
                style="margin: 0 16px 0 0;"
              />
            </div>
            <div
              data-cy="shareholder_checkbox"
            >
              <v-checkbox
                v-model="form.is_shareholder"
                :label="$t('dialogs.member.form.roles.options.shareholder')"
                hide-details
                style="margin: 0 16px 0 0;"
              />
            </div>
            <div
              data-cy="director_checkbox"
            >
              <v-checkbox
                v-model="form.is_director"
                :label="$t('dialogs.member.form.roles.options.director')"
                hide-details
                style="margin: 0 16px 0 0;"
                data-cy="director_checkbox"
              />
            </div>
            <div
              data-cy="secretary_checkbox"
            >
              <v-checkbox
                v-model="form.is_secretary"
                :label="$t('dialogs.member.form.roles.options.secretary')"
                hide-details
                style="margin: 0 16px 0 0;"
              />
            </div>
            <div
              data-cy="auth_rep_checkbox"
            >
              <v-checkbox
                v-model="form.is_auth_rep"
                :label="$t('dialogs.member.form.roles.options.auth_rep')"
                hide-details
                style="margin: 0 16px 0 0;"
              />
            </div>
            <div
              data-cy="significant_controller_checkbox"
            >
              <v-checkbox
                v-model="form.is_significant_controller"
                :label="$t('dialogs.member.form.roles.options.significant_controller')"
                hide-details
                style="margin: 0 16px 0 0;"
              />
            </div>
          </v-row>
        </v-col>
        <v-col cols="12">
          <component
            ref="member"
            :is="form.is_company ? 'Company2' : 'Person2'"
            v-show="form.is_founder || form.is_shareholder || form.is_director || form.is_secretary || form.is_auth_rep || form.is_significant_controller"
            v-bind="form.is_company ? { companyId: form.company.id } : { personId: form.person.id }"
            @onSave="onSave"
            @switchToID="onSwitchToID"
          >
            <div
              slot="roles"
              v-if="$refs.member && !$refs.member.requestLoading && (form.is_shareholder || form.is_director || form.is_secretary || form.is_auth_rep || form.is_significant_controller)"
              @change="checkAllDateFormat()"
            >
              <template v-if="form.is_shareholder">
                <v-card-title
                  data-cy="shareholder_title"
                >
                  {{ $t('dialogs.member.form.roles.options.shareholder') }}
                </v-card-title>
                <v-card-subtitle>{{ $t('dialogs.member.form_share.title') }}</v-card-subtitle>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('dialogs.member.form_share.type.label') }}
                          </th>
                          <th class="text-left">
                            {{ $t('dialogs.member.form_share.shares_num.label') }}
                          </th>
                          <th class="text-left">
                            {{ $t('dialogs.member.form_share.currency.label') }}
                          </th>
                          <th class="text-left">
                            {{ $t('dialogs.member.form_share.shares_amount.label') }}
                          </th>
                          <th class="text-left">
                            {{ $t('dialogs.member.form_share.rights.label') }}
                          </th>
                          <th>
                            <v-btn
                              class="ml-2 mb-2"
                              v-if="(form.shareholder.shares || []).length < 3"
                              @click="addShares(form.shareholder)"
                              data-cy="add_shareholder_share_title"
                            >
                              {{ $t('dialogs.member.form_share.buttons.add') }}
                            </v-btn>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(share, key) in form.shareholder.shares"
                          :key="key"
                        >
                          <td>
                            <v-text-field
                              type="text"
                              v-model="share.type"
                              @blur="mergeSharesIfNeeded"
                              :data-cy="`shareholder_type_share_${key}`"
                            />
                          </td>
                          <td>
                            <v-text-field
                              type="number"
                              v-model="share.shares_num"
                              @blur="mergeSharesIfNeeded"
                              :data-cy="`shareholder_share_num_${key}`"
                            />
                          </td>
                          <td>
                            <InputCurrency
                              v-model="share.currency"
                              @input="mergeSharesIfNeeded"
                              :data-cy="`shareholder_currency_${key}`"
                            />
                          </td>
                          <td>
                            <v-text-field
                              type="number"
                              v-model="share.shares_amount"
                              @blur="mergeSharesIfNeeded"
                              :data-cy="`shareholder_share_amt_${key}`"
                            />
                          </td>
                          <td>
                            <v-textarea
                              counter
                              v-model="share.rights"
                              auto-grow
                              rows="1"
                              row-height="15"
                              :data-cy="`shareholder_share_rights_${key}`"
                            />
                          </td>
                          <td>
                            <v-btn
                              class="ma-2"
                              color="error"
                              @click="form.shareholder.shares.splice(key, 1)"
                              :data-cy="`shareholder_row_remove_btn_${key}`"
                            >
                              {{ $t('dialogs.member.form_share.buttons.remove') }}
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-divider class="my-3" />
              </template>
              <template v-if="form.is_director">
                <v-card-title
                  data-cy="director_title"
                >
                  {{ $t('dialogs.member.form.roles.options.director') }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="4"
                      data-cy="director_type_col"
                    >
                      <v-select
                        :label="$t('dialogs.member.form_director.director_type.label')"
                        v-model="form.director.director_type"
                        :items="Object.values($t('dialogs.member.form_director.director_type.options')).map((v, key) => ({ value: key + 1, text: v }))"
                      >
                        <template
                          v-slot:selection="items"
                        >
                          <div
                            data-cy="selected_director_type"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                        <template
                          v-slot:item="items"
                        >
                          <div
                            :data-cy="`director_type_${items.item.value}`"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                        <!-- :items="[
														{ value: '1', text: 'Director' },
														{ value: '2', text: 'Alternate Director' },
														{ value: '3', text: 'Reserve Director', disabled: form.is_company },
														{ value: '4', text: 'Director & Alternate Director' },
													]">-->
                      </v-select>
                    </v-col>
                    <v-col
                      cols="4"
                      v-if="form.director.director_type == 2"
                      data-cy="dir_type2_alt_col"
                    >
                      <v-select
                        :label="$t('dialogs.member.form_director.alternate_to_id.label')"
                        v-model="form.director.alternate_to_id"
                        :items="[]"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      data-cy="appointment_date_col"
                    >
                      <InputDate
                        :label="$t('dialogs.member.form_director.start_date.label')"
                        v-model="form.director.start_date"
                        data-cy="date_of_appointment"
                        ref="director_start_date"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="4"
                      data-cy="director_consent_col"
                    >
                      <v-select
                        :label="$t('dialogs.member.form_director.consent.label')"
                        v-model="form.director.consent"
                        :items="[
                          { value: 1, text: $t('dialogs.member.form_director.consent.options.1') },
                          { value: 2, text: $t('dialogs.member.form_director.consent.options.2') }
                        ]"
                      >
                        <template
                          v-slot:selection="items"
                        >
                          <div
                            data-cy="selected_consent"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                        <template
                          v-slot:item="items"
                        >
                          <div
                            :data-cy="`consent_type_${items.item.value}`"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      style="max-width:170px"
                      data-cy="director_is_end_col"
                    >
                      <v-checkbox v-model="form.director.is_end_status">
                        <template v-slot:label>
                          <v-row no-gutters>
                            <v-col class="align-self-center">
                              {{ $t('dialogs.member.form_director.is_end_status.label') }}
                            </v-col>
                          </v-row>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col
                      v-if="form.director.is_end_status"
                      data-cy="director_end_reason-col"
                    >
                      <v-select
                        :label="$t('dialogs.member.form_director.end_reason.label')"
                        v-model="form.director.end_reason"
                        :items="[
                          { value: 1, text: $t('dialogs.member.form_director.end_reason.options.1') },
                          { value: 2, text: $t('dialogs.member.form_director.end_reason.options.2') }
                        ]"
                      >
                        <template
                          v-slot:selection="items"
                        >
                          <div
                            data-cy="selected_end_reason"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                        <template
                          v-slot:item="items"
                        >
                          <div
                            :data-cy="`end_reason_${items.item.value}`"
                          >
                            {{ items.item.text }}
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      v-if="form.director.is_end_status"
                      data-cy="director_end_date_col"
                    >
                      <InputDate
                        :label="$t('dialogs.member.form_director.end_date.label')"
                        v-model="form.director.end_date"
                        data-cy="director_end_date"
                        ref="director_end_date"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="my-3" />
              </template>
              <template v-if="form.is_secretary">
                <v-card-title>{{ $t('dialogs.member.form.roles.options.secretary') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <InputDate
                        :label="$t('dialogs.member.form_secretary.start_date.label')"
                        v-model="form.secretary.start_date"
                        ref="secretary_start_date"
                      />
                    </v-col>
                    <v-col cols="4">
                      <InputDate
                        :label="$t('dialogs.member.form_secretary.end_date.label')"
                        v-model="form.secretary.end_date"
                        ref="secretary_end_date"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="my-3" />
              </template>
              <template v-if="form.is_auth_rep">
                <v-card-title>{{ $t('dialogs.member.form.roles.options.auth_rep') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <InputDate
                        :label="$t('dialogs.member.form_auth_rep.start_date.label')"
                        v-model="form.auth_rep.start_date"
                        ref="auth_rep_start_date"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="my-3" />
              </template>
              <template v-if="form.is_significant_controller">
                <v-card-title>{{ $t('dialogs.member.form.roles.options.significant_controller') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        :label="$t('dialogs.member.form_controller.control_nature.label')"
                        v-model="form.significant_controller.control_nature"
                      />
                    </v-col>
                    <v-col cols="4">
                      <InputDate
                        :label="$t('dialogs.member.form_controller.commencement_date.label')"
                        v-model="form.significant_controller.commencement_date"
                        ref="controller_commencement_date"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="my-3" />
              </template>
            </div>
          </component>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>
<script type="text/javascript">
// import Vue from 'vue'
import * as helper from '@/store/helper'
import InputCurrency from '@/components/forms/InputCurrency'
import Person2 from '@/components/forms/Person2'
import Company2 from '@/components/forms/Company2'
import InputDate from '@/components/forms/InputDate'

const form = {
  // type: 2,
  is_company: false,
  is_founder: false,
  is_shareholder: false,
  is_director: false,
  is_secretary: false,
  is_auth_rep: false,
  is_significant_controller: false,
  company: helper.initialCompany(),
  person: helper.initialPerson(),
  director: {
    id: 0,
    director_type: 1,
    capacity_type: 0,
    capacity_id: 0,
    consent: 0,
    alternate_capacity: 0,
    alternate_to_id: 0,
    start_date: null,
    end_date: null,
    end_reason: 0,
    is_end_status: false
  },
  founder: {
    id: 0,
    company_id: 0,
    capacity_type: 0,
    capacity_id: 0,
    shares: []
  },
  shareholder: {
    id: 0,
    company_id: 0,
    capacity_type: 0,
    capacity_id: 0,
    shares: []
  },
  // shares: [],
  secretary: {
    id: 0,
    company_id: 0,
    capacity_type: 0,
    capacity_id: 0,
    start_date: null,
    end_date: null,
    end_reason: 0
  },
  auth_rep: {
    id: 0,
    company_id: 0,
    capacity_type: 0,
    capacity_id: 0,
    start_date: null
  },
  significant_controller: {
    id: 0,
    capacity_type: 0,
    capacity_id: 0,
    control_nature: '',
    commencement_date: null
  }
}

export default {
  components: {
    InputCurrency,
    Person2,
    Company2, // : import('@/components/forms/Company2'),
    InputDate
  },
  props: {
    records: {
      type: Object,
      default: null
    },
    dataCy: {
      type: String,
      default: ''
    }
  },

  watch: {
    records (unusedRecords) {
      // console.log('records', records);
      // if (records) {
      //   Object.assign(this.form, records)
      // } else {
      //   Object.assign(this.form, form)
      // }
      this.resetForm()
    },
    'form.is_founder' (is_founder) {
      this.onCheckedFounder(is_founder)
    },
    'form.is_shareholder' (is_shareholder) {
      this.onCheckedShareholder(is_shareholder)
    },
    'form.is_director' (is_director) {
      this.onCheckedDirector(is_director)
    },
    'form.is_secretary' (is_secretary) {
      this.onCheckedSecretary(is_secretary)
    },
    'form.is_auth_rep' (is_auth_rep) {
      this.onCheckedAuthRep(is_auth_rep)
    },
    'form.is_significant_controller' (is_significant_controller) {
      this.onCheckedController(is_significant_controller)
    }
  },
  data () {
    return {
      form: Object.assign({}, JSON.parse(JSON.stringify(form)))
    }
  },
  computed: {
    is_editing () {
      return (this.form.company || this.form.person).id
    }
    // total_shares () {
    // 	return [
    // 		{
    // 			number_of_shares: '',
    // 			currency: '',
    // 			amount_of_share: '',
    // 			rights: ''
    // 		},
    // 		{
    // 			number_of_shares: '',
    // 			currency: '',
    // 			amount_of_share: '',
    // 			rights: ''
    // 		}
    // 	];
    // }
  },
  methods: {
    ...helper,
    mergeSharesIfNeeded () {
      this.form.shareholder.shares = (this.form.shareholder.shares || []).reduce((shares, share) => {
        for (var i = shares.length - 1; i >= 0; i--) {
          if (shares[i].type.trim().toLowerCase() === share.type.trim().toLowerCase() &&
							shares[i].currency.toLowerCase() === share.currency.toLowerCase() &&
							parseFloat(share.shares_num) > 0 &&
							parseFloat(share.shares_paid_amount) > 0) {
            shares[i].shares_num = parseFloat(shares[i].shares_num) + parseFloat(share.shares_num)
            shares[i].shares_paid_amount = parseFloat(shares[i].shares_paid_amount) + parseFloat(share.shares_paid_amount)

            return shares
          }
        }

        shares.push(share)
        return shares
      }, [])
    },
    prepareDataForSave (object) {
      // Demo: Circular reference
      var o = object

      // Note: cache should not be re-used by repeated calls to JSON.stringify.
      var cache = []
      var results = JSON.stringify(o, function (key, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Duplicate reference found, discard key
            return
          }
          // Store value in our collection
          cache.push(value)
        }
        return value
      })

      cache = null // Enable garbage collection

      return results
    },
    onSave (saved_company_or_person) {
      if (this.form.is_company) {
        this.form.company = saved_company_or_person
      } else {
        this.form.person = saved_company_or_person
      }
      const _form = JSON.parse(JSON.stringify(this.form))
      // console.log(JSON.parse(JSON.stringify(_form)))
      this.$emit('onSaveMember', _form, saved_company_or_person)
      this.$modal.hide(this.$parent.$parent.name)
    },
    onSwitchToID (id) {
      // console.log(arguments);
      if (this.form.is_company) {
        this.form.company.id = id
      } else {
        this.form.person.id = id
      }
    },
    onCheckedFounder (is_founder) {
      if (is_founder) {
        this.form.founder = this.form.founder || {
          id: 0,
          company_id: 0,
          capacity_type: 0,
          capacity_id: 0,
          shares: []
        }
      }
      if (this.is_editing) {
        if (this.form.founder) {
          this.form.founder.is_deleted = !is_founder
        }
      }
    },
    onCheckedShareholder (is_shareholder) {
      if (is_shareholder) {
        this.form.shareholder = this.form.shareholder || {
          id: 0,
          company_id: 0,
          capacity_type: 0,
          capacity_id: 0,
          shares: []
        }
        if (!this.form.shareholder.shares.length) {
          this.addShares(this.form.shareholder)
        }
      }
      if (this.is_editing) {
        if (this.form.shareholder) {
          this.form.shareholder.is_deleted = !is_shareholder
        }
      }
    },
    onCheckedDirector (is_director) {
      if (is_director) {
        this.form.director = this.form.director || {
          id: 0,
          director_type: 1,
          capacity_type: 0,
          capacity_id: 0,
          consent: 0,
          alternate_capacity: 0,
          alternate_to_id: 0,
          start_date: null,
          end_date: null,
          end_reason: 0,
          is_end_status: false
        }
      }
      if (this.is_editing) {
        if (this.form.director) {
          this.form.director.is_deleted = !is_director
        }
      }
    },
    onCheckedSecretary (is_secretary) {
      if (is_secretary) {
        this.form.secretary = this.form.secretary || {
          id: 0,
          company_id: 0,
          capacity_type: 0,
          capacity_id: 0,
          start_date: null,
          end_date: null,
          end_reason: 0
        }
      }
      if (this.is_editing) {
        if (this.form.secretary) {
          this.form.secretary.is_deleted = !is_secretary
        }
      }
    },
    onCheckedAuthRep (is_auth_rep) {
      if (is_auth_rep) {
        this.form.auth_rep = this.form.auth_rep || {
          id: 0,
          company_id: 0,
          capacity_type: 0,
          capacity_id: 0,
          start_date: null
        }
      }
      if (this.is_editing) {
        if (this.form.auth_rep) {
          this.form.auth_rep.is_deleted = !is_auth_rep
        }
      }
    },
    onCheckedController (is_significant_controller) {
      if (is_significant_controller) {
        this.form.significant_controller = this.form.significant_controller || {
          id: 0,
          capacity_type: 0,
          capacity_id: 0,
          control_nature: '',
          commencement_date: null
        }
      }
      if (this.is_editing) {
        if (this.form.significant_controller) {
          this.form.significant_controller.is_deleted = !is_significant_controller
        }
      }
    },
    checkAllDateFormat () {
      let checking = true
      if (this.form.is_director) {
        if (this.$refs.director_start_date !== undefined) {
          if (this.form.director.is_end_status) {
            checking = checking && this.$refs.director_start_date.correctDate && this.$refs.director_end_date.correctDate
          } else {
            checking = checking && this.$refs.director_start_date.correctDate
          }
        }
      }
      if (this.form.is_secretary) {
        if (this.$refs.secretary_start_date !== undefined) {
          checking = checking && this.$refs.secretary_start_date.correctDate && this.$refs.secretary_end_date.correctDate
        }
      }
      if (this.form.is_auth_rep) {
        if (this.$refs.auth_rep_start_date !== undefined) {
          checking = checking && this.$refs.auth_rep_start_date.correctDate
        }
      }
      if (this.form.is_significant_controller) {
        if (this.$refs.controller_commencement_date !== undefined) {
          checking = checking && this.$refs.controller_commencement_date.correctDate
        }
      }
      return checking
    },
    resetForm () {
      if (this.records) {
        Object.assign(this.form, this.records)
      } else {
        Object.assign(this.form, JSON.parse(JSON.stringify(form)))
      }
    }
  },
  mounted () {
    // console.log('MemberDetail', this)
    this.resetForm()
    // if (this.records) {
    //   // console.log(this.records, this);
    //   Object.assign(this.form, this.records)
    //   // this.form = records;
    // } else {
    //   Object.assign(this.form, form)
    // }
    // if (this.company_id) {
    // 	console.log('company_id', company_id)
    // }
  }
}
</script>
<style type="text/css" scoped>
/*.v--modal {
	max-height: 90vh;
	top: 5vh !important;
	overflow: auto !important;
}
.v--modal-overlay {
	z-index: 9999;
}*/
#pop-up {
	/*padding: 5px 30px;*/
	/* height: 92vh; */
	overflow: auto;
    /* display: flex; */
    /* flex-direction: column; */
}
.content {
	/*max-height: calc(90vh - 49px);*/
	overflow: auto;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}
.content-header {
	padding-bottom: 15px;
}
textarea {resize: vertical;}

td, th {vertical-align: top;}
</style>
