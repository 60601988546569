import { RBAClient, AlertStatus, IndividualStatus, CorproateStatus } from 'rba-client'
import moment from 'moment'
const client = RBAClient.getInstance(require('@/api/config'))

const state = {
  search: '',
  orderby: '',
  order: '',
  page: 0,
  limit: 10,
  loading: false,

  batchScan: {
    starting: false,
    stopping: false,
    pausing: false,
    details: {
      id: null,
      is_deleted: false,
      create_date: null,
      updated_date: null,
      status: '',
      is_paused: true,
      is_resumed: false,
      is_completed: false,
      is_failed: false,
      progress: false,
      progress_last_touch: null,
      start_from_person_id: '',
      stop_at_person_id: '',
      current_person_id: '',
      total_person_ids: '',
      start_from_company_id: '',
      stop_at_company_id: '',
      current_company_id: '',
      total_company_ids: ''
    },
    timer: null,
    refreshing: false
  },
  alerts: {
    info: {
      total: 0,
      pending: 0,
      cnt_all_company: 0,
      cnt_all_person: 0,
      bar_chart_data: [],
      donut_chart_data: []
    }
  },
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: [
      'type',
      'initiator',
      'create_date',
      'duration',
      'processedRecords',
      'status',
      'actions'
    ],
    dateColumns: ['create_date'],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      requestFunction (request) {
        console.log(this, request)
        this.requestKeys = request
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            try {
              var criteria = {
                where: (((query) => {
                  var _where = { and: [] }

                  if (query.initiator) _where.initiator = { $Contains: query.initiator }
                  if (query.type) _where.type = query.type
                  switch (query.status) {
                    case 'completed':
                      _where.is_completed = 1
                      break
                    case 'stop':
                      _where.is_paused = 1
                      break
                    case 'failed':
                      _where.is_failed = 1
                      break
                    default:
                      break
                  }
                  // if (query.status != null) _where.status = query.status
                  // if (query.is_completed) _where.is_completed = query.is_completed
                  // if (query.is_paused) _where.is_paused = query.is_paused
                  // if (query.is_failed) _where.is_failed = query.failed
                  // if (query.progress) _where.progress = query.progress

                  if (query.create_date) { // _where.create_date = query.create_date
                    _where.and = [
                      ..._where.and,
                      { create_date: { '>': new Date(query.create_date.start).getTime() } },
                      { create_date: { '<': new Date(query.create_date.end).getTime() } }
                    ]
                  }

                  return _where
                })(request.query)),
                limit: request.limit,
                skip: request.limit * (request.page - 1),
                sort: request.orderBy ? `${request.orderBy} ${request.ascending ? 'ASC' : 'DESC'}` : 'create_date DESC'
              }

              Promise.all([
                client.system_batch_history.count(criteria),
                client.system_batch_history.findWhere(criteria)
              ])
                .then(([{ data: { count } }, { data }]) => {
                  resolve({ data, count })
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (error) {
              reject(error)
            }
          }, 1500)
        })
      },
      columnsClasses: {
        type: 'col-xs-2',
        user: 'col-xs-1',
        create_date: 'col-xs-2',
        duration: 'col-xs-2',
        processedRecords: 'col-xs-2',
        status: 'col-xs-2',
        actions: 'col-xs-1'
      },
      sortable: [
        // 'type',
        // 'user',
        'create_date',
        // 'duration',
        // 'processedRecords',
        'status'
      ],
      filterable: [
        'type',
        'initiator',
        'create_date',
        'status'
      ],
      filterByColumn: true,
      dateColumns: ['create_date']
    }
  },
  ewescreening: {
    screeninglist: [],
    categories: []
  },
  cachedEntityDetails: new Map()
}

// getters
const getters = {
  alerts: state => {
    return state.records
  },
  alertCount: state => {
    return state.alerts.info ? state.alerts.info.total : 0
  },
  loading: state => {
    return state.loading
  },
  pendingAlertCount: state => {
    return state.alerts.info ? state.alerts.info.pending : 0
  },
  alertInfo: state => {
    return state.alerts.info
  },
  batch_scanning: state => {
    return state.batchScan.details.is_resumed
  },
  batch_details: state => {
    return state.batchScan.details
  },
  batch_timer: state => {
    return state.batchScan.timer
  },
  // batch_refreshing: state => {
  //   return state.batchScan.refreshing
  // },
  batch_status: state => {
    return {
      starting: state.batchScan.starting,
      stopping: state.batchScan.stopping,
      pausing: state.batchScan.pausing
    }
  },
  // batchScanningInfo: state => {
  //   return state.batchScan.info
  // },
  listing_options: (state, getters, rootState, { i18n }) => {
    const { initFilters, initialPage, perPage, orderBy, requestFunction, columnsClasses, sortable, filterable, filterByColumn, dateColumns } = state.listing.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction,
      columnsClasses,
      sortable,
      headings: {
        type: i18n.t('screen.log.table.type.label'),
        initiator: i18n.t('screen.log.table.initiator.label'),
        create_date: i18n.t('screen.log.table.start_date.label'),
        duration: i18n.t('screen.log.table.duration.label'),
        processedRecords: i18n.t('screen.log.table.processed_records.label'),
        status: i18n.t('screen.log.table.status.label'),
        actions: i18n.t('screen.log.table.action.label')
      },
      filterable,
      filterByColumn,
      dateColumns,
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      },
      listColumns: {
        status: [
          {
            id: 'completed',
            text: i18n.t('screen.log.table.status.option.completed')// completed
          },
          {
            id: 'stop',
            text: i18n.t('screen.log.table.status.option.stop')// stop
          },
          {
            id: 'failed',
            text: i18n.t('screen.log.table.status.option.failed')// failed
          }

        ],
        type: [
          {
            id: 'new',
            text: i18n.t('screen.batch.type.options.new')
          },
          {
            id: 'all',
            text: i18n.t('screen.batch.type.options.all')
          }
        ]
      }
    }
  },
  screeninglist: state => {
    return state.ewescreening.screeninglist
  },
  categories: state => {
    return state.ewescreening.categories
  }
}

// actions
const actions = {
  singleScreening: (_, { name, id, nationality, dob }) => {
    return client.screen.nameScreening({
      name,
      id,
      nationality,
      dob
    })
  },
  singleScreeningEWE: (_, data) => {
    return client.screen.nameScreeningEWE(data)
  },
  check_running_batch: ({ commit }) => {
    return new Promise((resolve, reject) => {
      client.system_batch_history.findWhere({
        where: {
          is_completed: false,
          is_failed: false,
          is_paused: false,
          is_resumed: true
        }
      })
        .then((results) => {
          if (results.status === 200 && results.data && results.data instanceof Array && results.data.length > 0) {
            commit('SET_BASIC_INFO', results.data[0])
            return resolve(results)
          }

          reject()
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },
  start_batch: async ({ state, commit, dispatch }, batch_type) => {
    if (state.batchScan.details.id) {
      await dispatch('batch_refresh')
      if (state.batchScan.details.id && !(state.batchScan.details.is_completed || state.batchScan.details.is_failed)) {
        return dispatch('resume_batch')
      }
    }

    return new Promise((resolve, reject) => {
      commit('SET_BATCH_STATUS', { starting: true })
      client.screen.batch.startEWE({ type: batch_type })
        .then((results) => {
          if (results.status === 200 && results.data) {
            commit('SET_BASIC_INFO', results.data)
            return resolve(results.data)
          }
          reject()
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          commit('SET_BATCH_STATUS', { starting: false })
        })
    })
  },
  stop_batch: ({ commit, dispatch }) => {
    return new Promise((resolve) => {
      commit('SET_BATCH_STATUS', { stopping: true })
      dispatch('pause_batch')
        .then(() => {
          commit('SET_BASIC_INFO', {
            id: null
          })
          resolve()
        })
        .finally(() => {
          commit('SET_BATCH_STATUS', { stopping: false })
        })
    })
  },
  pause_batch: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      const batchId = state.batchScan.details.id
      commit('SET_BATCH_STATUS', { pausing: true })
      client.screen.batch
        .pauseEWE(batchId)
        .then((results) => {
          if (results.status === 200 && results.data) {
            commit('SET_BASIC_INFO', results.data)
            resolve()
          } else { reject() }
        })
        .finally(() => {
          commit('SET_BATCH_STATUS', { pausing: false })
        })
    })
  },
  resume_batch: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      client.screen.batch.resumeEWE(state.batchScan.details.id)
        .then((results) => {
          if (results.status === 200 && results.data) {
            commit('SET_BASIC_INFO', results.data)
            resolve()
          } else { reject() }
        })
    })
  },
  batch_refresh_socket: ({ state, commit }, payload) => {
    if (state.batchScan.details.id === payload.id) { commit('SET_BASIC_INFO', payload) }
  },
  batch_refresh: ({ state, commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (!state.batchScan.details.id) { return reject() }

      commit('SET_REFRESHING', true)
      // API.get(`sys_batch_history/${state.batchScan.details.id}`)
      client.system_batch_history.findOne(state.batchScan.details.id)
        .then((results) => {
          if (results.status === 200 && results.data) {
            commit('SET_BASIC_INFO', results.data)
            resolve()
            if (results.data.is_completed || results.data.is_failed) {
              dispatch('batch_stop_refresh')
            }
          }
        })
        .finally(() => {
          commit('SET_REFRESHING', false)
        })
    })
  },
  // batch_keep_refresh: ({ state, commit, dispatch }, unusedPayload) => {
  //   dispatch('batch_stop_refresh')

  //   if (!state.batchScan.details.id) { return }

  //   commit('SET_BATCH_UPDATER', setInterval(() => {
  //     dispatch('batch_refresh')
  //   }, 1000 * 30))
  // },
  // batch_stop_refresh: ({ state, commit }) => {
  //   if (!state.batchScan.timer) { return }

  //   clearInterval(state.batchScan.timer)
  //   commit('SET_BATCH_UPDATER', null) // clear timer referrence
  // },
  updateAlertInfo: (context) => {
    context.commit('SET_LOADING', true)
    var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()

    Promise.all([
      client.alert.count({
        where: {
          hit_status: {
            $NotIn: [AlertStatus.TrueHit, AlertStatus.FalseHit]
          }
        }
      }),
      client.alert_case.count({
        where: {
          status: 'pending'
        }
      }),
      client.corporate.count({
        where: {
          status: CorproateStatus.Active
        }
      }),
      client.individual.count({
        where: {
          status: IndividualStatus.Active
        }
      }),
      client.alert.count({
        where: {
          hit_status: AlertStatus.TrueHit
        }
      }),
      client.alert.count({
        where: {
          hit_status: AlertStatus.FalseHit
        }
      }),
      ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(x => {
        return client.alert.count({
          where: {
            $AND: [
              { create_date: { $GreaterEqual: moment([y, m]).subtract(x, 'M').startOf('M').toDate().getTime() } },
              { create_date: { $LessEqual: moment([y, m]).subtract(x, 'M').endOf('M').toDate().getTime() } }
            ]
          }
        })
      })
    ]).then(([cnt_pending, cnt_pending_alertCase, cnt_all_company, cnt_all_person, cnt_true_hit, cnt_false_hit, ...bar_chart_data]) => {
      context.commit('SET_ALERT_INFO', {
        pending: cnt_pending.data.count + cnt_pending_alertCase.data.count,
        cnt_all_company: cnt_all_company.data.count,
        cnt_all_person: cnt_all_person.data.count,
        donut_chart_data: [
          { status: 0, count: cnt_pending.data.count },
          { status: 1, count: cnt_true_hit.data.count },
          { status: 2, count: cnt_false_hit.data.count }
        ],
        bar_chart_data: [
          ...bar_chart_data.map((result, index) => {
            return {
              month: moment([y, m]).subtract(index, 'M').format('MM'),
              year: moment([y, m]).subtract(index, 'M').format('YYYY'),
              count: result.data.count
            }
          })
        ]
      })
    }).finally(() => {
      context.commit('SET_LOADING', false)
    })
  },
  get (context, payload) {
    return client.system_batch_history.findOne(payload)
  },
  batch_info: (context, { batch_type }) => {
    return new Promise((resolve, reject) => {
      if (batch_type === 'new') {
        client.screen.batch.info_new_only().then((results) => {
          resolve({
            batch_type,
            company: results.data.company[0].cnt,
            person: results.data.person[0].cnt
          })
        })
          .catch((error) => {
            reject(error)
          })
      } else if (batch_type === 'all') {
        try {
          client.corporate.count({
            where: {
              status: CorproateStatus.Active
            }
          }).then(result => {
            client.individual.count({
              where: {
                status: IndividualStatus.Active
              }
            }).then(result_2 => {
              resolve({
                batch_type,
                company: result.data.count,
                person: result_2.data.count
              })
            })
          })
        } catch (error) {
          reject(error)
        }
      } else {
        reject(new Error('Unsupported batch type'))
      }
    })
  },
  async get_report_download_url (content, { batch_id }) {
    return await client.system_batch_history.log.getReportDownloadUrl(batch_id)
  },
  nameScreening (context, entityDetails) {
    return client.screen.nameScreening(entityDetails)
  },
  nameScreeningEWE ({ commit }, entityDetails) {
    return client.screen.nameScreeningEWE(entityDetails).then(results => {
      commit('CLEAR_CACHED_ENTITY_DETAILS')
      return results
    })
  },
  getScreeningResults (context, { scanID }) {
    return client.screen.getScreeningResults(scanID)
  },
  getScreeningResultsEntity ({ state, commit }, { scanID, entityID }) {
    return state.cachedEntityDetails.get(entityID) || client.screen.getScreeningResultsEntity(scanID, entityID).then(result => {
      commit('SET_CACHED_ENTITY_DETAILS', {
        key: entityID,
        details: result
      })
      return result
    })
  },
  getScreeningResultsAssociation ({ state, commit }, { scanID, entityID, associationIDs }) {
    return state.cachedEntityDetails.get(associationIDs[associationIDs.length - 1]) || client.screen.getScreeningResultsAssociation(scanID, entityID, ...associationIDs).then(result => {
      commit('SET_CACHED_ENTITY_DETAILS', {
        key: associationIDs[associationIDs.length - 1],
        details: result
      })
      return result
    })
  },
  fetchScreeningList ({ commit }) {
    client.screen.getScreeningList()
      .then((results) => {
        if (results.status === 200 && results.data) {
          if (results.data.data instanceof Array) {
            commit('SET_SCREENING_LIST', results.data.data)
          }
        }
      })
  },
  fetchCategories ({ commit }) {
    client.screen.getCategories()
      .then((results) => {
        if (results.status === 200 && results.data) {
          if (results.data.data instanceof Array) {
            commit('SET_CATEGORIES', results.data.data)
          }
        }
      })
  }
}

// mutations
const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload
  },
  SET_ALERT_INFO: (state, payload) => {
    // console.log('SET_ALERT_INFO', payload);
    state.alerts.info = payload
  },
  SET_BATCH_STATUS: (state, { starting, stopping, pausing }) => {
    state.batchScan.starting = starting === undefined ? state.batchScan.starting : starting
    state.batchScan.stopping = stopping === undefined ? state.batchScan.stopping : stopping
    state.batchScan.pausing = pausing === undefined ? state.batchScan.pausing : pausing
  },
  SET_BASIC_INFO: (state, details) => {
    state.batchScan.details = details
    // state.basic.scanning = scanning;
  },
  SET_BATCH_UPDATER: (state, timer) => {
    state.batchScan.timer = timer
  },
  SET_REFRESHING: (state, refreshing) => {
    state.batchScan.refreshing = refreshing
  },
  'logTable/SET_DATA' (state, data) {
    console.log('logTable/SET_DATA', data)
  },
  'logTable/PAGINATION' (state, data) {
    console.log('logTable/PAGINATION', data)
  },
  'logTable/FILTER' (state, data) {
    console.log('logTable/FILTER', data)
  },
  'logTable/SORTED' (state, data) {
    console.log('logTable/SORTED', data)
  },
  'logTable/LOADING' (state, payload) {
    console.log('logTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.create_date) {
      state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start)
      state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end)
    }
    if (state.listing.options.initFilters.progress_last_touch) {
      state.listing.options.initFilters.progress_last_touch.start = moment(state.listing.options.initFilters.progress_last_touch.start)
      state.listing.options.initFilters.progress_last_touch.end = moment(state.listing.options.initFilters.progress_last_touch.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    state.listing.requestLoading = true
  },
  'logTable/LOADED' (state, data) {
    console.log('logTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'logTable/LIMIT' (state, data) {
    console.log('logTable/LIMIT', data)
  },
  'logTable/ERROR' (state, data) {
    console.log('logTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'logTable/ROW_CLICK' (state, data) {
    console.log('logTable/ROW_CLICK', data)
  },
  SET_SCREENING_LIST (state, list) {
    state.ewescreening.screeninglist = list
  },
  SET_CATEGORIES (state, list) {
    state.ewescreening.categories = list
  },
  SET_CACHED_ENTITY_DETAILS (state, { key, details: { status, data } }) {
    state.cachedEntityDetails.set(key, {
      status,
      data
    })
  },
  CLEAR_CACHED_ENTITY_DETAILS (state) {
    state.cachedEntityDetails.clear()
  }
}

// window.VB_updateScanStatus = (scanning) => {
// 	window.$store.commit('scan/SET_BATCH_SCAN_STATUS', scanning);
// }
// window.VB_updateScanInfo = (scan_info) => {
// 	try {
// 		window.$store.commit('scan/SET_BATCH_SCAN_INFO', JSON.parse(scan_info));
// 	}
// 	catch (e) {}
// }
// window.VB_updateAlertInfo = (alerts_info) => {
// 	// console.log('VB_updateAlertInfo', alerts_info);
// 	// try {
// 	// 	window.$store.commit('scan/SET_ALERT_INFO', JSON.parse(alerts_info));
// 	// 	window.$store.dispatch('scan/lists');
// 	// }
// 	// catch (e) {

// 	// }
// 	$store.dispatch('scan/updateAlertInfo');
// }
// // window.VB_updateBaseInfo = (basic_info) => {
// // 	try {
// // 		window.$store.commit('scan/SET_BASIC_INFO', JSON.parse(basic_info));
// // 	}
// // 	catch (e) {}
// // }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
