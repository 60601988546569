
// import API from '@/api'
import Cookies from 'js-cookie'
import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))
// console.log(client, RBAClient);

// if (location.search) {
//     var auth_redirect = new URLSearchParams(location.search)
//     if (auth_redirect.has('at') && (!localStorage.getItem('at') || localStorage.getItem('at') == 'null')) {
//         localStorage.setItem('at', auth_redirect.get('at'))
//     }
//     if (auth_redirect.has('rt') && (!localStorage.getItem('rt') || localStorage.getItem('rt') == 'null')) {
//         localStorage.setItem('rt', auth_redirect.get('rt'))
//     }
//     location.search = '';
// }

const state = {
  logging_in: false,
  // username: localStorage.getItem('uname') && localStorage.getItem('uname') != 'null' ? localStorage.getItem('uname') : null,
  username: '',
  organization_name: '',
  role: '',
  access_menus: '',
  last_access_ips: [],
  access_token: localStorage.getItem('at') && localStorage.getItem('at') !== 'null' ? localStorage.getItem('at') : null,
  // refresh_token: localStorage.getItem('rt') && localStorage.getItem('rt') != 'null' ? localStorage.getItem('rt') : null,
  refreshing: false
}

// getters
const getters = {
  is_logged_in: state => !!state.access_token,
  default_organization: () => {
    var [match, default_organization] = location.hostname.match('^([^.]*).?rba-essential.com$') || [null, null]
    return match ? default_organization : 'LOCALHOST'
  },
  username: state => state.username,
  weakPasswords: () => {
    return [
      '123456',
      '123456789',
      'picture1',
      'password',
      '12345678',
      '111111',
      '123123',
      '12345',
      '1234567890',
      'senha',
      '1234567',
      'qwerty',
      'abc123',
      'Million2',
      '000000',
      '1234',
      'iloveyou',
      'aaron431',
      'password1',
      'qqww1122',
      '123',
      'omgpop',
      '123321',
      '654321',
      'qwertyuiop',
      'qwer123456',
      '123456a',
      'a123456',
      '666666',
      'asdfghjkl',
      'ashley',
      '987654321',
      'unknown',
      'zxcvbnm',
      '112233',
      'chatbooks',
      '20100728',
      '123123123',
      'princess',
      'jacket025',
      'evite',
      '123abc',
      '123qwe',
      'sunshine',
      '121212',
      'dragon',
      '1q2w3e4r',
      '5201314',
      '159753',
      '123456789',
      'pokemon',
      'qwerty123',
      'Bangbang123',
      'jobandtalent',
      'monkey',
      '1qaz2wsx',
      'abcd1234',
      'default',
      'aaaaaa',
      'soccer',
      '123654',
      'ohmnamah23',
      '12345678910',
      'zing',
      'shadow',
      '102030',
      '11111111',
      'asdfgh',
      '147258369',
      'qazwsx',
      'qwe123',
      'michael',
      'football',
      'baseball',
      '1q2w3e4r5t',
      'party',
      'daniel',
      'asdasd',
      '222222',
      'myspace1',
      'asd123',
      '555555',
      'a123456789',
      '888888',
      '7777777',
      'fuckyou',
      '1234qwer',
      'superman',
      '147258',
      '999999',
      '159357',
      'love123',
      'tigger',
      'purple',
      'samantha',
      'charlie',
      'babygirl',
      '88888888',
      'jordan23',
      '789456123',
      'jordan',
      'anhyeuem',
      'killer',
      'basketball',
      'michelle',
      '1q2w3e',
      'lol123',
      'qwerty1',
      '789456',
      '6655321',
      'nicole',
      'naruto',
      'master',
      'chocolate',
      'maggie',
      'computer',
      'hannah',
      'jessica',
      '123456789a',
      'password123',
      'hunter',
      '686584',
      'iloveyou1',
      '0987654321',
      'justin',
      'cookie',
      'hello',
      'blink182',
      'andrew',
      '25251325',
      'love',
      '987654',
      'bailey',
      'princess1',
      '0123456',
      '101010',
      '12341234',
      'a801016',
      '1111',
      '1111111',
      'anthony',
      'yugioh',
      'fuckyou1',
      'amanda',
      'asdf1234',
      'trustno1',
      'butterfly',
      'x4ivygA51F',
      'iloveu',
      'batman',
      'starwars',
      'summer',
      'michael1',
      '00000000',
      'lovely',
      'jakcgt333',
      'buster',
      'jennifer',
      'babygirl1',
      'family',
      '456789',
      'azerty',
      'andrea',
      'q1w2e3r4',
      'qwer1234',
      'hello123',
      '10203',
      'matthew',
      'pepper',
      '12345a',
      'letmein',
      'joshua',
      '131313',
      '123456b',
      'madison',
      'Sample123',
      '777777',
      'football1',
      'jesus1',
      'taylor',
      'b123456',
      'whatever',
      'welcome',
      'ginger',
      'flower',
      '333333',
      '1111111111',
      'robert',
      'samsung',
      'a12345',
      'loveme',
      'gabriel',
      'alexander',
      'cheese',
      'passw0rd',
      '142536',
      'peanut',
      '11223344',
      'thomas',
      'angel1'
    ]
  }
}

// actions
const actions = {
  login: ({ state, commit, getters }, { organization, username, password }) => {
    return new Promise((resolve, reject) => {
      if (state.logging_in) { return reject() }

      commit('SET_TOKEN', { logging_in: true })
      // API.put('api/v1/users/login', { organization, username, password }, getters.default_organization == 'LOCALHOST' ? {} : { baseURL: `${location.protocol}//${organization}.rba-essential.com` })
      client.auth.getToken({ organization, username, password }, ['LOCALHOST', 'staging'].indexOf(getters.default_organization) !== -1 ? {} : { baseURL: `${location.protocol}//${organization}.rba-essential.com` })
        .then(res => {
          if (res.status === 200 && res.data.token && res.data.refresh) {
            if (getters.default_organization !== organization && ['LOCALHOST', 'staging'].indexOf(getters.default_organization) === -1) {
              location.href = `${location.protocol}//${organization}.rba-essential.com?at=${res.data.token}&rt=${res.data.refresh}`
              return location.href
            }

            commit('SET_TOKEN', {
              logging_in: false,
              access_token: res.data.token
              // refresh_token: res.data.refresh,
              // username
            })
            return resolve()
          } else if (res.status === 429) {
            return reject(new Error('Failed to many time'))
          }

          reject()
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          commit('SET_TOKEN', { logging_in: false })
        })
    })
  },
  refresh: (/* { state, commit } */) => {
    // return new Promise((resolve, reject) => {
    //     if (state.refreshing)
    //         return reject();

    //     commit('SET_TOKEN', { refreshing: true });
    //     // API.put('api/v1/users/refresh', { access_token: state.access_token, refresh_token: state.refresh_token })
    //     client.auth.refreshToken({ access_token: state.access_token, refresh_token: state.refresh_token })
    //         .then(res => {
    //             if (res.status == 200 && res.data.token && res.data.refresh) {
    //                 commit('SET_TOKEN', {
    //                     refreshing: false,
    //                     access_token: res.data.token,
    //                     refresh_token: res.data.refresh
    //                 });
    //                 return resolve(res);
    //             }

    //             commit('CLEAR_TOKEN');
    //             reject();
    //         })
    //         .catch(() => {
    //             commit('CLEAR_TOKEN');
    //             reject();
    //         })
    //         .finally(() => {
    //             commit('SET_TOKEN', { refreshing: false });
    //         })
    // })
  },
  logout: ({ commit }) => {
    commit('CLEAR_TOKEN')
  },
  forgot: (_, { organization, username, email }) => {
    return new Promise((resolve, reject) => {
      client.auth
        .forgotPassword(organization, username, email)
        .then(res => {
          if (res.status === 200 && res.data.data.reset_status === 0) {
            return resolve(res.data.data)
          }
          reject(res.data.data)
        })
        .catch(reject)
        .finally(() => { })
    })
  },
  reset: (_, { token, newPassword, confirmPassword }) => {
    return new Promise((resolve, reject) => {
      client.auth
        .resetPassword(token, newPassword, confirmPassword)
        .then(res => {
          console.log(res)
          if (res.status === 200 && res.data.data.reset_status === 1) {
            return resolve(res.data.data)
          }
          reject(res.data.data)
        })
        .catch(reject)
        .finally(() => { })
    })
  },
  verifyResetPasswordToken: (_, token) => {
    return new Promise((resolve, reject) => {
      client.auth
        .verifyResetPasswordToken(token)
        .then(res => {
          console.log(res)
          if (res.status === 200 && res.data.code === 200) {
            return resolve()
          }
          reject()
        })
        .catch(reject)
        .finally(() => { })
    })
  },
  fetchProfile: ({ commit }) => {
    return client.auth.getProfile().then((res) => {
      if (res.status === 200) {
        commit('SET_TOKEN', {
          username: res.data.username,
          organization_name: res.data.organization,
          role: res.data.role,
          last_access_ips: res.data.last_login_ips,
          access_menus: res.data.access_menus
        })
      }
    })
  },
  logAction: () => {
    return client.action_log.logAction()
  }
}

// mutations
const mutations = {
  SET_TOKEN: (state, { logging_in, refreshing, access_token, username, organization_name, role, last_access_ips, access_menus }) => {
    try {
      state.logging_in = logging_in !== undefined ? logging_in : state.logging_in
      state.refreshing = refreshing !== undefined ? refreshing : state.refreshing
      state.access_token = access_token || state.access_token
      // state.refresh_token = refresh_token || state.refresh_token;
      state.username = username || state.username
      state.organization_name = organization_name || state.organization_name
      state.role = role || state.role
      state.last_access_ips = last_access_ips || state.last_access_ips
      state.access_menus = access_menus || state.access_menus
      // localStorage.setItem('at', state.access_token)
      // localStorage.setItem('rt', state.refresh_token);
      Cookies.set('at', state.access_token)
      // API.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('at')}`
    } catch (error) {
      console.log(error)
    }
  },
  CLEAR_TOKEN: (state) => {
    // console.log('CLEAR_TOKEN');
    state.logging_in = false
    state.refreshing = false
    state.access_token = null
    // state.refresh_token = null;
    state.username = ''
    state.organization_name = ''
    state.role = ''
    state.last_access_ips = []
    state.access_menus = ''
    localStorage.removeItem('at')
    localStorage.removeItem('rt')
    Cookies.remove('at')
    // delete API.defaults.headers.common.Authorization
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
