// initial state
import currency_table from '@/store/modules/settings/currency'
import country_table from '@/store/modules/settings/countries'
import { RBAClient } from 'rba-client'
import Cookies from 'js-cookie'

const client = RBAClient.getInstance(require('@/api/config'))

const state = {
  settings: {
    fontSize: 10,
    UserID: '',
    UserPassword: '',
    OrganizationID: ''
  },
  token: null,
  token_verifying: false,
  country_table_ws: [],
  // checkoutStatus: null
  date_format: {
    ago: false
  },
  // external url confirmation
  external_url_ignore: !!(parseInt(Cookies.get('external_url_ignore')) || 0)
}

// getters
const getters = {
  settings: state => {
    return state.settings
  },
  token: state => {
    return state.token
  },
  token_verifying: state => {
    return state.token_verifying
  },
  currency_table: unusedState => {
    return currency_table
  },
  country_table: state => {
    return (state.country_table_ws.length ? state.country_table_ws : country_table)
  },
  externalUrlIgnore: state => {
    return state.external_url_ignore
  }
}

// actions
const actions = {
  fetchCountryList ({ commit }) {
    // API.get('v2/scan/get_country_list')
    client.screen.getCountryList()
      .then((results) => {
        if (results.status === 200 && results.data) {
          if (results.data.data instanceof Array) {
            commit('SET_COUNTRY_LIST', results.data.data.map(x => ({
              country: x.country_name,
              abbreviation: x.iso2,
              iso2: x.iso2,
              iso3: x.iso3,
              displayName: x.display_name,
              displayNameZhHant: x.display_name_zh_hant,
              displayNameZhHans: x.display_name_zh_hans
            })).sort((a, b) => a.country === b.country ? 0 : (a.country > b.country ? 1 : -1)))
          }
        }
      })
  },
  toggleDateFormat ({ state, commit }) {
    commit('SET_DATE_FORMAT', { ago: !state.date_format.ago })
  },
  fetchLatestApplicationVersion () {
    return client.settings.getApplicationVersion()
  },
  updateExternalUrlIgnore ({ commit }, ignore) {
    Cookies.set('external_url_ignore', ignore ? 1 : 0)
    commit('SET_EXTERNAL_URL_IGNORE', ignore)
  }
}

// mutations
const mutations = {
  // SET_VALUE (state, {key, value}) {
  //   state.settings[key] = value;
  // },
  // SET_TOKEN (state, token) {
  //   state.token = token;
  // },
  // SET_VERIFYING (state, verifying) {
  //   state.token_verifying = verifying;
  // },
  SET_COUNTRY_LIST (state, list) {
    state.country_table_ws = list
  },
  SET_DATE_FORMAT (state, { ago }) {
    state.date_format.ago = ago === undefined ? state.date_format.ago : ago
  },
  SET_EXTERNAL_URL_IGNORE (state, ignore) {
    state.external_url_ignore = ignore
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
