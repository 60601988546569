import Vue from 'vue'
// Plugin
import vuetify from '@/plugins/vuetify' // path to vuetify export
import router from '@/plugins/vue-router'
import VueTables2 from '@/plugins/vue-tables-2'
import VTooltip from '@/plugins/v-tooltip'
import VueTimeago from '@/plugins/vue-timeago'
import Notifications from '@/plugins/vue-notification'
import VueFormWizard from '@/plugins/vue-form-wizard'
import Loading from '@/plugins/vue-loading-overlay'
import TelInput from '@/plugins/vue-tel-input'
import TelInputVuetify from '@/plugins/vue-tel-input-vuetify'
import VueJsModal from '@/plugins/vue-js-modal'
import Vuelidate from '@/plugins/vuelidate'
import FlagIcon from '@/plugins/flag-icon'
// import Datetime from '@/plugins/vue-datetime'
import VueI18n from '@/plugins/vue-i18n'
import Sanitize from '@/plugins/sanitize'
import Moment from '@/plugins/moment'
import APIClient from '@/plugins/rba-api-client'

// Store
import Store from '@/store'
import App from '@/App'
// Global Component
import MemberDetail2 from '@/components/forms/MemberDetail2'

// import API from '@/api'
Vue.component('MemberDetail2', MemberDetail2)

const i18n = VueI18n(Vue)
const store = Store(Vue, i18n)

Vue.filter('company_name', function (company, lang) {
  var _lang = lang || 0

  if (!company) return ''

  // for (var i = (company.names || []).length - 1; i >= 0; i--) {
  // 	if (company.names[i].lang == lang && company.names[i].name_type == 3) {
  // 		return company.names[i].other;
  // 	}
  // }
  // for (var i = 0; i < (company.names || []).length; i++) {
  // 	if (company.names[i].lang == _lang && company.names[i].name_type == 3) {
  // 		return company.names[i].other;
  // 	}
  // }

  var sorted_name = (company.names || []).filter((a) => {
    return _lang === 0 ? true : a.lang === _lang
  }).sort((a, b) => {
    return new Date(b.updated_date) - new Date(a.updated_date)
  })

  if (sorted_name.length > 0) {
    return sorted_name[0].other
  }

  return ''
})

Vue.filter('company_type', function (company) {
  if (!company) return ''

  switch (company.company_type) {
    case 1:
      return 'Public Limited'
    case 2:
      return 'Private Limited'
    case 3:
      return 'Private Unlimited with Share Capital'
    case 4:
      return 'Public Unlimited with Share Capital'
    case 5:
      return 'Company Limited by Guarantee without a share capital'
    case 6:
      return 'Registered Non-Hong Kong Company'
    default:
      return ''
  }
})

Vue.filter('director_type', function (director_type_num) {
  if (!director_type_num) return ''

  switch (director_type_num) {
    case 1:
      return 'Director'
    case 2:
      return 'Alternate Director'
    case 3:
      return 'Reserve Director'
    case 4:
      return 'Director & Alternate Director'
    default:
      return ''
  }
})

Vue.filter('natural_person_name', function (natural_person, lang, name_type) {
  var _lang = lang || 0
  var _name_type = name_type || 0

  if (!natural_person) return ''

  // for (var i = (natural_person.names || []).length - 1; i >= 0; i--) {
  // 	if (natural_person.names[i].lang == lang && natural_person.names[i].name_type == name_type) {
  // 		return [natural_person.names[i].sur, natural_person.names[i].other].join(' ')
  // 	}
  // }

  // for (var i = 0; i < (natural_person.names || []).length; i++) {
  // 	if (natural_person.names[i].lang == _lang && natural_person.names[i].name_type == _name_type) {
  // 		return [natural_person.names[i].sur, natural_person.names[i].other].join(' ')
  // 	}
  // }

  var sorted_name = (natural_person.names || []).filter((a) => {
    return (_lang === 0 ? true : a.lang === _lang) && (_name_type === 0 ? true : a.name_type === _name_type)
  }).sort((a, b) => {
    return new Date(b.updated_date) - new Date(a.updated_date)
  })

  if (sorted_name.length > 0) {
    return [sorted_name[0].sur, sorted_name[0].other].filter(Boolean).join(' ')
  }

  return ''
})

Vue.filter('ucwords', function (text) {
  var _text = text.toLowerCase()
  return _text.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function (s) {
      return s.toUpperCase()
    })
})

Vue.filter('x500_name', (x500_name, x500_key) => {
  var regex = /(?:^|,)\s*?(C|CN|DC|E|EMAIL|EMAILADDRESS|L|O|OU|PC|S|SN|SP|ST|STREET Street|T)\s*?=\s*?/g
  var x500_attr = { // https://www.ibm.com/support/knowledgecenter/SSLTBW_2.2.0/com.ibm.tcp.ipsec.ipsec.help.doc/com/ibm/tcp/ipsec/nss/NssImageServerPs.RB_X500.html
    C: 'Country',
    // CN: 'Common name',
    // DC: 'Domain component',
    OU: 'Organizational unit name',
    O: 'Organization name',
    L: 'Locality'
    // E: 'E-mail address',
    // EMAIL: 'E-mail address (preferred)',
    // EMAILADDRESS: 'E-mail address',
    // PC: 'Postal code',
    // S: 'State or province',
    // SN: 'Family name',
    // SP: 'State or province',
    // ST: 'State or province (preferred)',
    // STREET: 'Street',
    // T: 'Title'
  }

  if (!x500_name.match(regex)) {
    return null
  }

  var attr = x500_name.split(regex) // extract

  attr.shift() // skip first extract

  var results = {
    x500_name
  }

  for (let index = 0; index + 1 < attr.length; index += 2) {
    const key = attr[index]
    const value = attr[index + 1]

    if (x500_attr[key]) {
      results[x500_attr[key]] = value
      results[key] = value
    }
  }

  return results[x500_key]
})

Sanitize(Vue)
VTooltip(Vue)
VueTimeago(Vue)
Notifications(Vue)
VueFormWizard(Vue)
Loading(Vue)
TelInput(Vue)
TelInputVuetify(Vue, vuetify)
VueTables2(Vue)
VueJsModal(Vue)
Vuelidate(Vue)
FlagIcon(Vue)
// Datetime(Vue)
Moment(Vue)
APIClient(Vue, store, i18n)

// Vue.prototype.$axios = API

// Vue.mixin({
// 	methods: {
// 		$notify: (...args) => {
// 			store.dispatch('setting/notify', ...args);
// 		}
// 	}
// })

// import * as socketIOClient from 'socket.io-client'
// import * as sailsIOClient from 'sails.io.js'

// import '@/assets/dependencies/sails.io.js';
// import * as io from '@/assets/dependencies/sails.io.js';
// console.log(sailsIOClient, socketIOClient);

new Vue({
  vuetify,
  store,
  router: router(Vue, store),
  i18n,
  render: h => h(App),
  mounted () {
    // this.$store.i18n = this.$i18n
  }
}).$mount('#app')
