// import API from '@/api'
import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))

// initial state
const state = {
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: ['id', 'name', 'brNumber', 'fileCount', 'requestCount', 'action'],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      requestFunction (request) {
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            client.ekyc.client.findWhere({
              limit: request.limit,
              skip: (request.limit * (request.page - 1)) || 0
            })
              .then(({ data: { data, total } }) => {
                resolve({ data, count: total })
              })
              .catch((error) => {
                reject(error)
              })

            // Promise.all([
            //     { data: { count: 999 } },
            //     API.get('v2/ekyc/clients', {
            //         params: {
            //             limit: request.limit,
            //             skip: (request.limit * (request.page - 1)) || 0,
            //         }
            //     })
            // ])
            // .then(([{ data: { count } }, { data: { data } }]) => {
            //     resolve({ data, count });
            // })
            // .catch((error) => {
            //     reject(error);
            // })
          }, 800)
        })
      },
      columnsClasses: {
        id: 'col-xs-2',
        name: 'col-xs-1',
        brNumber: 'col-xs-2',
        fileCount: 'col-xs-2',
        requestCount: 'col-xs-1',
        action: 'col-xs-1'
      },
      sortable: [],
      filterable: null,
      // filterable: ['id', 'name', 'brNumber', 'fileCount', 'requestCount'],
      filterByColumn: true
      // dateColumns: ['create_date'],
      // dateFormat: 'DD/MMM/YYYY',
    }
  },
  listing_request: {
    requestTimeout: null,
    requestLoading: false,
    columns: ['bank_name', 'status', 'request_date', /* 'message', */ 'action'],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      columnsClasses: {
        bank_name: 'col-xs-2',
        status: 'col-xs-1',
        request_date: 'col-xs-2',
        message: 'col-xs-2',
        action: 'col-xs-1'
      },
      sortable: [],
      filterable: null,
      // filterable: ['bank_name', 'status', 'request_date', 'message', 'action'],
      filterByColumn: true
      // dateColumns: ['request_date'],
      // dateFormat: 'DD/MMM/YYYY',
    }
  },
  listing_document: {
    requestTimeout: null,
    requestLoading: false,
    columns: [/* 'select', */'name', 'description', 'uploadedAt', 'mimeType', 'action'],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      columnsClasses: {
        // name: 'col-2',
        // description: 'col-2',
        // uploadedAt: 'col-2',
        // mimeType: 'col-1',
        // action: 'col-1'
      },
      sortable: [],
      filterable: null,
      // filterable: ['bank_name', 'status', 'request_date', 'message', 'action'],
      filterByColumn: true
      // dateColumns: ['request_date'],
      // dateFormat: 'DD/MMM/YYYY',
    }
  }
}

// getters
const getters = {
  listing_options: (state, unusedGetters, unusedRootState, { i18n }) => {
    const { initFilters, initialPage, perPage, orderBy, requestFunction, columnsClasses, sortable, filterable, filterByColumn } = state.listing.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction,
      columnsClasses,
      sortable,
      headings: {
        id: i18n.t('ekyc.listing.table.id.label'),
        name: i18n.t('ekyc.listing.table.name.label'),
        brNumber: i18n.t('ekyc.listing.table.brNumber.label'),
        fileCount: i18n.t('ekyc.listing.table.fileCount.label'),
        requestCount: i18n.t('ekyc.listing.table.requestCount.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      // dateFormat,
      // dateColumns,
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      }
    }
  },
  listing_request_options: (state, unusedGetters, unusedRootState, { i18n }) => (ekyc_client_id) => {
    const { initFilters, initialPage, perPage, orderBy, columnsClasses, sortable, filterable, filterByColumn } = state.listing_request.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction (request) {
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            Promise.all([
              { data: { count: 10 } },
              client.ekyc.request.find(ekyc_client_id, {
                limit: request.limit,
                skip: (request.limit * (request.page - 1)) || 0
              })
            ])
              .then(([{ data: { count } }, { data: { data } }]) => {
                resolve({ data, count })
              })
              .catch((error) => {
                reject(error)
              })
          }, 800)
        })
      },
      columnsClasses,
      sortable,
      headings: {
        bank_name: i18n.t('ekyc.listing_req.table.bank_name.label'),
        status: i18n.t('ekyc.listing_req.table.status.label'),
        request_date: i18n.t('ekyc.listing_req.table.request_date.label'),
        message: i18n.t('ekyc.listing_req.table.message.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      // dateFormat,
      // dateColumns,
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      }
    }
  },
  listing_document_options: (state, unusedGetters, unusedRootState, { i18n }) => (ekyc_client_id) => {
    const { initFilters, initialPage, perPage, orderBy, columnsClasses, sortable, filterable, filterByColumn } = state.listing_document.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction (request) {
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            client.ekyc.document.find(ekyc_client_id, {
              limit: request.limit,
              skip: (request.limit * (request.page - 1)) || 0
            })
              .then(({ status, data: { files, total } }) => {
                if (status === 200) {
                  return resolve({ data: files, count: total })
                }
                reject()
              })
              .catch((error) => {
                reject(error)
              })
          }, 800)
        })
      },
      columnsClasses,
      sortable,
      headings: {
        // select: '',
        name: i18n.t('ekyc.listing_doc.table.name.label'),
        description: i18n.t('ekyc.listing_doc.table.description.label'),
        uploadedAt: i18n.t('ekyc.listing_doc.table.uploaded_at.label'),
        mimeType: i18n.t('ekyc.listing_doc.table.mime_type.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      // dateFormat,
      // dateColumns,
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      }
    }
  }
}

// actions
const actions = {
  getBankList () {
    return client.ekyc.getBankList()
  }
}

// mutations
const mutations = {
  'clientsTable/SET_DATA' (state, data) {
    console.log('clientsTable/SET_DATA', data)
  },
  'clientsTable/PAGINATION' (state, data) {
    console.log('clientsTable/PAGINATION', data)
  },
  'clientsTable/FILTER' (state, data) {
    console.log('clientsTable/FILTER', data)
  },
  'clientsTable/SORTED' (state, data) {
    console.log('clientsTable/SORTED', data)
  },
  'clientsTable/LOADING' (state, payload) {
    console.log('clientsTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    // if (state.listing.options.initFilters.create_date) {
    //     state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start);
    //     state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end);
    // }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    // state.listing.options.orderBy.ascending = payload.ascending;
    // state.listing.options.orderBy.column = payload.orderBy;
    state.listing.requestLoading = true
  },
  'clientsTable/LOADED' (state, data) {
    console.log('clientsTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'clientsTable/LIMIT' (state, data) {
    console.log('clientsTable/LIMIT', data)
  },
  'clientsTable/ERROR' (state, data) {
    console.log('clientsTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'clientsTable/ROW_CLICK' (state, data) {
    console.log('clientsTable/ROW_CLICK', data)
  },
  // request table
  'requestTable/SET_DATA' (state, data) {
    console.log('requestTable/SET_DATA', data)
  },
  'requestTable/PAGINATION' (state, data) {
    console.log('requestTable/PAGINATION', data)
  },
  'requestTable/FILTER' (state, data) {
    console.log('requestTable/FILTER', data)
  },
  'requestTable/SORTED' (state, data) {
    console.log('requestTable/SORTED', data)
  },
  'requestTable/LOADING' (state, payload) {
    console.log('requestTable/LOADING', payload)
    state.listing_request.options.initFilters = payload.query
    // if (state.listing_request.options.initFilters.create_date) {
    //     state.listing_request.options.initFilters.create_date.start = moment(state.listing_request.options.initFilters.create_date.start);
    //     state.listing_request.options.initFilters.create_date.end = moment(state.listing_request.options.initFilters.create_date.end);
    // }
    state.listing_request.options.initialPage = payload.page
    state.listing_request.options.perPage = payload.limit
    // state.listing_request.options.orderBy.ascending = payload.ascending;
    // state.listing_request.options.orderBy.column = payload.orderBy;
    state.listing_request.requestLoading = true
  },
  'requestTable/LOADED' (state, data) {
    console.log('requestTable/LOADED', data)
    state.listing_request.requestLoading = false
  },
  'requestTable/LIMIT' (state, data) {
    console.log('requestTable/LIMIT', data)
  },
  'requestTable/ERROR' (state, data) {
    console.log('requestTable/ERROR', data)
    state.listing_request.requestLoading = false
  },
  'requestTable/ROW_CLICK' (state, data) {
    console.log('requestTable/ROW_CLICK', data)
  },
  // document table
  'documentTable/SET_DATA' (state, data) {
    console.log('documentTable/SET_DATA', data)
  },
  'documentTable/PAGINATION' (state, data) {
    console.log('documentTable/PAGINATION', data)
  },
  'documentTable/FILTER' (state, data) {
    console.log('documentTable/FILTER', data)
  },
  'documentTable/SORTED' (state, data) {
    console.log('documentTable/SORTED', data)
  },
  'documentTable/LOADING' (state, payload) {
    console.log('documentTable/LOADING', payload)
    state.listing_document.options.initFilters = payload.query
    // if (state.listing_document.options.initFilters.create_date) {
    //     state.listing_document.options.initFilters.create_date.start = moment(state.listing_document.options.initFilters.create_date.start);
    //     state.listing_document.options.initFilters.create_date.end = moment(state.listing_document.options.initFilters.create_date.end);
    // }
    state.listing_document.options.initialPage = payload.page
    state.listing_document.options.perPage = payload.limit
    // state.listing_document.options.orderBy.ascending = payload.ascending;
    // state.listing_document.options.orderBy.column = payload.orderBy;
    state.listing_document.requestLoading = true
  },
  'documentTable/LOADED' (state, data) {
    console.log('documentTable/LOADED', data)
    state.listing_document.requestLoading = false
  },
  'documentTable/LIMIT' (state, data) {
    console.log('documentTable/LIMIT', data)
  },
  'documentTable/ERROR' (state, data) {
    console.log('documentTable/ERROR', data)
    state.listing_document.requestLoading = false
  },
  'documentTable/ROW_CLICK' (state, data) {
    console.log('documentTable/ROW_CLICK', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
