// import Vue from 'vue'
import Vuex from 'vuex'
import company from '@/store/modules/company'
import natural_person from '@/store/modules/natural_person'
import alerts from '@/store/modules/alerts'
import alert_case from '@/store/modules/alert_case'
import bankinfo from '@/store/modules/bankinfo'
import scan from '@/store/modules/scan'
import setting from '@/store/modules/settings'
import risk from '@/store/modules/risk'
import transaction from '@/store/modules/transaction'
import auth from '@/store/modules/auth'
import attachment from '@/store/modules/attachment'
import importer from '@/store/modules/import'
import ekyc from '@/store/modules/ekyc'
// import createLogger from '../../../src/plugins/logger'
import socket from '@/store/plugins/socket'
// import plugin_auth from '@/store/plugins/auth'

// export default window.$store
export default (Vue, I18n) => {
  Vue.use(Vuex)

  const debug = process.env.NODE_ENV !== 'production'

  const store = new Vuex.Store({
    getters: {
      i18n: () => (I18n)
    },
    modules: {
      company,
      natural_person,
      alerts,
      alert_case,
      scan,
      setting,
      risk,
      transaction,
      auth,
      attachment,
      bankinfo,
      import: importer,
      ekyc
    },
    strict: debug,
    // plugins: debug ? [createLogger()] : []
    plugins: [
      socket
      // plugin_auth
    ]
  })

  if (debug) {
    window.$store = store
  }

  return store
}
