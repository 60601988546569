<template>
  <v-container fluid>
    <!-- <div style="position: relative;"> -->
    <loading
      ref="loading2"
      :active.sync="requestLoading"
      :is-full-page="false"
    />
    <v-row v-if="is_edit_mode">
      <v-col cols="4">
        <v-select
          :label="$t('company.details.form.status.label')"
          class="form-control"
          v-model="form.status"
          :items="[
            { value: 0, text: $t('company.details.form.status.options.inactive') },
            { value: 1, text: $t('company.details.form.status.options.active') },
          ]"
        />
      </v-col>
      <v-spacer />
      <v-col cols="2">
        <label>{{ $t('company.details.form.risk_level.label') }}</label>
        <div :style="{ 'color' : (risk_level == 'High Risk' ? 'red' : (risk_level == 'Low Risk' ? 'green' : (risk_level == 'Medium Risk' ? 'orange' : ''))) }">
          {{ risk_loading ? $t('company.details.form.risk_level.loading') : $t('company.details.form.risk_level.options')[risk_level] }} ({{ Math.max(risk_detail ? risk_detail : 0, 0) }})
        </div>
      </v-col>
    </v-row>
    <component
      :is="is_edit_mode ? 'div' : 'v-stepper'"
      v-bind="is_edit_mode ? {} : { 'alt-labels': true, 'flat': true }"
      v-model="step"
    >
      <v-tabs
        v-if="is_edit_mode"
        v-model="tab"
        class="mb-5"
        show-arrows
        data-cy="tab_lists"
      >
        <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
        <v-tab
          data-cy="company_info_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.form.company_type.$anyError || $v.name_form.$anyError || $v.email_form.$anyError || $v.form.names.$error"
          >
            <span :class="($v.form.company_type.$anyError || $v.name_form.$anyError || $v.email_form.$anyError || $v.form.names.$error) ? 'error--text' : ''">{{ $t('company.details.tabs.info') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          data-cy="company_add_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.address_form.$anyError"
          >
            <span :class="($v.address_form.$anyError) ? 'error--text' : ''">{{ $t('company.details.tabs.address') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          data-cy="company_members_tab"
        >
          {{ $t('company.details.tabs.members') }}
        </v-tab>
        <v-tab
          data-cy="company_contact_tab"
        >
          {{ $t('company.details.tabs.contact') }}
        </v-tab>
        <!-- <v-tab>{{ $t('company.details.tabs.business') }}</v-tab> -->
        <v-tab
          data-cy="company_others_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.form.acc_end_date.$anyError || $v.form.acc_start_date.$anyError"
          >
            <span :class="($v.form.acc_end_date.$anyError || $v.form.acc_start_date.$anyError) ? 'error--text' : ''">{{ $t('company.details.tabs.others') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          v-if="$slots.roles"
          data-cy="company_roles_tab"
        >
          {{ $t('company.details.tabs.roles') }}
        </v-tab>
        <v-tab
          data-cy="company_alerts_tab"
        >
          {{ $t('company.details.tabs.alerts') }}
        </v-tab>
        <v-tab
          data-cy="company_bankinfo_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.form.bank_infos.$anyError"
          >
            <span :class="($v.form.bank_infos.$anyError) ? 'error--text' : ''">{{ $t('company.details.tabs.bank') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          data-cy="company_atta_tab"
        >
          {{ $t('company.details.tabs.attachment') }}
        </v-tab>
      </v-tabs>
      <!-- <v-stepper-header v-else flat>
				<v-stepper-step step="1">Company Information</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="2">Addresses</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="3">Members</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="4">Contact Person</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="5">Business Registration</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="6">Others</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="1" v-if="$slots.roles">Roles Info</v-stepper-step>
				<v-divider v-if="$slots.roles"></v-divider>
				<v-stepper-step step="8">Bank Info</v-stepper-step>
			</v-stepper-header> -->
    </component>
    <component
      :is="is_edit_mode ? 'v-tabs-items' : 'form-wizard'"
      v-model="tab"
      v-bind="is_edit_mode ? { options: { useUrlFragment: false }, 'cache-lifetime': 0 } : { title: '', subtitle: '', color: '#3c8dbc' }"
      @changed="validateAsync"
      @on-change="onChange"
      @on-complete="onComplete"
    >
      <component
        ref="name_tab"
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? {
          name: $t('company.details.tabs.info'),
          suffix: !isTabActive('name_tab') && $v.form.names.$error ? ' <span class=\'badge bg-red\'>&nbsp;</span>' : ''
        } : { title: $t('company.details.tabs.info') }"
        :before-change="validateAsync"
      >
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                :label="$t('company.details.form.ref_id.label')"
                v-model="form.ref_id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              data-cy="company_type_list"
            >
              <v-select
                class="form-control"
                :error="$v.form.company_type.$error"
                v-model="form.company_type"
                :error-messages="$v.form.company_type.$error ? $t('validate.required', { field: $t('company.details.form.company_type.label') }) : ''"
                :label="$t('company.details.form.company_type.label')"
                clearable
                :items="Object.values($t('company.details.form.company_type.options') || {}).map((v, k) => ({ value: k + 1, text: v }))"
              >
                <template
                  v-slot:selection="items"
                >
                  <div
                    data-cy="selected_company_type"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
                <template
                  v-slot:item="items"
                >
                  <div
                    :data-cy="`company_type_${items.item.value}`"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
                <!-- :items="[
									/*{ value: 0, text: '-- Select Type --' }, */
									{ value: 1, text: 'Public Limited' },
									{ value: 2, text: 'Private Limited' },
									{ value: 3, text: 'Private Unlimited with Share Capital' },
									{ value: 4, text: 'Public Unlimited with Share Capital' },
									{ value: 5, text: 'Company Limited by Guarantee without a Share Capital' },
									{ value: 6, text: 'Registered Non-Hong Kong Company' }
								]" -->
                <!-- <v-option :value="0">-- Select Type --</v-option>
								<option :value="1">Public Limited</option>
								<option :value="2">Private Limited</option>
								<option :value="3">Private Unlimited with Share Capital</option>
								<option :value="4">Public Unlimited with Share Capital</option>
								<option :value="5">Company Limited by Guarantee without a Share Capital</option>
								<option :value="6">Registered Non-Hong Kong Company</option> -->
              </v-select>
            </v-col>
            <v-col
              cols="6"
              v-if="form.company_type == 5"
              data-cy="no_of_pps_member"
            >
              <v-text-field
                :label="$t('company.details.form.pps_members.label')"
                v-model="form.pps_members.number_of_members"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
            >
              <v-text-field
                :label="$t('company.details.form.coi_number.label')"
                v-model="form.coi_number"
                data-cy="coi_number"
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                :label="$t('company.details.form.br_number.label')"
                v-model="form.br_number"
                data-cy="br_number"
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-checkbox
                v-model="form.is_br_3y"
                :label="$t('company.details.form.is_br_3y.label')"
                data-cy="is_br_3y_checkbox"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              data-cy="country_selector_col"
            >
              <country-selector
                v-model="form.incorp_place"
                ref="incorp_place"
                :label="$t('company.details.form.incorp_place.label')"
                :hint="$t('company.details.form.incorp_place.hint')"
                persistent-hint
                data-cy="incorp_place"
              />
              <!-- <small>this value will cause a change in the risk level</small> -->
            </v-col>
            <v-col
              cols="6"
              data-cy="incorp_date_col"
            >
              <InputDate
                :label="$t('company.details.form.incorp_date.label')"
                format="yyyy-MM-dd"
                v-model="form.incorp_date"
                data-cy="incorp_date"
                ref="incorp_date"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-2" />
        <!-- <hr> -->
        <v-card-title>{{ $t('company.details.form_name.title') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              data-cy="company_eng_name_col"
            >
              <v-menu
                :offset-x="true"
                :value="like.name_form.english.is_show"
                :close-on-content-click="false"
                :close-on-click="false"
                :open-on-click="false"
              >
                <template v-slot:activator="{ attrs }">
                  <v-text-field
                    :label="$t('company.details.form_name.english.label')"
                    transition="scale-transition"
                    v-bind="{...attrs}"
                    v-model="name_form.english.other"
                    data-cy="company_eng_name_textfield"
                    @focus="toggleSimilarDropdown(like.name_form.english, true)"
                    @change="toggleSimilarDropdown(like.name_form.english, name_form.english.other.length > 0)"
                    @blur="toggleSimilarDropdown(like.name_form.english, false)"
                    :error="$v.name_form.english.$error || $v.name_form.english.other.$error || $v.form.names.$error"
                    :error-messages="($v.name_form.english.$error || $v.name_form.english.other.$error || $v.form.names.$error) ? (
                      !$v.form.names.at_least_one_company_name ? $t('company.details.form_name.validate.at_least_one_company_name') : (
                        !$v.name_form.english.other.required ? $t('validate.required', { field: $t('company.details.form_name.english.label') }) : (
                          !$v.name_form.english.duplicate_local ? $t('company.details.form_name.validate.duplicate_local', { field: $t('company.details.form_name.english.label') }) : (
                            !$v.name_form.english.duplicate_db ? $t('company.details.form_name.validate.duplicate_db', { field: $t('company.details.form_name.english.label') }) : '')))) : ''"
                  />
                </template>
                <v-card :loading="!!name_similar_check_timeout">
                  <v-card-subtitle>{{ $t('company.details.form_name.dropdown.title') }}</v-card-subtitle>
                  <v-card-text v-if="like.name_form.english.options.length == 0">
                    {{ $t('company.details.form_name.dropdown.no_records') }}
                  </v-card-text>
                  <v-list v-else>
                    <v-list-item
                      v-for="(name, key) in like.name_form.english.options"
                      @mousedown="switchCompanyDetails(name.company.id)"
                      :key="key"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ name.other }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col
                              class="py-0"
                              style="max-width:200px"
                            >
                              {{ $t('company.details.form.company_type.label') }}:
                            </v-col>
                            <v-col class="py-0">
                              {{ name.company | company_type }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              class="py-0"
                              style="max-width:200px"
                            >
                              {{ $t('company.details.form.coi_number.label') }}:
                            </v-col>
                            <v-col class="py-0">
                              {{ name.company.coi_number }}
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-btn-toggle dense>
                <v-menu
                  offset-y
                  v-if="form.names.filter(_name => _name.lang == 1 && !_name.is_deleted).length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-if="name_form.english.uuid">{{ $t('company.details.form_name.buttons.edit', { value: name_form.english.other }) }}</span>
                      <span v-else>
                        <span>{{ $t('company.details.form_name.buttons.select') }}
                          <!-- <v-icon>mdi-chevron-down</v-icon> -->
                        </span>
                        (<span class="badge bg-aqua">{{ form.names.filter(_name => _name.lang == 1 && !_name.is_deleted).length }}</span>)
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(_name, index) in form.names.filter(_name => _name.lang == 1 && !_name.is_deleted)"
                      @click="name_form.english = _name"
                      :key="index"
                    >
                      <v-list-item-title>
                        <span>#{{ index + 1 }} </span>
                        <span>{{ _name.other }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  :disabled="$v.name_form.english.$pending"
                  @click="saveRow(form.names, name_form.english, () => {
                    $v.name_form.english.$touch();
                    return $v.name_form.english.$invalid;
                  }, () => {
                    name_form.english = getNameForm(1, 3);
                    $v.name_form.english.$reset();
                  })"
                  data-cy="comp_eng_name_save_btn"
                >
                  {{ name_form.english.uuid ? $t('company.details.form_name.buttons.update') : $t('company.details.form_name.buttons.add') }}
                </v-btn>
                <v-btn
                  @click="removeRow(form.names, name_form.english, () => { name_form.english = getNameForm(1, 3); })"
                  v-if="name_form.english.uuid"
                >
                  {{ $t('company.details.form_name.buttons.remove') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col
              cols="6"
              data-cy="company_chn_name_col"
            >
              <v-menu
                :offset-x="true"
                left
                :value="like.name_form.chinese.is_show"
                :close-on-content-click="false"
                :close-on-click="false"
                :open-on-click="false"
              >
                <template v-slot:activator="{ attrs }">
                  <v-text-field
                    :label="$t('company.details.form_name.chinese.label')"
                    transition="scale-transition"
                    v-bind="{...attrs}"
                    v-model="name_form.chinese.other"
                    data-cy="company_chn_name_textfield"
                    @focus="toggleSimilarDropdown(like.name_form.chinese, true)"
                    @change="toggleSimilarDropdown(like.name_form.chinese, name_form.chinese.other.length > 0)"
                    @blur="toggleSimilarDropdown(like.name_form.chinese, false)"
                    :error="$v.name_form.chinese.$error || $v.name_form.chinese.other.$error || $v.form.names.$error"
                    :error-messages="($v.name_form.chinese.$error || $v.name_form.chinese.other.$error || $v.form.names.$error) ? (
                      !$v.form.names.at_least_one_company_name ? $t('company.details.form_name.validate.at_least_one_company_name') : (
                        !$v.name_form.chinese.other.required ? $t('validate.required', { field: $t('company.details.form_name.chinese.label') }) : (
                          !$v.name_form.chinese.other.chinese_words ? $t('company.details.form_name.validate.chinese_words', { field: $t('company.details.form_name.chinese.label') }) : (
                            !$v.name_form.chinese.duplicate_local ? $t('company.details.form_name.validate.duplicate_local', { field: $t('company.details.form_name.chinese.label') }) : (
                              !$v.name_form.chinese.duplicate_db ? $t('company.details.form_name.validate.duplicate_db', { field: $t('company.details.form_name.chinese.label') }) : ''))))) : ''"
                  />
                </template>
                <v-card :loading="!!name_similar_check_timeout">
                  <v-card-subtitle>{{ $t('company.details.form_name.dropdown.title') }}</v-card-subtitle>
                  <v-card-text v-if="like.name_form.chinese.options.length == 0">
                    {{ $t('company.details.form_name.dropdown.no_records') }}
                  </v-card-text>
                  <v-list v-else>
                    <v-list-item
                      v-for="(name, key) in like.name_form.chinese.options"
                      @mousedown="switchCompanyDetails(name.company.id)"
                      :key="key"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ name.other }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col
                              class="py-0"
                              style="max-width:200px"
                            >
                              {{ $t('company.details.form.company_type.label') }}:
                            </v-col>
                            <v-col class="py-0">
                              {{ name.company | company_type }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              class="py-0"
                              style="max-width:200px"
                            >
                              {{ $t('company.details.form.coi_number.label') }}:
                            </v-col>
                            <v-col class="py-0">
                              {{ name.company.coi_number }}
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-btn-toggle dense>
                <v-menu
                  offset-y
                  v-if="form.names.filter(_name => _name.lang == 2 && !_name.is_deleted).length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-if="name_form.chinese.uuid">{{ $t('company.details.form_name.buttons.edit', { value: name_form.chinese.other }) }}</span>
                      <span v-else>
                        <span>{{ $t('company.details.form_name.buttons.select') }}
                          <!-- <i class="fa fa-caret-down"></i> -->
                        </span>
                        (<span class="badge bg-aqua">{{ form.names.filter(_name => _name.lang == 2 && !_name.is_deleted).length }}</span>)
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(_name, index) in form.names.filter(_name => _name.lang == 2 && !_name.is_deleted)"
                      @click="name_form.chinese = _name"
                      :key="index"
                    >
                      <v-list-item-title>
                        <span>#{{ index + 1 }} </span>
                        <span>{{ _name.other }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  :disabled="$v.name_form.chinese.$pending"
                  @click="saveRow(form.names, name_form.chinese, () => {
                    $v.name_form.chinese.$touch();
                    return $v.name_form.chinese.$invalid;
                  }, () => {
                    name_form.chinese = getNameForm(2, 3);
                    $v.name_form.chinese.$reset();
                  })"
                  data-cy="comp_chn_name_save_btn"
                >
                  {{ name_form.chinese.uuid ? $t('company.details.form_name.buttons.update') : $t('company.details.form_name.buttons.add') }}
                </v-btn>
                <v-btn
                  @click="removeRow(form.names, name_form.chinese, () => { name_form.chinese = getNameForm(2, 3); })"
                  v-if="name_form.chinese.uuid"
                >
                  {{ $t('company.details.form_name.buttons.remove') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-2" />
        <!-- <hr> -->
        <v-row>
          <v-col cols="6">
            <v-card-title class="pr-0">
              {{ $t('company.details.form.tel.label') }}
            </v-card-title>
            <v-card-text class="pr-0">
              <v-row>
                <v-col cols="12">
                  <vue-tel-input-vuetify
                    v-model="form.tel"
                    :disabled-fetching-country="true"
                    default-country="HK"
                    :preferred-countries="['HK']"
                    :label="$t('company.details.form.tel.label')"
                    placeholder=""
                    data-cy="company_tel"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-title class="pl-0">
              {{ $t('company.details.form_email.title') }}
            </v-card-title>
            <v-card-text class="pl-0">
              <v-row>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-menu
                    :offset-x="true"
                    left
                    :value="like.email_form.is_show"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :open-on-click="false"
                  >
                    <template v-slot:activator="{ attrs }">
                      <v-text-field
                        :label="$t('company.details.form_email.email.label')"
                        transition="scale-transition"
                        v-bind="{...attrs}"
                        v-model="email_form.email"
                        @focus="toggleSimilarDropdown(like.email_form, true)"
                        @change="toggleSimilarDropdown(like.email_form, email_form.email.length > 0); $v.email_form.$touch()"
                        @blur="toggleSimilarDropdown(like.email_form, false)"
                        :error="$v.email_form.email.$error"
                        :error-messages="$v.email_form.email.$error ? (
                          !$v.email_form.email.required ? $t('validate.required', { field: $t('company.details.form_email.email.label') }) : (
                            !$v.email_form.email.email ? $t('validate.email') : '')) : ''"
                        data-cy="company_email"
                      />
                    </template>
                    <v-card :loading="!!email_similar_check_timeout">
                      <v-card-subtitle>{{ $t('company.details.form_email.dropdown.title') }}</v-card-subtitle>
                      <v-card-text v-if="like.email_form.options.length == 0">
                        {{ $t('company.details.form_email.dropdown.no_records') }}
                      </v-card-text>
                      <v-list v-else>
                        <v-list-item
                          v-for="(email, key) in like.email_form.options"
                          @click="switchCompanyDetails(email.company.id)"
                          :key="key"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{ email.email }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <v-row>
                                <v-col
                                  class="py-0"
                                  style="max-width:200px"
                                >
                                  {{ $t('company.details.form.company_type.label') }}:
                                </v-col>
                                <v-col class="py-0">
                                  {{ email.company | company_type }}
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  class="py-0"
                                  style="max-width:200px"
                                >
                                  {{ $t('company.details.form.coi_number.label') }}:
                                </v-col>
                                <v-col class="py-0">
                                  {{ email.company.coi_number }}
                                </v-col>
                              </v-row>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col
                  cols="6"
                  data-cy="company_email_col"
                >
                  <InputDate
                    v-model="email_form.effect_date"
                    :label="$t('company.details.form_email.effect_date.label')"
                    data-cy="email_eff_date"
                    ref="email_effect_date"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <v-btn-toggle dense>
                    <v-menu
                      offset-y
                      v-if="(form.emails || []).filter(email => !email.is_deleted).length"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          data-cy="company_email_edit_btn"
                        >
                          <span v-if="email_form.uuid">{{ $t('company.details.form_email.buttons.edit', { value: email_form.email }) }}</span>
                          <span v-else>
                            <span>{{ $t('company.details.form_email.buttons.select') }}
                              <!-- <i class="fa fa-caret-down"></i> -->
                            </span>
                            (<span class="badge bg-aqua">{{ form.emails.filter(email => !email.is_deleted).length }}</span>)
                          </span>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(email, index) in form.emails.filter(email => !email.is_deleted)"
                          @click="email_form = email"
                          :key="index"
                        >
                          <v-list-item-title>
                            <table>
                              <tr>
                                <td
                                  class="col-xs-1"
                                  style="vertical-align: top;"
                                >
                                  #{{ index + 1 }}
                                </td>
                                <td class="col-xs-6">
                                  <span>{{ email.email }}</span>
                                </td>
                                <td
                                  class="col-xs-1"
                                  style="vertical-align: top;"
                                >
                                  <span v-if="email.effect_date"> [eff. <timeago
                                    :datetime="email.effect_date"
                                    :auto-update="60"
                                  />]</span>
                                </td>
                              </tr>
                            </table>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn
                      @click=" $refs.email_effect_date.correctDate?
                        saveRow(form.emails, email_form, () => {
                          $v.email_form.$touch();
                          return $v.email_form.$invalid;
                        }, () => {
                          email_form = getEmailForm()
                          $v.email_form.$reset();
                        }) : null"
                      data-cy="company_email_save_btn"
                    >
                      {{ email_form.uuid ? $t('company.details.form_email.buttons.update') : $t('company.details.form_email.buttons.add') }}
                    </v-btn>
                    <v-btn
                      @click="removeRow(form.emails, email_form, () => { email_form = getEmailForm() })"
                      v-if="email_form.uuid"
                      data-cy="company_email_delete_btn"
                    >
                      {{ $t('company.details.form_email.buttons.remove') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider class="my-2" />
        <!-- <hr> -->
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="$t('company.details.form.fund_source.label')"
                v-model="form.fund_source"
                data-cy="fund_source_textfield"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('company.details.form.business_nature.label')"
                v-model="form.business_nature"
                data-cy="business_nature_textfield"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-2" />
      </component>
      <component
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('company.details.tabs.address') } : { title: $t('company.details.tabs.address') }"
        :before-change="validateAsync"
      >
        <v-row>
          <template v-for="(form_type, key) in ['current', 'correspondence', 'principal_hk', 'principal_registered_country']">
            <v-col
              cols="6"
              v-if="['current', 'correspondence'].indexOf(form_type) != -1 || (form.company_type == 6 && ['principal_hk', 'principal_registered_country'].indexOf(form_type) != -1)"
              :key="key"
            >
              <!-- <v-card-subtitle>{{ form_type == 'current' ? 'Proposed / Current Address of Registered Office' : (form_type == 'correspondence' ? 'Correspondence Address' : (form_type == 'principal_hk' ? 'Principal Place of Business in Hong Kong' : (form_type == 'principal_registered_country' ? 'Principal Place of Business in Registered Country' : ''))) }}</v-card-subtitle> -->
              <v-card-subtitle
                :data-cy="`company_${form_type}_add_title`"
              >
                {{ $t(`company.details.form_address.titles.${form_type}`) }}
              </v-card-subtitle>
              <v-card-text>
                <v-text-field
                  v-model="address_form[form_type].address_1"
                  :label="$t('company.details.form_address.address_1.label')"
                  :error="$v.address_form[form_type].address_1.$error"
                  :error-messages="$v.address_form[form_type].address_1.$error ? $t('validate.required', { field: $t('company.details.form_address.address_1.label') }) : ''"
                  :data-cy="`company_add_1_${key}`"
                />
                <v-text-field
                  v-model="address_form[form_type].address_2"
                  :label="$t('company.details.form_address.address_2.label')"
                  :data-cy="`company_add_2_${key}`"
                />
                <v-text-field
                  v-model="address_form[form_type].address_3"
                  :label="$t('company.details.form_address.address_3.label')"
                  :data-cy="`company_add_3_${key}`"
                />
                <country-selector
                  v-model="address_form[form_type].address_4"
                  :label="$t('company.details.form_address.address_4.label')"
                  :error="$v.address_form[form_type].address_4.$error"
                  :error-messages="$v.address_form[form_type].address_4.$error ? $t('validate.required', { field: $t('company.details.form_address.address_4.label') }) : ''"
                  :data-cy="`company_add_4_${key}`"
                />
                <InputDate
                  v-model="address_form[form_type].effect_date"
                  :label="$t('company.details.form_address.effect_date.label')"
                  :data-cy="`add_eff_date_${key}`"
                  :ref="`address_effect_date`"
                />
                <v-btn-toggle dense>
                  <v-menu
                    offset-y
                    v-if="(form.addresses || []).filter(address => address.address_type == address_form[form_type].address_type && !address.is_deleted).length"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :data-cy="`add_edit_btn_${key}`"
                      >
                        <span v-if="address_form[form_type].uuid">{{ $t('company.details.form_address.buttons.edit', { value: address_form[form_type].address_1 }) }}
                          <!-- <span v-if="address_form[form_type].effect_date"> [{{ address_form[form_type].effect_date }}]</span> -->"</span>
                        <span v-else>
                          <span>{{ $t('company.details.form_address.buttons.select') }}
                            <!-- <i class="fa fa-caret-down"></i> -->
                          </span>
                          (<span class="badge bg-aqua">{{ form.addresses.filter(address => address.address_type == address_form[form_type].address_type && !address.is_deleted).length }}</span>)
                        </span>
                      </v-btn>
                    </template>
                    <v-list
                      :data-cy="`${form_type}_add_list`"
                    >
                      <v-list-item
                        v-for="(address, index) in form.addresses.filter(address => address.address_type == address_form[form_type].address_type && !address.is_deleted)"
                        @click="address_form[form_type] = address"
                        :key="index"
                      >
                        <v-list-item-title>
                          <table>
                            <tr
                              :data-cy="`${form_type}_add_${index+1}`"
                            >
                              <td
                                class="col-xs-1"
                                style="vertical-align: top;"
                              >
                                #{{ index + 1 }}
                              </td>
                              <td class="col-xs-6">
                                <div>{{ address.address_1 }}</div>
                                <!-- <div>{{ address.address_2 }}</div>
																<div>{{ address.address_3 }}</div>
																<div>{{ address.address_4 }}</div> -->
                              </td>
                              <td
                                class="col-xs-1"
                                style="vertical-align: top;"
                              >
                                <span v-if="address.effect_date"> [eff. <timeago
                                  :datetime="address.effect_date"
                                  :auto-update="60"
                                />]</span>
                              </td>
                            </tr>
                          </table>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    @click="$refs.address_effect_date[key].correctDate?
                      saveRow(form.addresses, address_form[form_type], () => {
                        $v.address_form[form_type].$touch();
                        return $v.address_form[form_type].$invalid;
                      }, () => {
                        address_form[form_type] = getAddressForm(address_form[form_type].address_type);
                        $v.address_form[form_type].$reset();
                      }) : null"
                    :data-cy="`add_save_btn_${key}`"
                  >
                    {{ address_form[form_type].uuid ? $t('company.details.form_address.buttons.update') : $t('company.details.form_address.buttons.add') }}
                  </v-btn>
                  <v-btn
                    @click="removeRow(form.addresses, address_form[form_type], () => { address_form[form_type] = getAddressForm(address_form[form_type].address_type) })"
                    v-if="address_form[form_type].uuid"
                    :data-cy="`add_delete_btn_${key}`"
                  >
                    {{ $t('company.details.form_address.buttons.remove') }}
                  </v-btn>
                </v-btn-toggle>
              </v-card-text>
            </v-col>
          </template>
        </v-row>
      </component>
      <component
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('company.details.tabs.members') } : { title: $t('company.details.tabs.members') }"
        :before-change="validateAsync"
      >
        <!-- <button @click="addMember" type="button" class="btn btn-primary">Add Member/Officer</button> -->
        <v-col>
          <v-btn
            color="primary"
            outlined
            @click="addMember"
            data-cy="add_company_member_btn"
          >
            {{ $t('company.details.form_member.buttons.add') }}
          </v-btn>
        </v-col>
        <v-client-table
          :data="people"
          :columns="member_table.columns"
          :options="member_table.options"
          data-cy="member_table"
        >
          <div
            slot="role"
            slot-scope="props"
          >
            {{ props.row.role }}
          </div>
          <div
            slot="name"
            slot-scope="props"
          >
            <div v-if="props.row.is_company">
              {{ props.row.company | company_name }}
            </div>
            <div v-else>
              {{ props.row.person | natural_person_name }}
            </div>
          </div>
          <div
            slot="alerts"
            slot-scope="props"
          >
            {{ (props.row.company || props.row.person).alerts_pending }} / {{ (props.row.company || props.row.person).alerts_true }} / {{ (props.row.company || props.row.person).alerts_false }}
          </div>
          <div
            slot="action"
            slot-scope="props"
          >
            <!-- <a @click="editholder(props.row.uuid)"> Edit</a> -->
            <v-btn
              icon
              class="ma-1"
              @click="editMember(props.row)"
              :data-cy="`member_edit_btn_${props.index}`"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              class="ma-1"
              color="error"
              @click="removeMember(props.row)"
              :data-cy="`member_remove_btn_${props.index}`"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <!-- <v-btn style="cursor: pointer;" @click="editMember(props.row)"> Edit</v-btn>
						<v-btn style="cursor: pointer;" @click="removeMember(props.row)"> Delete </v-btn> -->
          </div>
        </v-client-table>
        <v-dialog
          v-model="dialog_member_detail"
          max-width="90%"
        >
          <v-card
            data-cy="add_company_member_dialog"
          >
            <MemberDetail2
              v-if="dialog_member_detail"
              :records="dialog_member_detail_record"
              @onSaveMember="(member) => {
                this.onSaveMember(member);
                dialog_member_detail = false;
                dialog_member_detail_record = null;
              }"
              @close="dialog_member_detail = false;"
              data-cy="company_member_new"
            />
          </v-card>
        </v-dialog>
      </component>
      <component
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('company.details.tabs.contact') } : { title: $t('company.details.tabs.contact') }"
        :before-change="validateAsync"
      >
        <v-card-title
          data-cy="company_contact_person_title"
        >
          {{ $t('company.details.tabs.contact') }}
        </v-card-title>
        <v-card-text>
          <!-- <h4>Contact Person</h4> -->
          <v-text-field
            :label="$t('company.details.form.contact_person.label')"
            v-model="form.contact_person"
            data-cy="contact_person_name"
          />
          <!-- <v-tel-input-vuetify label="Tel" v-model="form.contact_person_tel"></v-tel-input-vuetify> -->
          <vue-tel-input-vuetify
            v-model="form.contact_person_tel"
            :label="$t('company.details.form.contact_person_tel.label')"
            :disabled-fetching-country="true"
            default-country="HK"
            :preferred-countries="['HK']"
            placeholder=""
            data-cy="company_contact_person_tel"
          />
          <vue-tel-input-vuetify
            v-model="form.contact_fax"
            :label="$t('company.details.form.contact_fax.label')"
            :disabled-fetching-country="true"
            default-country="HK"
            :preferred-countries="['HK']"
            placeholder=""
            data-cy="company_contact_person_fax"
          />
        </v-card-text>
      </component>
      <!-- <component
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('company.details.tabs.business') } : { title: $t('company.details.tabs.business') }"
        :before-change="validateAsync"
      >
        <v-card-text>
          <v-checkbox
            v-model="form.is_br_3y"
            :label="$t('company.details.form.is_br_3y.label')"
          />
        </v-card-text>
      </component> -->
      <component
        ref="others_tab"
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('company.details.tabs.others'), suffix: !isTabActive('others_tab') && ($v.form.acc_start_date.$error || $v.form.acc_end_date.$error) ? ' <span class=\'badge bg-red\'>&nbsp;</span>' : '' } : { title: $t('company.details.tabs.others') }"
        :before-change="validateAsync"
      >
        <v-card-title
          data-cy="other_title"
        >
          {{ $t('company.details.form_oversea.title') }}
        </v-card-title>
        <v-card-text>
          <InputDate
            v-model="form.estab_date"
            :label="$t('company.details.form.estab_date.label')"
            data-cy="business_estab_date"
            ref="company_estab_date"
          />
          <v-text-field
            v-model="form.ma_name"
            :label="$t('company.details.form.ma_name.label')"
            data-cy="ma_name_textfield"
          />
          <v-text-field
            v-model="form.coi_name"
            :label="$t('company.details.form.coi_name.label')"
            data-cy="coi_name_textfield"
          />
        </v-card-text>
        <v-divider class="my-2" />
        <!-- <hr> -->
        <v-card-title>{{ $t('company.details.form_accounting.title') }}</v-card-title>
        <!-- <h4>Accounts</h4> -->
        <v-card-text>
          <v-radio-group v-model="accounting_needed">
            <template v-slot:label>
              <div>{{ $t('company.details.form_accounting.accounting_needed.label') }}</div>
            </template>
            <div
              data-cy="account_needed_div"
            >
              <v-radio
                :value="true"
              >
                <template v-slot:label>
                  <v-row
                    data-cy="account_needed_row"
                  >
                    <v-col
                      cols="1"
                      class="align-self-center py-0"
                    >
                      {{ accounting_needed ? $t('company.details.form_accounting.accounting_needed.peroid.from') : 'Yes' }}
                    </v-col>
                    <template v-if="accounting_needed">
                      <v-col
                        cols="5"
                        class="py-0"
                      >
                        <InputDate
                          v-model="form.acc_start_date"
                          :max-datetime="form.acc_end_date"
                          :label="$t('company.details.form.acc_start_date.label')"
                          :error="$v.form.acc_start_date.$error"
                          :error-messages="$v.form.acc_start_date.$error ? (
                            !$v.form.acc_start_date.required ? $t('validate.required', { field: $t('company.details.form.acc_start_date.label') }) : (
                              !$v.form.acc_end_date.maxValue ? $t('validate.maxValue', { field: $t('company.details.form.acc_start_date.label'), value: $t('company.details.form.acc_end_date.label') }) : '')) : ''"
                          data-cy="account_start_date"
                          ref="acc_start_date"
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        class="align-self-center py-0"
                      >
                        {{ $t('company.details.form_accounting.accounting_needed.peroid.to') }}
                      </v-col>
                      <v-col
                        cols="5"
                        class="py-0"
                      >
                        <InputDate
                          v-model="form.acc_end_date"
                          :max-datetime="form.acc_end_date"
                          :label="$t('company.details.form.acc_end_date.label')"
                          :error="$v.form.acc_end_date.$error"
                          :error-messages="$v.form.acc_end_date.$error ? (
                            !$v.form.acc_end_date.required ? $t('validate.required', { field: $t('company.details.form.acc_end_date.label') }) : (
                              !$v.form.acc_end_date.minValue ? $t('validate.minValue', { field: $t('company.details.form.acc_end_date.label'), value: $t('company.details.form.acc_start_date.label') }) : '')) : ''"
                          data-cy="account_end_date"
                          ref="acc_end_date"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </v-radio>
            </div>
            <!-- <v-row v-if="accounting_needed">
							<v-col cols="12">
								<v-date-picker v-model="accounting_range" range no-title scrollable></v-date-picker>
							</v-col>
						</v-row> -->
            <div
              data-cy="account_not_needed_div"
            >
              <v-radio :value="false">
                <template v-slot:label>
                  <v-row
                    data-cy="account_not_needed_row"
                  >
                    <v-col
                      style="max-width:  240px;"
                      class="align-self-center py-0"
                    >
                      {{ accounting_needed ? 'No' : $t('company.details.form.acc_no_reason.label') }}&nbsp;
                    </v-col>
                    <v-col
                      v-show="!accounting_needed"
                      class="py-0"
                    >
                      <v-select
                        v-model="form.acc_no_reason"
                        :label="$t('company.details.form.acc_no_reason.placeholder')"
                        clearable
                        :items="$t('company.details.form.acc_no_reason.options').map((text, key) => ({ value: key, text: text }))"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </v-card-text>
        <v-divider class="my-2" />
      </component>
      <component
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('company.details.tabs.roles') } : { title: $t('company.details.tabs.roles') }"
        v-if="$slots.roles"
        :before-change="validateAsync"
      >
        <slot name="roles" />
      </component>
      <v-tab-item
        v-if="is_edit_mode && companyId"
        name="Alerts"
        :suffix=" form.alerts_pending > 0 ? (' <span class=\'badge bg-red\'>' + form.alerts_pending + '</span>') : ''"
      >
        <loading
          :active.sync="alerts_table.requestLoading"
          :is-full-page="false"
        />
        <div
          data-cy="alert_table"
        >
          <v-server-table
            ref="alerts_table"
            :name="'alerts/alertsTable' + _uid"
            :columns="alerts_table.columns"
            :options="alerts_table_options"
            @loading="alerts_table.requestLoading = true"
            @loaded="alerts_table.requestLoading = false"
          >
            <div
              slot="service_id"
              slot-scope="props"
            >
              {{ props.row.service_id }}
            </div>
            <div
              slot="create_date"
              slot-scope="props"
            >
              <DateFormat
                :date="props.row.create_date"
                :showtime="true"
              />
            </div>
            <div
              slot="hit_name"
              slot-scope="props"
            >
              {{ props.row.hit_name }}
            </div>
            <!-- <div slot="hit_status" slot-scope="props">{{ parseInt(props.row.hit_status) == 1 ? 'True Hit' : (parseInt(props.row.hit_status) == 2 ? 'False Hit' : 'Pending' )  }}</div> -->
            <div
              slot="hit_status"
              slot-scope="props"
            >
              {{ (hit_status_options.find(x => x.value == props.row.hit_status) || { text: '' }).text }}
            </div>
            <div
              slot="exposition"
              slot-scope="props"
              style="max-width: 300px;"
            >
              {{ (exposition_options(props.row.hit_status).find(x => x.value == props.row.exposition) || { text: '' }).text }}
            </div>
            <div
              slot="remarks"
              slot-scope="props"
            >
              <TextareaSafeDisplay :value="props.row.remarks" />
            </div>
            <div
              slot="action"
              slot-scope="props"
            >
              <v-btn
                icon
                @click="popup_alert_detail(props.row);"
              >
                <v-icon>{{ props.row.hit_status == 0 ? 'mdi-pencil' : 'mdi-eye' }}</v-icon>
              </v-btn>
            </div>
          </v-server-table>
        </div>
        <v-dialog
          v-model="dialog_alert_detail"
          max-width="90%"
        >
          <v-card>
            <PopupAlertDetail
              :records="dialog_alert_detail_record"
              @onSave="(form) => {
                $refs.alerts_table.getData()
                dialog_alert_detail = false;
                dialog_alert_detail_record = null;
              }"
            />
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item
        v-if="is_edit_mode && companyId"
        :name="$t('company.details.tabs.bank')"
      >
        <BankInfo
          :parent-id="companyId"
          parent-type="company"
        />
      </v-tab-item>
      <v-tab-item
        v-if="is_edit_mode && companyId"
        :name="$t('company.details.tabs.attachment')"
      >
        <Attachment
          :parent-id="companyId"
          parent-type="company"
        />
      </v-tab-item>
    </component>
    <!-- </div> -->
  </v-container>
</template>
<script type="text/javascript">
import uuidv4 from 'uuid/v4'
import Vue from 'vue'
// import MemberDetailForm2 from '@/components/forms/MemberDetail2'
import CountrySelector from '@/components/forms/CountrySelector'
import InputDate from '@/components/forms/InputDate'
import InputDateRange from '@/components/forms/InputDateRange'
import DateFormat from '@/components/common/DateFormat'
// import Names from '@/components/forms/company/Names'
// import Loading from 'vue-loading-overlay'
import * as helper from '@/store/helper'
import Vuelidate from 'vuelidate'
import { required, between, requiredIf, email } from 'vuelidate/lib/validators'
import PopupAlertDetail from '@/components/popups/PopupAlertDetail'
import { mapActions, mapGetters } from 'vuex'
import { VTabsItems, VStepper } from 'vuetify/lib'
// import CompanyCard from '@/components/Common/CompanyCard'
// import countries from '@/store/modules/countries'
// import SimilarDropdown from '@/components/Common/SimilarDropdown'
// import NaturalPersonCard from '@/components/Common/NaturalPersonCard'
import Attachment from '@/components/forms/Attachment'
import BankInfo from '@/components/forms/BankInfo'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'

Vue.use(Vuelidate)

export default {
  name: 'Company2',
  components: {
    // Names,
    CountrySelector,
    InputDate,
    InputDateRange,
    DateFormat,
    PopupAlertDetail,
    // MemberDetailForm2: require('@/components/forms/MemberDetail2').default,
    VTabsItems,
    VStepper,
    Attachment,
    BankInfo,
    TextareaSafeDisplay
    // Loading,
    // CompanyCard,
    // SimilarDropdown,
    // NaturalPersonCard
  },
  store: window.$store,
  // props: ['value', 'companyId'/*, 'parent_company_id' */],
  props: {
    companyId: {
      type: [Number, String],
      default: null
    }
  },

  watch: {
    companyId () {
      this.loadCompanyDetails()
    },
    'form.incorp_place' (/* incorp_place */) {
      this.risk_loading = true
      this.risk_level = this.$t('company.details.form.risk_level.options.Not In List')
      this.risk_detail = 0
      // this.risk_loading = false;
      this.$store.dispatch('risk/get_risk_info', this.form.incorp_place).then((results) => {
        if (results && results.status === 200) {
          // $store.dispatch('risk/get_risk_info', 'Hong Kong')
          this.risk_level = results.data.risk_level || this.$t('company.details.form.risk_level.options.Not In List')
          this.risk_detail = results.data.risk_score || 0
        }
      })
        .finally(() => {
          this.risk_loading = false
        })
    },
    accounting_needed (_accounting_needed) {
      if (!_accounting_needed) {
        this.form.acc_start_date = 0
        this.form.acc_end_date = 0
      }
    },
    email_form: {
      deep: true,
      handler (email) {
        clearTimeout(this.email_similar_check_timeout)
        this.email_similar_check_timeout = null

        if (!email.email) {
          this.like.email_form.options = []
          return this.like.email_form.options
        }

        this.email_similar_check_timeout = setTimeout(() => {
          this.$store.dispatch('company/similarEmail', {
            company_id: this.form.id,
            email_address: email.email
          })
            .then((results) => {
              if (results.data) { this.like.email_form.options = results.data }
              this.email_similar_check_timeout = null
            })
        }, 500)
      }
    },
    'name_form.chinese': {
      deep: true,
      handler (name_form) {
        this.name_similar_check('chinese', name_form)
      }
    },
    'name_form.english': {
      deep: true,
      handler (name_form) {
        this.name_similar_check('english', name_form)
      }
    }
  },
  computed: {
    ...mapGetters({
      hit_status_options: 'alerts/hit_status_options',
      exposition_options: 'alerts/exposition_options'
    }),
    is_edit_mode () {
      return this.form.id
    },
    accounting_range: {
      get () {
        return [
          parseInt(this.form.acc_start_date) ? this.form.acc_start_date : null,
          parseInt(this.form.acc_end_date) ? this.form.acc_end_date : null
        ].filter(Boolean)
      },
      set (_value) {
        var [acc_start_date, acc_end_date] = _value || []
        this.form.acc_start_date = acc_start_date
        this.form.acc_end_date = acc_end_date
      }
    },
    people () {
      var founder = JSON.parse(JSON.stringify(this.members)).reduce(function (founders, member) {
        if (member.is_founder) {
          member.role = 'Founder'
          founders.push(member)
        }

        return founders
      }, [])
      const shareholder = JSON.parse(JSON.stringify(this.members)).reduce(function (shareholders, member) {
        if (member.is_shareholder) {
          member.role = 'Shareholder'
          shareholders.push(member)
        }

        return shareholders
      }, [])
      var director = JSON.parse(JSON.stringify(this.members)).reduce(function (directors, member) {
        if (member.is_director) {
          var director_type = Vue.filter('director_type')(member.director.director_type)
          member.role = 'Director' + (director_type ? ' (' + director_type + ')' : '')
          directors.push(member)
        }

        return directors
      }, [])
      var secretary = JSON.parse(JSON.stringify(this.members)).reduce(function (secretaries, member) {
        if (member.is_secretary) {
          member.role = 'Secretary'
          secretaries.push(member)
        }

        return secretaries
      }, [])
      var auth_rep = JSON.parse(JSON.stringify(this.members)).reduce(function (auth_reps, member) {
        if (member.is_auth_rep) {
          member.role = 'Auth Rep'
          auth_reps.push(member)
        }

        return auth_reps
      }, [])
      var significant_controller = JSON.parse(JSON.stringify(this.members)).reduce(function (significant_controllers, member) {
        if (member.is_significant_controller) {
          member.role = 'Significant Controller'
          significant_controllers.push(member)
        }

        return significant_controllers
      }, [])
      const full = [].concat(founder, shareholder, director, secretary, auth_rep, significant_controller)

      return full
    },
    member_table () {
      return {
        columns: ['role', 'name', 'alerts', 'action'],
        options: {
          columnsClasses: {
            role: 'col-xs-4',
            name: 'col-xs-4',
            alerts: 'col-xs-3',
            action: 'col-xs-1'
          },
          sortable: [
            'role',
            'name'
          ],
          filterable: false,
          headings: {
            role: this.$t('company.details.form_member.table.role.label'),
            name: this.$t('company.details.form_member.table.name.label'),
            alerts: this.$t('company.details.form_member.table.alerts.label'),
            action: this.$t('listing.table.action.label')
          },
          texts: {
            count: this.$t('listing.table.texts.count'),
            first: this.$t('listing.table.texts.first'),
            last: this.$t('listing.table.texts.last'),
            filter: this.$t('listing.table.texts.filter'),
            filterPlaceholder: this.$t('listing.table.texts.filterPlaceholder'),
            limit: this.$t('listing.table.texts.limit'),
            page: this.$t('listing.table.texts.page'),
            noResults: this.$t('listing.table.texts.noResults'),
            noRequest: this.$t('listing.table.texts.noRequest'),
            filterBy: this.$t('listing.table.texts.filterBy'),
            loading: this.$t('listing.table.texts.loading'),
            defaultOption: this.$t('listing.table.texts.defaultOption'),
            columns: this.$t('listing.table.texts.columns')
          }
        }
      }
    },
    alerts_table_options () {
      return {
        requestFunction: (request) => {
          if (!this.companyId) {
            return false
          }

          clearTimeout(this.alerts_table.requestTimeout)
          return new Promise((resolve, reject) => {
            this.alerts_table.requestTimeout = setTimeout(async () => {
              try {
                var criteria = {
                  // params: {
                  // where: request.query, // Todo: parse where query
                  where: (((query) => {
                    var _where = { company: this.companyId }

                    if (query.hit_name) _where.hit_name = { contains: query.hit_name }
                    if (query.id) _where.id = query.id
                    if (query.service_id) _where.service_id = { contains: query.service_id }
                    if (query.exposition) _where.exposition = { contains: query.exposition }
                    if (query.remarks) _where.remarks = { contains: query.remarks }
                    if (query.hit_status != null) _where.hit_status = query.hit_status
                    if (query.create_date) { // _where.create_date = query.create_date
                      _where.and = [
                        { create_date: { '>': new Date(query.create_date.start).getTime() } },
                        { create_date: { '<': new Date(query.create_date.end).getTime() } }
                      ]
                    }

                    return _where
                  })(request.query)),
                  sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                  limit: request.limit,
                  skip: request.limit * (request.page - 1)
                  // }
                }
                var count = await this.$store.dispatch('alerts/count', criteria)
                var results = await this.$store.dispatch('alerts/findWhere', criteria)

                resolve({ data: results.data, count: count.data.count }) // https://matanya.gitbook.io/vue-tables-2/server-table
              } catch (error) {
                console.log('Alerts History: ' + error)
                reject(error)
              }
            }, 800)
          })
        },
        columnsClasses: {
          id: 'col-xs-1',
          service_id: 'col-xs-1',
          create_date: 'col-xs-1',
          hit_name: 'col-xs-2',
          hit_status: 'col-xs-1',
          exposition: 'col-xs-3',
          // remarks: 'col-xs-3',
          action: 'col-xs-1'
        },
        sortable: ['id', 'service_id', 'create_date', 'hit_name', 'hit_status', 'exposition'],
        headings: {
          id: this.$t('alert.details.form.id.label'),
          service_id: this.$t('alert.details.form.service_id.label'),
          hit_name: this.$t('alert.details.form.hit_name.label'), // 'Company Name',
          hit_status: this.$t('alert.details.form.hit_status.label'), // 'Status',
          create_date: this.$t('alert.details.form.create_date.label'), // 'Date Created',
          updated_date: this.$t('alert.details.form.updated_date.label'), // 'Date Updated',
          exposition: this.$t('alert.details.form.exposition.label'),
          action: this.$t('listing.table.action.label')
        },
        // sortable: [],
        filterable: ['id', 'service_id', 'create_date', 'hit_name', 'hit_status', 'exposition'],
        filterByColumn: true,
        dateColumns: ['create_date'],
        // dateFormat: 'DD/MMM/YYYY',
        // datepickerOptions: {
        // 	// alwaysShowCalendars: true,
        // 	ranges: {
        // 		[this.$t('listing.table.datepicker.today')]: [moment(), moment()],
        // 		[this.$t('listing.table.datepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        // 		[this.$t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [moment().subtract(6, 'days'), moment()],
        // 		[this.$t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [moment().subtract(29, 'days'), moment()],
        // 		[this.$t('listing.table.datepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
        // 		[this.$t('listing.table.datepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        // 	}
        // },
        texts: {
          count: this.$t('listing.table.texts.count'),
          first: this.$t('listing.table.texts.first'),
          last: this.$t('listing.table.texts.last'),
          filter: this.$t('listing.table.texts.filter'),
          filterPlaceholder: this.$t('listing.table.texts.filterPlaceholder'),
          limit: this.$t('listing.table.texts.limit'),
          page: this.$t('listing.table.texts.page'),
          noResults: this.$t('listing.table.texts.noResults'),
          noRequest: this.$t('listing.table.texts.noRequest'),
          filterBy: this.$t('listing.table.texts.filterBy'),
          loading: this.$t('listing.table.texts.loading'),
          defaultOption: this.$t('listing.table.texts.defaultOption'),
          columns: this.$t('listing.table.texts.columns')
        },
        listColumns: {
          hit_status: [
            {
              id: 0,
              text: this.$t('alert.details.form.hit_status.options')[0]// 'Pending'
            },
            {
              id: 1,
              text: this.$t('alert.details.form.hit_status.options')[1] // 'True hit',
            },
            {
              id: 2,
              text: this.$t('alert.details.form.hit_status.options')[2] // 'False hit',
            }
          ]
        }
        // see the options API
      }
    }
  },
  data () {
    return {
      risk_loading: false,
      risk_level: this.$t('company.details.form.risk_level.options.Not In List'),
      risk_detail: 0,
      step: 0,
      requestLoading: false,
      name_form: {
        english: helper.getNameForm(1, 3),
        chinese: helper.getNameForm(2, 3)
      },
      email_form: helper.getEmailForm(),
      address_form: {
        current: helper.getAddressForm(1),
        correspondence: helper.getAddressForm(2),
        principal_hk: helper.getAddressForm(3),
        principal_registered_country: helper.getAddressForm(4)
      },
      bank_info_form: helper.getBankInfoForm(),
      accounting_needed: true,
      VB2form_loading: false,
      emitting: false,
      form: helper.initialCompany(),
      members: [],
      like: {
        name_form: {
          english: {
            is_show: false,
            options: []
          },
          chinese: {
            is_show: false,
            options: []
          }
        },
        email_form: {
          is_show: false,
          options: []
        }
      },
      alerts_table: {
        requestTimeout: null,
        requestLoading: false,
        columns: ['id', 'service_id', 'create_date', 'hit_name', 'hit_status', 'exposition', /* 'remarks', */'action']
      },
      active_penal: [0],
      tab: '',
      dialog_alert_detail: false,
      dialog_alert_detail_record: null,
      dialog_member_detail: false,
      dialog_member_detail_record: null,
      email_similar_check_timeout: null,
      name_similar_check_timeout: null
    }
  },
  validations () {
    var rules = {
      name_form: {
        english: {
          other: {
            required
          },
          duplicate_local (name) {
            return new Promise((resolve) => {
              var duplicate = this.form.names.filter((_name) => {
                return !_name.is_deleted && _name.other === name.other && _name.lang === name.lang && _name.name_type === name.name_type && _name.uuid !== name.uuid
              })
              // console.log(duplicate);
              resolve(!(duplicate && duplicate.length > 0))
            })
          }
          // duplicate_db (name) {
          // 	return name_duplicate_with_db('english', this.form.id, name);
          // 	// if (!name.other)
          // 	// 	return true;

          // 	// return new Promise((resolve, reject) => {
        },
        chinese: {
          other: {
            required,
            chinese_words (/* company_name */) {
              return true
              // return new Promise((resolve, reject) => {
              // 	resolve(/[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf][0-9]/ug.test(company_name))
              // });
            }
          },
          duplicate_local (name) {
            return new Promise((resolve) => {
              var duplicate = this.form.names.filter((_name) => {
                return !_name.is_deleted && _name.other === name.other && _name.lang === name.lang && _name.name_type === name.name_type && _name.uuid !== name.uuid
              })
              // console.log(duplicate);
              resolve(!(duplicate && duplicate.length > 0))
            })
          }
        }
      },
      email_form: {
        email: {
          required,
          email
        }
        // duplicate_db (email) {
        // 	return email_duplicate_with_db(this.form.id, email)
        // }
      },
      address_form: {
        current: {
          address_1: {
            required
          },
          // address_2: {
          //   required
          // },
          // address_3: {
          //   required
          // },
          address_4: {
            required
          }
        },
        correspondence: {
          address_1: {
            required
          },
          // address_2: {
          //   required
          // },
          // address_3: {
          //   required
          // },
          address_4: {
            required
          }
        },
        principal_hk: {
          address_1: {
            required
          },
          // address_2: {
          //   required
          // },
          // address_3: {
          //   required
          // },
          address_4: {
            required
          }
        },
        principal_registered_country: {
          address_1: {
            required
          },
          // address_2: {
          //   required
          // },
          // address_3: {
          //   required
          // },
          address_4: {
            required
          }
        }
      },
      form: {
        company_type: {
          required,
          between: between(1, 6)
        },
        names: {
          at_least_one_company_name (names) {
            return names.filter((name) => { return !name.is_deleted }).length !== 0
          }
        },
        // contact_email: {
        // 	email
        // },
        acc_end_date: {
          required: requiredIf(() => this.form.acc_start_date)
          // minValue: minValue(() => moment(this.form.acc_start_date).unix() * 1000)
        },
        acc_start_date: {
          required: requiredIf(() => this.form.acc_end_date)
          // maxValue: maxValue(() => this.form.acc_end_date)
        },
        bank_infos: {
          $each: {
            acc_number: {
              required
            }
          }
        }
      }
    }

    return rules
  },
  methods: {
    ...helper,
    ...mapActions({
      updateAlertInfo: 'scan/updateAlertInfo'
    }),
    isTabActive (tab_ref_name) {
      if (!this.is_edit_mode) {
        return true
      }
      return this.$refs[tab_ref_name] ? this.$refs[tab_ref_name].isActive : false
    },
    async saveRow (form_field, row_for_save, callback_validation, callback_after_save) {
      // Callback
      if (callback_validation && typeof callback_validation === 'function') {
        if (await callback_validation(form_field, row_for_save)) {
          console.log('invalid')
          return
        }
      }
      // Save Row
      if (row_for_save.uuid) {
        // Update Row
        var update_index = form_field.findIndex((row) => {
          return row.uuid === row_for_save.uuid
        })
        if (update_index !== -1) {
          form_field[update_index] = row_for_save
        }
      } else {
        // Insert Row
        row_for_save.uuid = uuidv4()
        form_field.push(row_for_save)
      }
      // Callback
      if (callback_after_save && typeof callback_after_save === 'function') {
        callback_after_save(form_field, row_for_save)
      }
    },
    removeRow (form_field, row_for_remove, callback_after_remove) {
      // Remove Row
      // var remove_index = form_field.findIndex((row) => {
      // 		return row.uuid == row_for_remove.uuid;
      // 	});
      // if (remove_index != -1) {
      // 	form_field.splice(remove_index, 1);
      // }
      row_for_remove.is_deleted = true
      // Callback
      if (callback_after_remove && typeof callback_after_remove === 'function') {
        callback_after_remove(form_field, row_for_remove)
      }
    },
    onSaveMember (results) {
      console.log('onSaveMember', results)
      var temp = JSON.parse(JSON.stringify(this.members))

      if (results.director) results.director.is_deleted = !results.is_director
      if (results.secretary) results.secretary.is_deleted = !results.is_secretary
      if (results.auth_rep) results.auth_rep.is_deleted = !results.is_auth_rep
      if (results.founder) results.founder.is_deleted = !results.is_founder
      if (results.shareholder) results.shareholder.is_deleted = !results.is_shareholder
      if (results.significant_controller) results.significant_controller.is_deleted = !results.is_significant_controller

      for (var i = temp.length - 1; i >= 0; i--) {
        var existing_member = temp[i]
        var new_member = results
        // console.log(existing_member.is_company, new_member.is_company, (existing_member.company || existing_member.person).id, (new_member.company || new_member.person).id)
        // if (existing_member.is_company == new_member.is_company && (existing_member.company || existing_member.person).id == (new_member.company || new_member.person).id) {
        if ((existing_member.is_company === new_member.is_company && new_member.is_company === true && existing_member.company && new_member.company && existing_member.company.id === new_member.company.id) ||
						(existing_member.is_company === new_member.is_company && new_member.is_company === false && existing_member.person && new_member.person && existing_member.person.id === new_member.person.id)) {
          // console.log('hihi', this, this.members[i], new_member);
          temp[i] = new_member
          this.$notify({
            type: 'success',
            title: this.$t('company.details.msg.update_member.title'),
            text: this.$t('company.details.msg.update_member.success')
          })
          this.members = temp
          return this.members
        }
      }

      this.members.push(results)

      this.$notify({
        type: 'success',
        title: this.$t('company.details.msg.add_member.title'),
        text: this.$t('company.details.msg.add_member.success')
      })
    },
    addMember () {
      this.dialog_member_detail_record = null
      this.dialog_member_detail = true
    },
    editMember (row) {
      this.dialog_member_detail_record = row
      this.dialog_member_detail = true
    },
    removeMember (row) {
      this.members.map((member) => {
        if (member.is_company === row.is_company && ((member.company && row.company && member.company.id === row.company.id) || (member.person && row.person && member.person.id === row.person.id))) {
          if (row.role.indexOf('Director') === 0) {
            member.is_director = false
            member.director.is_deleted = true
          } else if (row.role === 'Founder') {
            member.is_founder = false
            member.founder.is_deleted = true
          } else if (row.role === 'Shareholder') {
            member.is_shareholder = false
            member.shareholder.is_deleted = true
          } else if (row.role === 'Secretary') {
            member.is_secretary = false
            member.secretary.is_deleted = true
          } else if (row.role === 'Auth Rep') {
            member.is_auth_rep = false
            member.auth_rep.is_deleted = true
          } else if (row.role === 'Significant Controller') {
            member.is_significant_controller = false
            member.significant_controller.is_deleted = true
          }
        }
      })
    },
    onComplete () {
      if (this.requestLoading) { return }

      this.$v.form.$touch()

      if (this.$v.form.$error ||
          (this.$slots.roles && !this.$slots.roles[0].context.checkAllDateFormat()) ||
          !this.checkAllDateFormat()
      ) {
        return this.$notify({
          type: 'error',
          title: this.$t('company.details.msg.valid.title'),
          text: this.$t('company.details.msg.valid.error')
        })
      } else {
        this.$notify({
          type: 'success',
          title: this.$t('company.details.msg.valid.title'),
          text: this.$t('company.details.msg.valid.success')
        })
      }

      var company = JSON.parse(JSON.stringify(this.form))
      var members = JSON.parse(JSON.stringify(this.members))

      company.directors = []
      company.founders = []
      company.shareholders = []
      company.secretaries = []
      company.auth_reps = []
      company.significant_controllers = []

      for (var i = members.length - 1; i >= 0; i--) {
        var member = members[i]

        if (member.is_founder || (member.founder && member.founder.is_deleted)) {
          // member.founder.capacity_type = member.is_company ? 1 : 2;
          // member.founder.capacity_id = member.is_company ? member.company.id : member.person.id;
          member.founder.company = member.is_company ? member.company.id : null
          member.founder.person = !member.is_company ? member.person.id : null
          company.founders.push(member.founder)
        }
        if (member.is_shareholder || (member.shareholder && member.shareholder.is_deleted)) {
          member.shareholder.company = member.is_company ? member.company.id : null
          member.shareholder.person = !member.is_company ? member.person.id : null
          company.shareholders.push(member.shareholder)
        }
        if (member.is_director || (member.director && member.director.is_deleted)) {
          // member.director.capacity_type = member.is_company ? 1 : 2;
          // member.director.capacity_id = member.is_company ? member.company.id : member.person.id;
          member.director.company = member.is_company ? member.company.id : null
          member.director.person = !member.is_company ? member.person.id : null
          company.directors.push(member.director)
        }
        if (member.is_secretary || (member.secretary && member.secretary.is_deleted)) {
          // member.secretary.capacity_type = member.is_company ? 1 : 2;
          // member.secretary.capacity_id = member.is_company ? member.company.id : member.person.id;
          member.secretary.company = member.is_company ? member.company.id : null
          member.secretary.person = !member.is_company ? member.person.id : null
          company.secretaries.push(member.secretary)
        }
        if (member.is_auth_rep || (member.auth_rep && member.auth_rep.is_deleted)) {
          // member.auth_rep.capacity_type = member.is_company ? 1 : 2;
          // member.auth_rep.capacity_id = member.is_company ? member.company.id : member.person.id;
          member.auth_rep.company = member.is_company ? member.company.id : null
          member.auth_rep.person = !member.is_company ? member.person.id : null
          company.auth_reps.push(member.auth_rep)
        }
        if (member.is_significant_controller || (member.significant_controller && member.significant_controller.is_deleted)) {
          // member.significant_controller.capacity_type = member.is_company ? 1 : 2;
          // member.significant_controller.capacity_id = member.is_company ? member.company.id : member.person.id;
          member.significant_controller.company = member.is_company ? member.company.id : null
          member.significant_controller.person = !member.is_company ? member.person.id : null
          company.significant_controllers.push(member.significant_controller)
        }
      }
      if (company) {
        this.requestLoading = true
        this.$store.dispatch('company/save', company).then((results) => {
          // this.requestLoading = false;
          if (results.status === 200) {
            // Object.assign(this.form, results.data)
            // this.prepareForm()
            this.$notify({
              type: 'success',
              title: this.$t('company.details.msg.save.title'),
              text: this.$t('company.details.msg.save.success')
            })

            this.$emit('onSave', results.data)

            // refresh content if records updated
            if (this.companyId && this.companyId === results.id) {
              this.loadCompanyDetails()
            }
          } else {
            this.$notify({
              type: 'error',
              title: this.$t('company.details.msg.save.title'),
              text: this.$t('company.details.msg.save.error')
            })
          }
        }, (error) => {
          this.$notify({
            type: 'error',
            title: this.$t('company.details.msg.save.title'),
            text: (
              error.response && error.response.data && error.response.data.code === 'E_UNIQUE'
                ? this.$t('company.details.msg.save.error_unique')
                : this.$t('company.details.msg.save.exception')
            )
          })
        })
          .finally(() => {
            this.requestLoading = false
          })
      }
    },
    onChange (unusedFromStep, toStep) {
      this.step = toStep
    },
    checkAllDateFormat () {
      let correctDate = true
      switch (this.tab !== '' ? this.tab : this.step) {
        case 0:
          correctDate = this.$refs.incorp_date.correctDate &&
            this.$refs.email_effect_date.correctDate
          break
        case 1:
          correctDate = this.$refs.incorp_date.correctDate &&
            this.$refs.email_effect_date.correctDate &&
            this.$refs.address_effect_date.filter(date => date.correctDate).length === this.$refs.address_effect_date.length
          break
        default:
          correctDate = this.$refs.incorp_date.correctDate &&
            this.$refs.email_effect_date.correctDate &&
            this.$refs.address_effect_date.filter(date => date.correctDate).length === this.$refs.address_effect_date.length &&
            (this.$refs.acc_start_date || { correctDate: true }).correctDate &&
            (this.$refs.acc_end_date || { correctDate: true }).correctDate &&
            (this.$refs.company_estab_date || { correctDate: true }).correctDate
          break
      }
      return correctDate
    },
    validateAsync () {
      // console.log('here', this.checkAllDateFormat())
      return new Promise((resolve) => {
        this.$v.email_form.$reset()
        this.$v.name_form.$reset()
        this.$v.address_form.$reset()
        this.$v.form.$touch()
        resolve(!this.$v.form.$anyError && this.checkAllDateFormat())
      })
    },
    loadCompanyDetails () {
      // Default
      this.$v.$reset()
      Object.assign(this.form, this.initialCompany())
      this.prepareForm()
      // Load DB
      if (this.companyId) {
        this.requestLoading = true
        this.$store.dispatch('company/findOne', this.companyId).then((results) => {
          this.requestLoading = false
          // console.log(results)
          if (results.data) {
            Object.assign(this.form, results.data)
            this.$emit('onLoad', this.form)

            this.prepareForm()
            if (this.$refs.alerts_table) {
              this.$refs.alerts_table.getData()
            }
            this.$notify({
              type: 'success',
              title: this.$t('company.details.msg.load.title'),
              text: this.$t('company.details.msg.load.success')
            })
          }
        }).catch((unusedError) => {
          this.requestLoading = false
          this.$notify({
            type: 'error',
            title: this.$t('company.details.msg.load.title'),
            text: this.$t('company.details.msg.load.error')
          })
          this.$router.replace({ name: 'Companies' })
        })

        this.like.name_form.chinese.options.splice(0)
        this.like.name_form.chinese.is_show = false
        this.like.name_form.english.options.splice(0)
        this.like.name_form.english.is_show = false
        this.like.email_form.options.splice(0)
        this.like.email_form.is_show = false

        this.$store.dispatch('risk/get_risk_info', this.form.incorp_place).then((results) => {
          if (results && results.status === 200) {
            // $store.dispatch('risk/get_risk_info', 'Hong Kong')
            this.risk_level = results.data.risk_level
            this.risk_detail = results.data.risk_score
          }
        })
      }
    },
    prepareForm () {
      // Reset All Form
      this.name_form.english = this.getNameForm(1, 3)
      this.name_form.chinese = this.getNameForm(2, 3)
      this.address_form.current = this.getAddressForm(1)
      this.address_form.correspondence = this.getAddressForm(2)
      this.address_form.principal_hk = this.getAddressForm(3)
      this.address_form.principal_registered_country = this.getAddressForm(4)
      this.email_form = this.getEmailForm()

      // Reset All Validation
      this.$v.name_form.$reset()
      this.$v.address_form.$reset()
      this.$v.email_form.$reset()

      // Setup Temp UUID
      // Prepare Form Data
      this.form.names.map((name) => {
        name.uuid = uuidv4()
        return name
      })

      this.form.emails.map((email) => {
        email.uuid = uuidv4()
        return email
      })

      this.form.addresses.map((address) => {
        address.uuid = uuidv4()
        return address
      })

      this.accounting_needed = !this.form.acc_no_reason

      this.members = []
        .concat(this.form.founders, this.form.shareholders, this.form.directors, this.form.secretaries, this.form.auth_reps, this.form.significant_controllers)
        .reduce((members, member) => {
          // var is_company = !!member.company
          var _member = (member.company || member.person)

          if (!_member) {
            return members
          }

          for (var i = members.length - 1; i >= 0; i--) {
            if ((members[i].company || members[i].person) && _member && (members[i].company || members[i].person).id === _member.id) {
              return members
            }
          }

          const [founder, shareholder, secretary, auth_rep, director, significant_controller] =
            ['founders', 'shareholders', 'secretaries', 'auth_reps', 'directors', 'significant_controllers']
              .map(role => {
                return this.form[role].find(roleInfo => roleInfo.capacity_id === _member.id && roleInfo.capacity_type === member.capacity_type)
              })

          members.push({
            is_company: !!member.company,
            is_founder: !!founder,
            is_shareholder: !!shareholder,
            is_secretary: !!secretary,
            is_auth_rep: !!auth_rep,
            is_director: !!director,
            is_significant_controller: !!significant_controller,
            company: member.company,
            person: member.person,
            founder,
            shareholder,
            secretary,
            auth_rep,
            director,
            significant_controller
          })

          return members
        }, [])
    },
    switchCompanyDetails (company_id) {
      // console.log('switchCompanyDetails', company_id)
      this.like.name_form.english.options = []
      this.like.name_form.chinese.options = []
      this.like.email_form.options = []
      this.$emit('switchToID', company_id)
    },
    toggleSimilarDropdown (field, is_show) {
      clearTimeout(field.timeout)
      field.timeout = setTimeout(() => {
        field.is_show = is_show
      }, 100)
    },
    popup_alert_detail (row) {
      this.dialog_alert_detail_record = row
      this.dialog_alert_detail = true
    },
    add_bank_info () {
      var temp = helper.getBankInfoForm()
      temp.uuid = uuidv4()
      this.form.bank_infos.push(temp)
      this.active_penal.push(this.form.bank_infos.length - 1)
    },
    remove_bank_info (bank_info) {
      if (bank_info.uuid) {
        var index = this.form.bank_infos.findIndex((x) => x.uuid === bank_info.uuid && bank_info.id === 0)
        if (index !== -1) {
          this.form.bank_infos.splice(index, 1)
          return
        }
      }

      bank_info.is_deleted = true
    },
    name_similar_check (form_name, name) {
      clearTimeout(this.name_similar_check_timeout)
      this.name_similar_check_timeout = null

      if (!name.other) {
        this.like.name_form[form_name].options = []
        return this.like.name_form[form_name].options
      }

      this.name_similar_check_timeout = setTimeout(() => {
        this.$store.dispatch('company/similarName', { company_id: this.form.id, lang: name.lang, company_name: name.other })
          .then((results) => {
            if (results.data) { this.like.name_form[form_name].options = results.data }
            this.name_similar_check_timeout = null
          })
      }, 500)
    }
  },
  mounted () {
    this.$store.dispatch('risk/lists')
    // this.VB2form(this.value);
    // console.log('company', this, JSON.stringify(this.value));
    this.loadCompanyDetails()
  }
}
</script>
<style type="text/css">

.btn .badge {font-size: 1rem !important;}

.tabs-component {
  margin: 4em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-left: 0;
}

@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 1.4rem;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
  z-index: 1;
}

.tabs-component-tab:not(:last-child) {
  border-bottom: dotted 1px #ddd;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
  z-index: 3;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 700px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: .5em;
    transform: translateY(2px);
    transition: transform .3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    /*z-index: 2;*/
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: .75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
  z-index: 2;
  position: relative;
}

@media (min-width: 700px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    padding: 4em 2em;
  }
}

.tooltip-error {transform: translate3d(825px, 548px, -1px);}
.tooltip-error .tooltip-arrow {border-color: #dd4b39 !important;}
.tooltip-error .tooltip-inner {background: #dd4b39 !important;}

.form-group.has-error .vs__dropdown-toggle {border-color: #dd4b39 !important;}

.like_dropdown {position: absolute;z-index: 2;background-color: #fff;border: 1px solid #eee;}
.like_remarks {color: #a5a5a5;padding: 5px;}
.like_options {max-height: 300px;overflow: auto;max-width: 100%;}
.like_options > .like_options_item {border: 1px solid #eee; padding: 5px;cursor: pointer;}
.like_options > .like_options_item:hover {color: #fff;background-color: #5aa2e0;}

</style>
