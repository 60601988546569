<template>
  <span v-html="$sanitize(value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${nl2br}$2`), { disallowedTagsMode: 'escape' })" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    nl2br: {
      type: String,
      default: '</br>'
    }
  }
}
</script>

<style>

</style>
