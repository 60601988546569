var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.listing_existing_loading,"is-full-page":false},on:{"update:active":function($event){_vm.listing_existing_loading=$event}}}),_c('v-server-table',{ref:"tableExisting",staticClass:"pt-0",attrs:{"name":'attachment/attachmentTable',"columns":_vm.listing_existing_columns,"options":_vm.listing_existing_options(( _obj = {}, _obj[_vm.parentType == 'company' ? 'company_id' : (_vm.parentType == 'person' ? 'person_id' : (_vm.parentType == 'alert' ? 'alert_id' : 'all'))] = _vm.parentId, _obj ))},scopedSlots:_vm._u([{key:"file_name",fn:function(props){return _c('div',{},[(_vm.mode == 'selecting')?_c('v-checkbox',{key:props.row.id,attrs:{"multiple":"","disabled":!!_vm.unselectable(props.row),"messages":_vm.unselectable(props.row) || '',"value":props.row.id,"label":props.row.file_name},on:{"change":_vm.onSelect},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(props.row.file_name)+" ")]},proxy:true}],null,true),model:{value:(_vm.selected_items_local),callback:function ($$v) {_vm.selected_items_local=$$v},expression:"selected_items_local"}}):_c('span',[_vm._v(_vm._s(props.row.file_name))])],1)}},{key:"create_date",fn:function(props){return _c('div',{},[_c('DateFormat',{attrs:{"date":props.row.create_date,"showtime":true}})],1)}},{key:"description",fn:function(props){return _c('div',{},[_c('TextareaSafeDisplay',{attrs:{"value":props.row.description}})],1)}},{key:"action",fn:function(props){return _c('div',{},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){return _vm.view_document(props.row)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('attachment.listing.buttons.preview')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){return _vm.download_document(props.row)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('attachment.listing.buttons.download')))])])],1)}}])},[_c('div',{attrs:{"slot":"afterFilterWrapper"},slot:"afterFilterWrapper"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","outlined":"","loading":_vm.file_selecting || _vm.uploading,"data-cy":"upload_att_btn"},on:{"click":_vm.onUploadClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('attachment.listing.buttons.add'))+" ")],1),(_vm.file_selecting)?_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"*/*"},on:{"change":_vm.onFileChanged}}):_vm._e()],1)]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog_upload),callback:function ($$v) {_vm.dialog_upload=$$v},expression:"dialog_upload"}},[_c('v-card',{attrs:{"loading":_vm.uploading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('attachment.listing.buttons.add'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","loading":_vm.uploading},on:{"click":_vm.onUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" "+_vm._s(_vm.$t('attachment.listing.buttons.upload'))+" ")],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t('attachment.listing.table.file_name.label'))+" ")]),_c('v-col',{staticClass:"primary--text",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.file_selected ? _vm.file_selected.name : '')+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('attachment.listing.table.description.label'),"auto-grow":"","rows":"1","row-height":"15"},model:{value:(_vm.file_description),callback:function ($$v) {_vm.file_description=$$v},expression:"file_description"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }