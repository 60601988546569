<template>
  <!-- <v-select v-bind="$props" taggable v-model="selection" :options="currencies" label="code" :reduce="currency => currency.code"></v-select> -->
  <!-- <v-select v-bind="$props" v-model="selection" class="form-control">
		<option v-for="(currency, key) in currencies" :key="key">{{ currency.code }}</option>
	</v-select> -->
  <v-autocomplete
    v-bind="$props"
    v-model="selection"
    :search-input.sync="search"
    ref="input"
    :items="items"
    clearable
    :label="label"
    :hint="hint"
    persistent-hint
    @keypress.enter.prevent="onEnter"
    prepend-icon="mdi-currency-usd"
    :data-cy="dataCy"
  >
    <template
      v-slot:selection="currency"
    >
      <div
        data-cy="selected_currency"
      >
        {{ currency.item.text }}
      </div>
    </template>
    <template
      v-slot:item="currencies"
    >
      <div
        :data-cy="`${currencies.item.value}`"
      >
        {{ currencies.item.text }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script type="text/javascript">
// import currency from '@/store/modules/currency'
import { mapGetters } from 'vuex'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    dataCy: {
      type: String,
      default: ''
    }
  },
  watch: {
    value (value) {
      this.selection = value
    },
    selection (selection) {
      console.log(selection)
      this.$emit('input', selection)
    }
  },
  data () {
    return {
      selection: this.value,
      search: this.value
    }
  },
  computed: {
    ...mapGetters('setting', {
      currency: 'currency_table'
    }),
    // currencies () {
    // 	// return currency.map((currency) => {
    // 	// 	return currency.code;
    // 	// })
    // 	return Object.values(this.currency)
    // }
    items () {
      return (this.search ? [this.search] : []).filter(Boolean).concat(Object.values(this.currency).map((x) => {
        return {
          text: x.code,
          value: x.code
        }
      }))
    }
  },
  methods: {
    onEnter () {
      this.$refs.input.blur()
    }
  }
}
</script>
