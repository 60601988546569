<template>
  <v-autocomplete
    v-model="selection"
    :search-input.sync="search"
    ref="input"
    :items="items"
    clearable
    :label="label"
    :hint="hint"
    :dark="dark"
    :error="error"
    :dense="dense"
    :multiple="multiple"
    :error-messages="errorMessages"
    persistent-hint
    @keypress.enter.prevent="onEnter"
    prepend-inner-icon="mdi-map-marker"
    :autocomplete="`autocomplete_off_${_uid}` /* disable browser autocomplete by a unqiue key string, as most of browsers already ignore attribute autocomplete=off */"
    :data-cy="dataCy"
    :filter="(item, queryText, itemText) => {
      return (item.keywords || []).findIndex(keyword => keyword.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) > -1
    }"
  >
    <template
      v-slot:selection="{ item, index }"
    >
      <div
        v-if="!multiple || (multiple && (selection.length > 0))"
        v-show="index === 0"
        data-cy="selected_country"
      >
        <flag
          v-if="showFlag"
          :iso="item.iso2"
          :squared="false"
          class="mr-2"
        />
        {{
          multiple ? (
            index === 0 ? (
              !isSelectedAll ? item.text : $t('common.countrySelector.selection.all', { labelPlural })
            )
              : item.text
          )
            : item.text
        }}
      </div>
      <span
        v-if="multiple && (selection.length > 1) && !isSelectedAll"
        v-show="index === 1"
        class="grey--text text-caption"
      >
        &nbsp;({{ $t('common.countrySelector.selection.nItemSelected', { n: selection.length - 1 }) }})
      </span>
    </template>
    <template
      v-if="multiple"
      v-slot:prepend-item
    >
      <v-list-item
        link
        ripple
        @mousedown.prevent
        @click.prevent="onClickSelectAll"
        :data-cy="`${dataCy}_select_all`"
      >
        <v-list-item-action
          class="ml-0 my-0"
        >
          <v-icon
            :color="isSelectedAll ? 'primary' : ''"
          >
            {{ isSelectedAll ? 'mdi-checkbox-marked' : ( isSelectedSome ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline' ) }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content
          class="pa-0"
        >
          <div>
            {{ $t('common.countrySelector.selectAll') }}
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:item="data">
      <!-- for "Multiple Mode" -->
      <v-list-item-action
        v-if="multiple"
        class="ml-0 my-0"
      >
        <v-icon>
          {{ data.attrs.inputValue ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon>
      </v-list-item-action>
      <!-- for "New Option" -->
      <v-list-item-icon v-if="data.item.isNewOption">
        <v-icon>
          mdi-plus-box
        </v-icon>
      </v-list-item-icon>
      <!-- Item Content -->
      <v-list-item-content
        class="pa-0"
        data-cy="countryList"
      >
        <div>
          <flag
            v-if="showFlag"
            :iso="data.item.iso2"
            :squared="false"
            class="mr-2"
          />
          <span :data-cy="`${data.item.value}`.replace(/\s/g, '')">{{ typeof data.item !== 'object' ? data.item : data.item.text }}</span>
          <span
            class="caption"
            v-if="data.item.isNewOption"
          >( {{ $t('common.countrySelector.newOptionHint') }} )</span>
        </div>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    labelPlural: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Array],
      default: null
    },
    dark: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String,
      default: ''
    },
    showFlag: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selection (_new) {
      if (this.multiple) {
        this.search = ''
      }
      this.$emit('input', _new)
    },
    value (_new) {
      this.selection = _new
    }
  },
  data () {
    return {
      selection: this.value,
      search: ''// this.value; this.getDisplayName(this.countries.find(country => country.country === this.value))
    }
  },
  computed: {
    ...mapGetters('setting', {
      countries: 'country_table'
    }),
    items () {
      const lists = this.countries.map((country) => {
        return {
          text: this.getDisplayName(country),
          value: country.country,
          iso2: country.iso2,
          keywords: [
            country.country, // key
            country.displayName, // eng
            country.displayNameZhHant, // hant
            country.displayNameZhHans, // hans
            country.iso2, // iso2
            country.iso3 // iso3
          ].filter(Boolean)
        }
      })
      // disable new option when multiple mode is enabled
      if (this.multiple) {
        return lists
      }
      const newOption = ((value) => {
        if (value instanceof Array) return false
        return value && !((lists || []).findIndex(country => country.keywords.findIndex(keyword => keyword.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) > -1) > -1) ? value : ''
      })(this.search || this.value)
      return (newOption ? [{ text: newOption, value: newOption, keywords: [newOption], isNewOption: true }, { divider: true }, ...lists] : [...lists])
    },
    isSelectedAll () {
      return this.multiple && this.selection instanceof Array && this.selection.length === this.items.length
    },
    isSelectedSome () {
      return this.multiple && this.selection instanceof Array && this.selection.length > 0 && !this.isSelectedAll
    }
  },
  methods: {
    onEnter () {
      this.$refs.input.blur()
    },
    getDisplayName (country) {
      return ((this.$i18n.locale.toLowerCase() === 'zh-hant' ? country.displayNameZhHant : country.displayName) || country.country)
    },
    onClickSelectAll () {
      if (!this.multiple) {
        return
      }

      this.$nextTick(() => {
        if (this.isSelectedAll) {
          this.selection = []
        } else {
          this.selection = this.items.slice().map(item => item.value)
        }
      })
    }
  }
}
</script>

<style>

</style>
