<template>
  <div
    class="VueTables__list-filter pt-2"
    :id="`VueTables__${props.column}-filter`"
  >
    <v-select
      ref=""
      dense
      class="font-weight-regular"
      :class="props.theme.select"
      @change="onChange"
      :name="props.name"
      :value="props.value"
      :items="[
        { value: '', text: props.defaultOption },
        ...props.items.map(option => ({
          value: option.id,
          text: option.text
        }))
      ]"
      style="max-width: 150px;"
    />
    <!-- <select :class="props.theme.select"
                @change="onChange"
                :name="props.name"
                :value="props.value">
            <option value="">
                {{props.defaultOption}}
            </option>
            <option v-for="(option, key) in props.items" :value="option.id" :key="key">
                {{option.text}}
            </option>
        </select> -->
  </div>
</template>

<script>
export default {
  name: 'VtListFilter',
  props: {
    props: {
      type: Object,
      default: null
    }
  },
  methods: {
    // change (val) {
    //     this.props.search(false)(val)
    //     // console.log(this.props.search(false)(...args))
    //     // // props.search(false)
    //     // console.log(...args)
    // }
    onChange (value) {
      // props.search(false)
      // var evt = document.createEvent("HTMLEvents");
      // evt.initEvent("change", false, true);
      // event.target.name = this.props.name;
      const event = {
        target: {
          name: this.props.name,
          value
        }
      }
      event[Symbol.toStringTag] = 'NotAObject'
      console.log('onChange', event)
      this.props.search(false)(event)
    }
    // blur () {
    //     document.children[0].focus()
    // }
  }
  // mounted () {
  //     console.log('list_filter', this)
  // }
}
</script>
