import { NameType, RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))
/* global moment */

function ensure_company_data_type (data) {
  data.acc_start_date = parseInt(data.acc_start_date) || 0 // this.form.acc_start_date && this.form.acc_start_date != 0 ? moment(parseInt(this.form.acc_start_date)).format('YYYY-MM-DD') : ''
  data.acc_end_date = parseInt(data.acc_end_date) || 0 // this.form.acc_end_date && this.form.acc_end_date != 0 ? moment(parseInt(this.form.acc_end_date)).format('YYYY-MM-DD') : ''
  data.is_br_3y = data.is_br_3y === 'true'
  return data
}

// initial state
const state = {
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: [
      'ref_id',
      'coi_number',
      'names',
      'status',
      // 'directors',
      'batch_scan_date',
      // 'cr_ref',
      // 'acc_end_date',
      'create_date',
      'updated_date',
      'action'
    ],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      requestFunction (request) {
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            if (this.$store) {
              this.$store.commit('company/companyTable', { requestLoading: true })
            }
            try {
              Promise.all([
                request.query.names ? (this.lastSearchNames === request.query.names.trim() ? this.lastSearchNamesResults : client.name.findWhere({
                  where: {
                    other: { $Contains: request.query.names.trim() },
                    company: { $NotEqual: null }
                  },
                  limit: 500,
                  select: 'company'
                })) : null
              ]).then(async (results) => {
                this.lastSearchNames = (request.query.names || '').trim()
                this.lastSearchNamesResults = results[0]
                var ids = results.filter(Boolean).map((result) => {
                  return result.data ? result.data.map((row) => {
                    return row.company.id
                  }) : []
                })
                ids = ids.length > 0 ? ids.reduce((x, y) => {
                  return x.filter((x_row) => {
                    return y.includes(x_row)
                  })
                }) : null

                var criteria = {
                  where: (((query) => {
                    var _where = { and: [] }

                    if (ids) _where.id = { in: ids }
                    if (query.ref_id != null) _where.ref_id = { $Contains: query.ref_id }
                    if (query.status != null) _where.status = query.status
                    if (query.batch_scan_date) { // _where.batch_scan_date = query.batch_scan_date
                      _where.and = [
                        { batch_scan_date: { '>': new Date(query.batch_scan_date.start).getTime() } },
                        { batch_scan_date: { '<': new Date(query.batch_scan_date.end).getTime() } }
                      ]
                    }

                    if (query.coi_number) _where.coi_number = { contains: query.coi_number }
                    if (query.create_date) { // _where.create_date = query.create_date
                      _where.and = [
                        ..._where.and,
                        { create_date: { '>': new Date(query.create_date.start).getTime() } },
                        { create_date: { '<': new Date(query.create_date.end).getTime() } }
                      ]
                    }
                    if (query.updated_date) {
                      _where.and = [
                        ..._where.and,
                        { updated_date: { '>': new Date(query.updated_date.start).getTime() } },
                        { updated_date: { '<': new Date(query.updated_date.end).getTime() } }
                      ]
                    }

                    return _where
                  })(request.query)),
                  sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                  limit: request.limit,
                  skip: request.limit * (request.page - 1),
                  populate: 'names'
                }

                Promise.all([
                  client.corporate.count(criteria),
                  client.corporate.findWhere(criteria)
                ])
                  .then(([{ data: { count } }, { data }]) => {
                    resolve({ data, count })
                  })
                  .catch((error) => {
                    reject(error)
                  })
              })
            } catch (error) {
              reject(error)
            }
          }, request.query.names || request.query.coi_number ? 800 : 0)
        }).catch((e) => {
          this.dispatch('error', e)
        })
      },
      /* responseAdapter: function(resp) {
        // console.log('responseAdapter', resp);
        return { data: resp.data[0], count: resp.data[1] };
      }, */
      columnsClasses: {
        coi_number: 'col-xs-2',
        names: 'col-xs-4',
        status: 'col-xs-2',
        // directors: 'col-xs-2',
        batch_scan_date: 'col-xs-1',
        // br_number:'col-xs-2',
        // acc_end_date: 'col-xs-1',
        create_date: 'col-xs-1',
        updated_date: 'col-xs-1',
        action: 'col-xs-1'
      },
      sortable: [
        'ref_id',
        // 'names',
        'status',
        // 'directors',
        'batch_scan_date',
        // 'cr_ref',
        // 'acc_end_date',
        'create_date',
        'updated_date'
      ],
      // headings: {
      //   coi_number: 'COI Number',
      //   names: 'Company Name',
      //   status: 'Status',
      //   // directors: 'Director',
      //   batch_scan_date: 'Batch Screened',
      //   // br_number: 'Co. Registration No.',
      //   // acc_end_date: 'Expiry Date',
      //   create_date: 'Date Created',
      //   updated_date: 'Date Updated',
      // },
      filterable: [
        'ref_id',
        'coi_number',
        'names',
        'status',
        // 'directors',
        'batch_scan_date',
        // 'cr_ref',
        // 'acc_end_date',
        'create_date',
        'updated_date'
      ],
      filterByColumn: true,
      dateColumns: ['create_date', 'updated_date', 'batch_scan_date', 'acc_end_date'],
      dateFormat: 'DD/MMM/YYYY'
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     'Today': [moment(), moment()],
      //     'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //     'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //     'This Month': [moment().startOf('month'), moment().endOf('month')],
      //     'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // },
      // listColumns: {
      //   status: [
      //     {
      //       id: 0,
      //       text: 'Inactive'
      //     },
      //     {
      //       id: 1,
      //       text: 'Active',
      //       // hide:true
      //     },
      //     // {
      //     //   id: 2,
      //     //   text: 'Rejected'
      //     // },
      //   ]
      // }
      // // see the options API
    }

  }
}

// getters
const getters = {
  options (state, unusedGetters, unusedRootState, rootGetters) {
    // rootGetters.i18n.t()
    // console.log('this', this)
    // console.log('rootGetters', rootGetters)
    const { i18n } = rootGetters
    const { initFilters, initialPage, perPage, orderBy, requestFunction, columnsClasses, sortable, filterable, filterByColumn, dateColumns } = state.listing.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction,
      columnsClasses,
      sortable,
      headings: {
        ref_id: i18n.t('company.listing.table.ref_id.label'),
        coi_number: i18n.t('company.details.form.coi_number.label'),
        names: i18n.t('company.listing.table.names.label'), // 'Company Name',
        status: i18n.t('company.details.form.status.label'), // 'Status',
        batch_scan_date: i18n.t('company.listing.table.batch_scan_date.label'), // 'Batch Screened',
        create_date: i18n.t('company.listing.table.create_date.label'), // 'Date Created',
        updated_date: i18n.t('company.listing.table.updated_date.label'), // 'Date Updated',
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      // dateFormat,
      dateColumns,
      // datepickerOptions,
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     [i18n.t('listing.table.datepicker.today')]: [moment(), moment()],
      //     [i18n.t('listing.table.datepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [moment().subtract(6, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [moment().subtract(29, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
      //     [i18n.t('listing.table.datepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // },
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      },
      listColumns: {
        status: [
          {
            id: 0,
            text: i18n.t('company.details.form.status.options.inactive')// 'Inactive'
          },
          {
            id: 1,
            text: i18n.t('company.details.form.status.options.active') // 'Active',
            // hide:true
          }
        ]
      }
    }
  }
  // companies: state => {
  //   return state.records;
  // }
}

// actions
const actions = {
  similarName (_, { company_id, lang, company_name }) {
    return client.name.findWhere({
      where: {
        $AND: [
          { company: { $NotEqual: company_id } },
          { company: { $GreaterThan: 0 } }
        ],
        lang,
        name_type: NameType.Company,
        other: { $Contains: company_name }
      },
      limit: 10
    })
  },
  similarEmail (_, { company_id, email_address }) {
    return client.email.findWhere({
      where: {
        $AND: [
          { company: { $NotEqual: company_id } },
          { company: { $GreaterThan: 0 } }
        ],
        email: { $Contains: email_address }
      },
      limit: 10
    })
  },
  findOne (context, payload) {
    return new Promise((resolve, reject) => {
      // API.get(`/company_v2/${payload}`)
      client.corporate.findOne(payload)
        .then(({ status, data }) => {
          if (status === 200 && data) {
            const { companies, people } = [].concat(data.auth_reps, data.directors, data.founders, data.shareholders, data.secretaries, data.significant_controllers).reduce((output, each) => {
              output.companies.push(each.company)
              output.people.push(each.person)
              return output
            }, { companies: [], people: [] })

            Promise.all([
            // API.get(`/naturalperson_v2`, {
            //   params: {
            //     where: {
            //       id: {
            //         in: people.filter(Boolean)
            //       }
            //     },
            //     populate: 'names',
            //   }
            // }),
              people.filter(Boolean).length ? client.individual.findWhere({
                where: {
                  id: { $In: people.filter(Boolean) }
                },
                populate: 'names'
              }) : { status: 200, data: [] },
              // API.get(`/naturalperson_v2`, {
              //   params: {
              //     where: {
              //       id: {
              //         in: companies.filter(Boolean)
              //       }
              //     },
              //     populate: 'names',
              //   }
              // })
              companies.filter(Boolean).length ? client.corporate.findWhere({
                where: {
                  id: { $In: companies.filter(Boolean) }
                },
                populate: 'names'
              }) : { status: 200, data: [] }
            ]).then(async (results) => {
              var anyErrorResults = results.filter(x => x.status !== 200)
              if (anyErrorResults.length > 0) return reject(anyErrorResults)

              if (data.founders) {
                // fetch nested data: shares
                data.founders = (await client.founder.findWhere({ where: { parent: data.id }, populate: 'shares' })).data
              }
              if (data.shareholders) {
                data.shareholders = (await client.shareholder.findWhere({ where: { parent: data.id }, populate: 'shares' })).data
              }

              results[1].data.map(_company => {
                ['auth_reps', 'directors', 'founders', 'shareholders', 'secretaries', 'significant_controllers']
                  .forEach(key => {
                    data[key] = data[key].map(x => {
                      x.company = x.company === _company.id ? _company : x.company
                      x.capacity_id = (x.company || x.person).id
                      x.capacity_type = x.company ? 1 : 2
                      return x
                    })
                  })
              })

              results[0].data.map(_person => {
                ['auth_reps', 'directors', 'founders', 'shareholders', 'secretaries', 'significant_controllers']
                  .forEach(key => {
                    data[key] = data[key].map(x => {
                      x.person = x.person === _person.id ? _person : x.person
                      x.capacity_id = (x.company || x.person).id
                      x.capacity_type = x.company ? 1 : 2
                      return x
                    })
                  })
              })

              // if (data.founders) {
              //   const shares = (await client.share.findWhere(
              //     {
              //       where: {
              //         founder: {
              //           $In: data.founders.map(x => x.id)
              //         }
              //       },
              //       populate: ''
              //     }
              //   )).data
              //   console.log(data.founders, shares)
              //   data.founders = data.founders.map(x => {
              //     x.shares = shares.filter(y => y.founder === x.id)
              //     return x
              //   })
              // }

              // data.acc_start_date = parseInt(data.acc_start_date) || 0; //this.form.acc_start_date && this.form.acc_start_date != 0 ? moment(parseInt(this.form.acc_start_date)).format('YYYY-MM-DD') : ''
              // data.acc_end_date = parseInt(data.acc_end_date) || 0; //this.form.acc_end_date && this.form.acc_end_date != 0 ? moment(parseInt(this.form.acc_end_date)).format('YYYY-MM-DD') : ''
              // data.is_br_3y = data.is_br_3y == 'true'
              data = ensure_company_data_type(data)

              resolve({
                status,
                data
              })
            })
          } else { reject() }
        })
        .catch(() => {
          reject()
        })
    })
  },
  async save (context, payload) {
    var clearNull = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key] == null) {
          delete obj[key]
        }
      })
      console.log(obj)
      return obj
    }
    var dateStringToInt = (date_string) => {
      date_string = date_string || 0 // default to 0
      return typeof date_string === 'string' && date_string && !isNaN(new Date(date_string)) ? new Date(date_string).getTime() : date_string
    }
    var handleChild = async (record, path, date_columns, endpoint, nestedHandle) => {
      if (record.is_deleted) {
        // TODO: remove
        if (!record.id) {
          return false
        } else {
          // var results = await API.delete(`${path}/${record.id}`);
          var results = await endpoint.destroy(record.id)
          if (results.status !== 200) { throw new Error(`DELETE FAILED: ${path}`) }
          return false
        }
      } else {
        // record.effect_date = dateStringToInt(record.effect_date);
        Object.keys(record).forEach((key) => {
          if (date_columns.indexOf(key) !== -1) {
            record[key] = dateStringToInt(record[key])
          }
        })

        // Nested Handle: shares
        record = nestedHandle ? await nestedHandle(record) : record

        if (!record.id) {
          // TODO: CREATE
          delete record.id
          // var results = await API.post(path, clearNull(record));
          results = await endpoint.create(clearNull(record))
          if (results.status !== 200) { throw new Error(`CREATE FAILED: ${path}`) }
          return results.data.id
        } else {
          // TODO: UPDATE
          // var results = await API.patch(`${path}/${record.id}`, clearNull(record));
          results = await endpoint.update(record.id, clearNull(record))
          if (results.status !== 200) { throw new Error(`UPDATE FAILED: ${path}`) }
          return results.data.id
        }
      }
    }

    if (payload.ref_id) {
      const duplicate = await client.corporate.findWhere({
        where: {
          id: {
            $NotEqual: payload.id
          },
          ref_id: payload.ref_id
        }
      })
      if (duplicate.data.length > 0) {
        const error = new Error('')
        error.response = {
          data: {
            code: 'E_UNIQUE'
          }
        }
        return Promise.reject(error)
      }
    }

    return new Promise((resolve, reject) => {
      try {
        Promise.all([
          Promise.all((payload.addresses || []).map(x => handleChild(x, 'address', ['effect_date'], client.address))),
          Promise.all((payload.auth_reps || []).map(x => handleChild(x, 'authrep', ['start_date', 'end_date'], client.authorized_representative))),
          // Promise.all((payload.bank_infos || []).map(x => handleChild(x, 'bankinfo_v2', ['acc_open_date', 'acc_close_date']))),
          Promise.all((payload.directors || []).map(x => handleChild(x, 'director', ['start_date', 'end_date'], client.director))),
          Promise.all((payload.emails || []).map(x => handleChild(x, 'email', ['effect_date'], client.email))),
          Promise.all((payload.founders || []).map(x => handleChild(x, 'founder', ['effect_date'], client.founder,
            async (y) => {
              // y.shares = await Promise.all((y.shares || []).map(share => handleChild(share, 'shares', [], client.share)))
              y.shares = await Promise.all((y.shares || []).map(share => share.id))
              return x
            }))),
          Promise.all((payload.shareholders || []).map(x => handleChild(x, 'shareholder', ['effect_date'], client.shareholder,
            async (y) => {
              y.shares = await Promise.all((y.shares || []).map(share => handleChild(share, 'shares', [], client.share)))
              return x
            }))),
          Promise.all((payload.names || []).map(x => handleChild(x, 'name', ['effect_date'], client.name))),
          Promise.all((payload.secretaries || []).map(x => handleChild(x, 'comsec', ['start_date', 'end_date'], client.secretary))),
          // Promise.all((payload.shares_grouped || []).map(x => handleChild(x, 'shares', [], client.share))),
          Promise.all((payload.significant_controllers || []).map(x => handleChild(x, 'significantcontroller', ['commencement_date'], client.significant_controller))),
          Promise.all((payload.attachments || []).map(x => handleChild(x, 'attachment', ['create_date'], client.attachment)))
        ]).then((child_results) => {
          // console.log(all_results);
          payload.addresses = child_results[0].filter(Boolean)
          payload.auth_reps = child_results[1].filter(Boolean)
          // payload.bank_infos = child_results[2].filter(Boolean)
          payload.directors = child_results[2].filter(Boolean)
          payload.emails = child_results[3].filter(Boolean)
          payload.founders = child_results[4].filter(Boolean)
          payload.shareholders = child_results[5].filter(Boolean)
          payload.names = child_results[6].filter(Boolean)
          payload.secretaries = child_results[7].filter(Boolean)
          // payload.shares_grouped = child_results[8].filter(Boolean)
          payload.significant_controllers = child_results[8].filter(Boolean)
          payload.attachments = child_results[9].filter(Boolean)
          delete payload.attachments
          delete payload.bank_infos

          payload.incorp_date = dateStringToInt(payload.incorp_date)
          payload.batch_scan_date = dateStringToInt(payload.batch_scan_date)
          payload.estab_date = dateStringToInt(payload.estab_date)
          payload.acc_start_date = dateStringToInt(payload.acc_start_date)
          payload.acc_end_date = dateStringToInt(payload.acc_end_date)
          payload.acc_no_reason = payload.acc_no_reason || ''
          if (payload.acc_no_reason !== '') {
            payload.acc_start_date = 0
            payload.acc_end_date = 0
          }

          // when create new, status default to "Active"
          if (!payload.id) { payload.status = 1 }

          if (payload.id) {
            client.corporate.update(payload.id, payload)
            // API.patch(`/company_v2/${payload.id}`, payload)
              .then(({ status, data }) => {
                // data.acc_start_date = parseInt(data.acc_start_date) || 0; //this.form.acc_start_date && this.form.acc_start_date != 0 ? moment(parseInt(this.form.acc_start_date)).format('YYYY-MM-DD') : ''
                // data.acc_end_date = parseInt(data.acc_end_date) || 0; //this.form.acc_end_date && this.form.acc_end_date != 0 ? moment(parseInt(this.form.acc_end_date)).format('YYYY-MM-DD') : ''
                // data.is_br_3y = data.is_br_3y == 'true'
                data = ensure_company_data_type(data)
                return { status, data }
              })
              .then(resolve)
              .catch(reject)
          } else {
            delete payload.id
            client.corporate.create(payload)
            // API.post(`/company_v2`, payload)
              .then(({ status, data }) => {
                // data.acc_start_date = parseInt(data.acc_start_date) || 0; //this.form.acc_start_date && this.form.acc_start_date != 0 ? moment(parseInt(this.form.acc_start_date)).format('YYYY-MM-DD') : ''
                // data.acc_end_date = parseInt(data.acc_end_date) || 0; //this.form.acc_end_date && this.form.acc_end_date != 0 ? moment(parseInt(this.form.acc_end_date)).format('YYYY-MM-DD') : ''
                // data.is_br_3y = data.is_br_3y == 'true'
                data = ensure_company_data_type(data)
                return { status, data }
              })
              .then(resolve)
              .catch(reject)
          }
        })
          .catch((err) => {
            console.warn(err)
            reject(err)
          })
      } catch (error) {
        reject(error)
      }
    })
  },
  remove (context, payload) {
    return client.corporate.destroy(payload)
    // return API.delete(`/company_v2/${payload}`)
  }
}

// mutations
const mutations = {
  // SET_RECORDS (state, payload) {
  //   state.records = payload
  // },
  // SET_SORT_COLUMN (state, payload) {
  //   state.orderby = payload
  // },
  // SET_SORT_DIRECTION (state, payload) {
  //   state.order = payload
  // },
  // SET_PAGE_NUM (state, payload) {
  //   state.page_num = payload
  // },
  'companyTable/SET_DATA' (state, data) {
    console.log('companyTable/SET_DATA', data)
  },
  'companyTable/PAGINATION' (state, data) {
    console.log('companyTable/PAGINATION', data)
  },
  'companyTable/FILTER' (state, data) {
    console.log('companyTable/FILTER', data)
  },
  'companyTable/SORTED' (state, data) {
    console.log('companyTable/SORTED', data)
  },
  'companyTable/LOADING' (state, payload) {
    console.log('companyTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.updated_date) {
      state.listing.options.initFilters.updated_date.start = moment(state.listing.options.initFilters.updated_date.start)
      state.listing.options.initFilters.updated_date.end = moment(state.listing.options.initFilters.updated_date.end)
    }
    if (state.listing.options.initFilters.create_date) {
      state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start)
      state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end)
    }
    if (state.listing.options.initFilters.batch_scan_date) {
      state.listing.options.initFilters.batch_scan_date.start = moment(state.listing.options.initFilters.batch_scan_date.start)
      state.listing.options.initFilters.batch_scan_date.end = moment(state.listing.options.initFilters.batch_scan_date.end)
    }
    if (state.listing.options.initFilters.acc_end_date) {
      state.listing.options.initFilters.acc_end_date.start = moment(state.listing.options.initFilters.acc_end_date.start)
      state.listing.options.initFilters.acc_end_date.end = moment(state.listing.options.initFilters.acc_end_date.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    // state.listing.requestLoading = true
  },
  'companyTable/LOADED' (state, data) {
    console.log('companyTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'companyTable/LIMIT' (state, data) {
    console.log('companyTable/LIMIT', data)
  },
  'companyTable/ERROR' (state, data) {
    console.log('companyTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'companyTable/ROW_CLICK' (state, data) {
    console.log('companyTable/ROW_CLICK', data)
  },
  'companyTable' (state, { requestLoading }) {
    state.listing.requestLoading = requestLoading === undefined ? state.listing.requestLoading : requestLoading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
