<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :open-on-click="false"
    :open-on-focus="true"
    v-click-outside="meun = false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ attrs }">
      <!-- <div>date: {{ date }}, input: {{ input }}, value: {{ value }}</div> -->
      <v-text-field
        ref="input"
        v-model="input"
        :placeholder="$refs.input ? ($refs.input.isFocused ? 'YYYY-MM-DD' : '') : ''"
        v-mask="'####-##-##'"
        v-bind="{
          ...attrs,
          ...$props
        }"
        @focus="onFocus"
        @keypress.enter.prevent="menu = false"
        @keydown.tab="menu = false"
        :clearable="date ? true : false"
        @click:clear="onClear"
        @click:prepend-inner="menu = true"
        autocomplete="off"
        :dense="dense"
        :data-cy="dataCy"
        :error="error? error : $v.input.$error"
        :error-messages="errorMessages !== '' ? errorMessages: $v.input.dateFormat? $t('validate.dateFormat') : ''"
        :prepend-inner-icon="prependInnerIcon"
      >
        <template v-slot:label>
          <slot
            v-if="!!$slots.mylabel"
            name="mylabel"
            :mylabeltext="{ label }"
          />
          <span v-else>
            {{ label }}
          </span>
        </template>
        <!-- <template
          v-slot:prepend-inner
        >
          <div class="align-self-auto">
            <slot
              v-if="!!$slots.myicon"
              name="myicon"
              :defaulticon="'mdi-calendar'"
            />
            <v-icon
              v-else
              dense
            >
              mdi-calendar
            </v-icon>
          </div>
        </template> -->
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      @input="onPickDate"
      :data-cy="`${dataCy}_picker`"
    />
  </v-menu>
</template>

<script>
import Vue from 'vue'
import { mask } from 'vue-the-mask'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// const dateFormat = (value) => (value !== null && value !== '') &&
//                               !this.moment(value, ['YYYY-MM-DD'], true).isValid()

export default {
  directives: {
    mask
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Date, String, Number],
      default: null
    },
    range: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: String,
      default: ''
    },
    // customLabel: {
    //   type: Boolean,
    //   default: false
    // },
    dataCy: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependInnerIcon: {
      type: String,
      default: 'mdi-calendar'
    }
  },
  watch: {
    // ['$refs.input.isFocused'] (_isFocused) {
    //     console.log(_isFocused);
    //     if (_isFocused)
    //         this.menu = true;
    // },
    input (_new) {
      if (this.moment(_new, ['YYYY-MM-DD'], true).isValid()) {
        return this.$emit('input', _new)
      } else if (this.moment(_new, ['YYYY-MM'], true).isValid()) {
        this.date = _new
        return this.date
      } else if (this.moment(_new, ['YYYY'], true).isValid()) {
        this.date = _new += '-01'
        return this.date
      } else if (_new === '') {
        this.$emit('input', '')
        return ''
      }
    },
    date (/* _new */) {
      // var try_parse = moment(_new, ['YYYY', 'YYYY-MM', 'YYYY-MM-DD'], true);

      // if (try_parse.isValid()) {
      //     return this.$emit('input', _new);
      // }
      // return this.$emit('input', _new);
    },
    value (_new) {
      // console.log('new value', _new)
      this.date = this.convert(_new)
      this.input = this.convert(_new)
      // this.date = moment(_new).format('YYYY-MM-DD');
      // this.date = this.convert(_new);
      // this.convert(_new);
    },
    '$v.$invalid': function _watch$vSomeField$invalid (value) {
      this.correctDate = value
      // console.log('invalid field', value, this.correctDate)
    }
  },
  data () {
    return {
      date: this.convert(this.value), // || new Date().toISOString().substr(0, 10),
      input: this.convert(this.value),
      menu: false,
      typing_timeout: null,
      correctDate: true
    }
  },
  validations () {
    return {
      input: {
        dateFormat: (value) => (value !== null && value !== '') &&
                              !this.moment(value, ['YYYY-MM-DD'], true).isValid()
      }
    }
  },
  methods: {
    onClear () {
      // this.$refs.input.blur();
      // this.menu = false;
      this.date = ''
      this.$emit('input', '')
    },
    onBlur () {
      // setTimeout(() => {
      //     this.menu = false;
      // }, 500);
    },
    convert (input) {
      // console.log('convert value', input)
      if (input === 0) { return '' }

      if (input) { return this.moment(input).format('YYYY-MM-DD') }

      return input
      // // if (typeof input == 'number' && input >= 0) {
      // //     return moment(input).format('YYYY-MM-DD')
      // // }
      // // if (typeof input == 'string') {
      // //     return input
      // // }

      // // return null;
    },
    onPickDate () {
      this.$emit('input', this.date)
      this.menu = false
    },
    onFocus () {
      setTimeout(() => {
        this.menu = this.$refs.input.isFocused
      }, 100)
      // this.menu = true
      // this.$nextTick(() => {
      //     this.menu = true
      // })
    }
  },
  mounted () {
    // console.log(this.customLabel ? 'mdi-calendar-range' : 'mdi-calendar')
  }
}
</script>

<style type="text/css" scoped>

</style>
