import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))
/* global moment */

// initial state
const state = {
  records: [],
  search: '',
  orderby: '',
  order: '',
  page: 0,
  limit: 10,
  // checkoutStatus: null
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: [
      'ref_id',
      'idens',
      'names',
      'status',
      'batch_scan_date',
      // 'emails',
      'tel',
      // 'fax',
      'create_date',
      'updated_date',
      'action'
    ],
    dateColumns: ['create_date', 'updated_date', 'batch_scan_date', 'acc_end_date'],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      requestFunction (request) {
        this.requestKeys = request
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            if (this.$store) {
              this.$store.commit('natural_person/peopleTable', { requestLoading: true })
            }
            try {
              Promise.all([
                request.query.idens ? client.identification.findWhere({
                  where: {
                    id_number: {
                      contains: request.query.idens
                    },
                    parent: {
                      '>': 0
                    }
                  },
                  limit: 500,
                  select: 'parent'
                }) : null,
                request.query.names ? (this.lastSearchNames === request.query.names.trim() ? this.lastSearchNamesResults : client.name.findWhere({
                  where: {
                    // partial sur & other
                    $OR: String(request.query.names).split(' ')
                      .filter(Boolean)
                      .reduce((acc, _, key, all) => {
                        if (key !== all.length - 1) {
                          acc.push({
                            sur: { $Contains: all.slice(0, acc.length + 1).filter(Boolean).join(' ') },
                            other: { $Contains: all.slice(acc.length + 1).filter(Boolean).join(' ') }
                          })
                        }
                        return acc
                      }, [])
                      .reduce((acc, _, key, all) => {
                        // keep direct search
                        acc.push(all[key])
                        // allow reverse search
                        acc.push({
                          sur: all[key].other,
                          other: all[key].sur
                        })
                        return acc
                      }, [])
                      .concat([
                        // exact sur
                        { sur: { $Contains: request.query.names.trim() } },
                        // exact other
                        { other: { $Contains: request.query.names.trim() } }
                      ]),
                    person: { $NotEqual: null }
                  },
                  limit: 500,
                  select: 'person'
                })) : null
              ]).then(async (results) => {
                this.lastSearchNames = (request.query.names || '').trim()
                this.lastSearchNamesResults = results[1]
                var ids = results.filter(Boolean).map((result) => {
                  return result.data ? result.data.map((row) => {
                    return row.person ? row.person.id : (row.parent ? row.parent.id : null)
                  }) : []
                })
                ids = ids.length > 0 ? ids.reduce((x, y) => {
                  return x.filter((x_row) => {
                    return y.includes(x_row)
                  })
                }) : null

                var criteria = {
                  // params: {
                  where: (((query) => {
                    var _where = { and: [] }

                    if (ids) _where.id = { in: ids }
                    if (query.ref_id != null) _where.ref_id = { $Contains: query.ref_id }
                    if (query.status != null) _where.status = query.status
                    if (query.batch_scan_date) { // _where.batch_scan_date = query.batch_scan_date
                      _where.and = [
                        { batch_scan_date: { '>': new Date(query.batch_scan_date.start).getTime() } },
                        { batch_scan_date: { '<': new Date(query.batch_scan_date.end).getTime() } }
                      ]
                    }
                    // if (query.fax) _where.fax = { contains: query.fax }
                    if (query.tel) _where.tel = { contains: query.tel }
                    if (query.create_date) { // _where.create_date = query.create_date
                      _where.and = [
                        ..._where.and,
                        { create_date: { '>': new Date(query.create_date.start).getTime() } },
                        { create_date: { '<': new Date(query.create_date.end).getTime() } }
                      ]
                    }
                    if (query.updated_date) { // _where.updated_date = query.updated_date
                      _where.and = [
                        ..._where.and,
                        { updated_date: { '>': new Date(query.updated_date.start).getTime() } },
                        { updated_date: { '<': new Date(query.updated_date.end).getTime() } }
                      ]
                    }

                    return _where
                  })(request.query)), // Todo: parse where query
                  // sort: `${request.byColumn} ${request.ascending}`, // Todo: parse sort query
                  limit: request.limit,
                  skip: request.limit * (request.page - 1),
                  // sort: [
                  // 	request.orderBy ? {
                  // 		[request.orderBy]: request.ascending ? 'ASC' : 'DESC'
                  // 	} : null
                  // ].filter(Boolean)
                  sort: request.orderBy ? `${request.orderBy} ${request.ascending ? 'ASC' : 'DESC'}` : 'create_date DESC',
                  populate: 'names,idens'
                  // }
                } //,
                //   count = await API.get('naturalperson_v2/count', criteria), //count = 10; // Todo: hardcode to 10, need call count API
                //   results = await API.get('naturalperson_v2', criteria);

                // resolve({data: results.data, count: count.data.count})

                Promise.all([
                  // API.get('naturalperson_v2/count', criteria),
                  // API.get('naturalperson_v2', criteria)
                  client.individual.count(criteria),
                  client.individual.findWhere(criteria)
                ])
                  .then(([{ data: { count } }, { data }]) => {
                    resolve({ data, count })
                  })
                  .catch((error) => {
                    reject(error)
                  })
              })
            } catch (error) {
              reject(error)
            }
          }, 800)
        })
      },
      // responseAdapter (resp) {
      // 	// console.log('responseAdapter', resp);
      // 	return { data: resp.data[0], count: resp.data[1] };
      // },
      columnsClasses: {
        idens: 'col-xs-2',
        names: 'col-xs-2',
        status: 'col-xs-1',
        emails: 'col-xs-1',
        batch_scan_date: 'col-xs-1',
        tel: 'col-xs-1',
        fax: 'col-xs-1',
        create_date: 'col-xs-1',
        updated_date: 'col-xs-1',
        action: 'col-xs-1'
      },
      sortable: [
        // 'names',
        'ref_id',
        'status',
        'batch_scan_date',
        // 'emails',
        'tel',
        'fax',
        'create_date',
        'updated_date'
      ],
      filterable: [
        'ref_id',
        'idens',
        'names',
        'status',
        'batch_scan_date',
        // 'emails',
        'tel',
        // 'fax',
        'create_date',
        'updated_date'
      ],
      filterByColumn: true,
      dateColumns: ['create_date', 'updated_date', 'batch_scan_date', 'acc_end_date'],
      dateFormat: 'DD/MMM/YYYY'
      // see the options API
    }
  }
}

// getters
const getters = {
  options (state, getters, rootState, rootGetters) {
    const { i18n } = rootGetters
    const { initFilters, initialPage, perPage, orderBy, requestFunction, columnsClasses, sortable, filterable, filterByColumn, dateColumns, dateFormat } = state.listing.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction,
      columnsClasses,
      sortable,
      headings: {
        ref_id: i18n.t('person.listing.table.ref_id.label'),
        idens: i18n.t('person.listing.table.idens.label'),
        names: i18n.t('person.listing.table.names.label'),
        status: i18n.t('person.details.form.status.label'),
        tel: i18n.t('person.details.form.tel.label'),
        batch_scan_date: i18n.t('person.listing.table.batch_scan_date.label'),
        create_date: i18n.t('person.listing.table.create_date.label'),
        updated_date: i18n.t('person.listing.table.updated_date.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      dateFormat,
      dateColumns,
      // datepickerOptions,
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     [i18n.t('listing.table.datepicker.today')]: [moment(), moment()],
      //     [i18n.t('listing.table.datepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [moment().subtract(6, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [moment().subtract(29, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
      //     [i18n.t('listing.table.datepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // },
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      },
      listColumns: {
        status: [
          {
            id: 0,
            text: i18n.t('person.details.form.status.options.inactive')// 'Inactive'
          },
          {
            id: 1,
            text: i18n.t('person.details.form.status.options.active') // 'Active',
            // hide:true
          }
        ]
      }
    }
  },
  people: state => {
    return state.records
  }
  // cartProducts: (state, getters, rootState) => {
  //   return state.items.map(({ id, quantity }) => {
  //     const product = rootState.products.all.find(product => product.id === id)
  //     return {
  //       title: product.title,
  //       price: product.price,
  //       quantity
  //     }
  //   })
  // },

  // cartTotalPrice: (state, getters) => {
  //   return getters.cartProducts.reduce((total, product) => {
  //     return total + product.price * product.quantity
  //   }, 0)
  // }
}

// actions
const actions = {
  findOne (context, payload) {
    console.log('findOne', payload, client.individual.findOne)
    // return API.get(`naturalperson_v2/${payload}`)
    return client.individual.findOne(payload)
  },
  async save (context, payload) {
    var person = JSON.parse(JSON.stringify(payload))
    var clearNull = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key] == null) {
          delete obj[key]
        }
      })
      console.log(obj)
      return obj
    }
    var dateStringToInt = (date_string) => {
      date_string = date_string || 0 // default to 0
      return typeof date_string === 'string' && date_string && !isNaN(new Date(date_string)) ? new Date(date_string).getTime() : date_string
    }
    var handleChild = async (record, path, date_columns, endpoint) => {
      if (record.is_deleted) {
        // TODO: remove
        if (!record.id) {
          return false
        } else {
          // var results = await API.delete(`${path}/${record.id}`);
          var results = await endpoint.destroy(record.id)
          if (results.status !== 200) { throw new Error(`DELETE FAILED: ${path}`) }
          return false
        }
      } else {
        // record.effect_date = dateStringToInt(record.effect_date);
        Object.keys(record).forEach((key) => {
          if (date_columns.indexOf(key) !== -1) {
            record[key] = dateStringToInt(record[key])
          }
        })
        if (!record.id) {
          // TODO: CREATE
          delete record.id
          // var results = await API.post(path, clearNull(record));
          results = await endpoint.create(clearNull(record))
          if (results.status !== 200) { throw new Error(`CREATE FAILED: ${path}`) }
          return results.data.id
        } else {
          // TODO: UPDATE
          results = await endpoint.update(record.id, clearNull(record)) // API.patch(`${path}/${record.id}`, clearNull(record));
          if (results.status !== 200) { throw new Error(`UPDATE FAILED: ${path}`) }
          return results.data.id
        }
      }
    }

    if (payload.ref_id) {
      const duplicate = await client.individual.findWhere({
        where: {
          id: {
            $NotEqual: payload.id
          },
          ref_id: payload.ref_id
        }
      })
      if (duplicate.data.length > 0) {
        const error = new Error('')
        error.response = {
          data: {
            code: 'E_UNIQUE'
          }
        }
        return Promise.reject(error)
      }
    }

    return new Promise((resolve, reject) => {
      try {
        Promise.all([
          Promise.all((person.names || []).map(x => handleChild(x, 'name', ['effect_date'], client.name))),
          Promise.all((person.addresses || []).map(x => handleChild(x, 'address', ['effect_date'], client.address))),
          Promise.all((person.emails || []).map(x => handleChild(x, 'email', ['effect_date'], client.email))),
          Promise.all((person.idens || []).map(x => handleChild(x, 'iden', ['effect_date', 'expiry_date'], client.identification))),
          Promise.all((person.attachments || []).map(x => handleChild(x, 'iden', ['effect_date', 'expiry_date'], client.attachment)))
          // Promise.all((person.bank_infos || []).map(x => handleChild(x, 'bankinfo_v2', ['acc_open_date', 'acc_close_date']))),
        ]).then(([names_ids, address_ids, emails_ids, idens_ids, attachment_ids]) => {
          person.names = names_ids.filter(Boolean)
          person.addresses = address_ids.filter(Boolean)
          person.emails = emails_ids.filter(Boolean)
          person.idens = idens_ids.filter(Boolean)
          person.attachments = attachment_ids.filter(Boolean)
          // person.bank_infos = bank_infos_ids.filter(Boolean)
          delete person.bank_infos

          // date to int
          person.dob = dateStringToInt(person.dob)
          person.gender = person.gender || ''

          // when create new, status default to "Active"
          if (!person.id) { person.status = 1 }

          if (person.id) {
            // API.patch(`naturalperson_v2/${person.id}`, person)
            client.individual
              .update(person.id, person)
              .then(resolve)
              .catch(reject)
          } else {
            delete person.id
            // API.post(`naturalperson_v2`, person)
            client.individual
              .create(person)
              .then(resolve)
              .catch(reject)
          }
        })
          .catch((error) => {
            reject(error)
          })
      } catch (error) {
        reject(error)
      }
    })
  },
  remove (context, payload) {
    return client.individual.destroy(payload)
    // return API.delete(`naturalperson_v2/${payload}`)
  },
  similarIdentity (context, { person_id, id_type, id_number }) {
    return client.identification.findWhere({
      where: {
        type: id_type,
        id_number: {
          $Contains: id_number
        },
        parent: { $NotEqual: person_id }
      }
    })
  },
  similarName (context, { person_id, lang, name_type, sur, other }) {
    return client.name.findWhere({
      where: {
        $AND: [
          { person: { $NotEqual: person_id } },
          { person: { $GreaterThan: 0 } }
        ],
        lang,
        name_type,
        $OR: [
          sur ? { sur: { $Contains: sur } } : null,
          other ? { other: { $Contains: other } } : null
        ].filter(Boolean)
      },
      limit: 10
    })
  }
}

// mutations
const mutations = {
  'peopleTable/SET_DATA' (state, data) {
    console.log('peopleTable/SET_DATA', data)
  },
  'peopleTable/PAGINATION' (state, data) {
    console.log('peopleTable/PAGINATION', data)
  },
  'peopleTable/FILTER' (state, payload) {
    console.log('peopleTable/FILTER', payload)
  },
  'peopleTable/SORTED' (state, data) {
    console.log('peopleTable/SORTED', data)
  },
  'peopleTable/LOADING' (state, payload) {
    console.log('peopleTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.updated_date) {
      state.listing.options.initFilters.updated_date.start = moment(state.listing.options.initFilters.updated_date.start)
      state.listing.options.initFilters.updated_date.end = moment(state.listing.options.initFilters.updated_date.end)
    }
    if (state.listing.options.initFilters.create_date) {
      state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start)
      state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end)
    }
    if (state.listing.options.initFilters.batch_scan_date) {
      state.listing.options.initFilters.batch_scan_date.start = moment(state.listing.options.initFilters.batch_scan_date.start)
      state.listing.options.initFilters.batch_scan_date.end = moment(state.listing.options.initFilters.batch_scan_date.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    // state.listing.requestLoading = true
  },
  'peopleTable/LOADED' (state, data) {
    console.log('peopleTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'peopleTable/LIMIT' (state, data) {
    console.log('peopleTable/LIMIT', data)
  },
  'peopleTable/ERROR' (state, data) {
    console.log('peopleTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'peopleTable/ROW_CLICK' (state, data) {
    console.log('peopleTable/ROW_CLICK', data)
  },
  'peopleTable' (state, { requestLoading }) {
    state.listing.requestLoading = requestLoading === undefined ? state.listing.requestLoading : requestLoading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
