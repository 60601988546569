import * as JSDiff from 'diff'
import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))

// initial state
const state = {
  audit: {
    requestLoading: false,
    // paging
    total: 0,
    page: 0,
    limit: 5,
    is_more: false,
    // records
    alertCaseEntity: null,
    trails: [],
    changes: new Map()
  },
  pool: {
    options: {
      sortBy: ['last_hit_at'],
      sortDesc: [1]
    }
  }
}

// getters
const getters = {
  hit_status_options: (state, getters, rootState, { i18n }) => {
    return [
      { value: 0, text: i18n.t('alert.details.form.hit_status.options[0]') },
      { value: 1, text: i18n.t('alert.details.form.hit_status.options[1]') },
      { value: 2, text: i18n.t('alert.details.form.hit_status.options[2]') }
    ]
    // return [
    //   'Pending', // 0
    //   'True hit', // 1
    //   'False hit', // 2
    // ];
  },
  suggested_remarks: (state, getters, rootState, { i18n }) => {
    return i18n.t('alert.details.form.remarks.options')
    // return [
    //   'Enhanced CDD has been completed with AML/CFT risks assessed to be acceptable.  Allows to be on board with high risk rating and recording the fact in application form if required.',
    //   'Enhanced CDD has been completed with AML/CFT risks assessed to be UNACCEPTABLE.  No service would be provided.',
    //   'Suspicious transaction report has been prepared and filed to the authority.',
    // ]
  },
  exposition_options: (state, getters, rootState, { i18n }) => (hit_status) => {
    if (parseInt(hit_status) === 1) {
      return [
        { value: 0, text: i18n.t('alert.details.form.exposition.options_true[0]') },
        { value: 1, text: i18n.t('alert.details.form.exposition.options_true[1]') },
        { value: 2, text: i18n.t('alert.details.form.exposition.options_true[2]') },
        { value: 3, text: i18n.t('alert.details.form.exposition.options_true[3]') },
        { value: 4, text: i18n.t('alert.details.form.exposition.options_true[4]') },
        { value: 5, text: i18n.t('alert.details.form.exposition.options_true[5]') }
      ]
    } else if (parseInt(hit_status) === 2) {
      return [
        { value: 0, text: i18n.t('alert.details.form.exposition.options_false[0]') },
        { value: 1, text: i18n.t('alert.details.form.exposition.options_false[1]') }
      ]
    }
    return []
  }
}

// actions
const actions = {
  findOne (unusedContext, payload) {
    return client.alert_case.findOne(payload)
  },
  findWhere (unusedContext, criteria) {
    return client.alert_case.findWhere(criteria)
  },
  count (unusedContext, criteria) {
    return client.alert_case.count(criteria)
  },
  findWhereEntities (unusedContext, { parent, criteria }) {
    return client.alert_case.entities.findWhere(parent, criteria)
  },
  countEnities (unusedContext, { parent, criteria }) {
    return client.alert_case.entities.count(parent, criteria)
  },
  update (unusedContext, { id, status, exposition, remarks }) {
    return client.alert_case_entity.update(id, {
      status, exposition, remarks
    })
  },
  findAudits ({ commit, state, getters }, payload) {
    commit('RESET_AUDIT')
    commit('SET_AUDIT', { requestLoading: true })

    Promise.all([
      client.alert_case.countHistories(payload.alert_case, payload.entity_id),
      client.alert_case.findHistories(payload.alert_case, payload.entity_id, state.audit.limit, 0)
    ]).then(([{ status: status0, data: { count } }, { status: status1, data }]) => {
      if (status0 !== 200 || status1 !== 200) { return commit('SET_AUDIT', { requestLoading: false }) }

      commit('SET_AUDIT', {
        requestLoading: false,
        alertCaseEntity: payload,
        total: count,
        page: 0,
        trails: data, // data.map(x => x.archive_object.hit_status_text = i18n.t('alert.details.form.hit_status.options')[x.archive_object.hit_status]),
        hit_status_options: getters.hit_status_options,
        exposition_options: getters.exposition_options
      })
    }).catch((unusedError) => {
      commit('SET_AUDIT', { requestLoading: false })
    })
  },
  moreAudits ({ state, commit, getters }) {
    // console.log('more', state.audit.is_more, state.audit.requestLoading)
    if (state.audit.is_more && !state.audit.requestLoading) {
      commit('SET_AUDIT', { requestLoading: true })
      client.alert_case
        .findHistories(
          state.audit.alertCaseEntity.alert_case,
          state.audit.alertCaseEntity.entity_id,
          state.audit.limit,
          (state.audit.page + 1) * state.audit.limit
        )
        .then(({ status, data }) => {
          if (status !== 200) { return commit('SET_AUDIT', { requestLoading: false }) }

          commit('SET_AUDIT', {
            requestLoading: false,
            page: state.audit.page + 1,
            trails: data,
            hit_status_options: getters.hit_status_options,
            exposition_options: getters.exposition_options
          })
        })
        .catch((unusedError) => {
          commit('SET_AUDIT', { requestLoading: false })
        })
    }
  },
  createEntities (unusedContext, { review_token, company, person, decisions }) {
    return client.alert_case_entity.createByScreeningReviewToken(
      company ? {
        screening_review_token: review_token,
        company,
        decisions
      } : {
        screening_review_token: review_token,
        person,
        decisions
      }
    )
  }
}

// mutations
const mutations = {
  RESET_AUDIT (state) {
    state.audit.requestLoading = false
    state.audit.is_more = false
    state.audit.alertCaseEntity = null
    state.audit.total = 0
    state.audit.page = 0
    state.audit.trails.splice(0)
    // state.audit.changes.splice(0)
    state.audit.changes.clear()
  },
  SET_AUDIT (state, { requestLoading, alertCaseEntity, total, page, trails, hit_status_options, exposition_options }) {
    // console.log(requestLoading, alert, total, page, trails)
    state.audit.requestLoading = requestLoading === undefined ? state.audit.requestLoading : requestLoading
    // paging
    state.audit.total = total || state.audit.total
    state.audit.page = page || state.audit.page
    state.audit.is_more = (state.audit.page + 1) * state.audit.limit < state.audit.total
    // records
    state.audit.alertCaseEntity = alertCaseEntity || state.audit.alertCaseEntity
    state.audit.trails = state.audit.trails.concat(trails || [])
    // state.audit.changes.splice(0)
    if (hit_status_options && exposition_options) {
      state.audit.trails.reduce((a, b, index) => {
        if (!a) return b

        var hit_status_to_word = alert => (hit_status_options.find(x => x.value === alert.status) || { text: '' }).text
        var expoistion_to_word = alert => (exposition_options(alert.status).find(x => String(x.value) === String(alert.exposition)) || { text: '' }).text

        // state.audit.changes.push({
        state.audit.changes.set(index, {
          id: index,
          datetime: a.updated_date,
          a,
          b,
          hit_status: JSDiff.diffWordsWithSpace(hit_status_to_word(b), hit_status_to_word(a)),
          exposition: JSDiff.diffWordsWithSpace(expoistion_to_word(b), expoistion_to_word(a)),
          remarks: JSDiff.diffTrimmedLines(b.remarks, a.remarks),
          updated_by: JSDiff.diffWordsWithSpace(b.updated_by, a.updated_by)
        })

        return b
      }, null)
    }
  },
  SET_POOL_OPTIONS (state, payload) {
    state.pool.options = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
