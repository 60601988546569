import { ServerTable, ClientTable } from 'vue-tables-2'
import VtTable from '@/plugins/vue-tables-2/VtTable'
import VtGenericFilter from '@/plugins/vue-tables-2/VtGenericFilter'
import VtDataTable from '@/plugins/vue-tables-2/VtDataTable'
import VtSortControl from '@/plugins/vue-tables-2/VtSortControl'
import VtPagination from '@/plugins/vue-tables-2/VtPagination'
import VtPerPageSelector from '@/plugins/vue-tables-2/VtPerPageSelector'
import VtTextFilter from '@/plugins/vue-tables-2/VtTextFilter'
import VtListFilter from '@/plugins/vue-tables-2/VtListFilter'
import VtDateFilter from '@/plugins/vue-tables-2/VtDateFilter'
// import VtFiltersRow from '@/plugins/vue-tables-2/VtFiltersRow'

export default (Vue) => {
  Vue.use(ServerTable, {
    // texts: {
    //     count: "Showing {from} to {to} of {count} records|{count} records|One record",
    //     first: "First",
    //     last: "Last",
    //     filter: "Filter:",
    //     filterPlaceholder: "Search query",
    //     limit: "Records:",
    //     page: "Page:",
    //     noResults: "No matching records",
    //     noRequest:"Please select at least one filter to fetch results",
    //     filterBy: i18n.t('listing.table.texts.filterBy'),
    //     loading: "Loading...",
    //     defaultOption: "Select {column}",
    //     columns: "Columns"
    // },
  }, true, 'bootstrap4', {
    table: VtTable,
    genericFilter: VtGenericFilter,
    dataTable: VtDataTable,
    sortControl: VtSortControl,
    pagination: VtPagination,
    perPageSelector: VtPerPageSelector,
    // filtersRow: VtFiltersRow,
    textFilter: VtTextFilter,
    listFilter: VtListFilter,
    dateFilter: VtDateFilter
  })
  Vue.use(ClientTable, {}, false, 'bootstrap4', {
    table: VtTable,
    genericFilter: VtGenericFilter,
    dataTable: VtDataTable,
    sortControl: VtSortControl,
    pagination: VtPagination,
    perPageSelector: VtPerPageSelector,
    // filtersRow: VtFiltersRow,
    textFilter: VtTextFilter,
    listFilter: VtListFilter,
    dateFilter: VtDateFilter
  })
}
