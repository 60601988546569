import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))

// initial state
const state = {
  import_history: []
}

// getters
const getters = {
  import_history: state => {
    return state.import_history
  }
}

// actions
const actions = {
  importXLSX: (unusedContext, { import_type, file }) => {
    return client.importer.importXLSX(import_type, file)
  },
  import_refresh_socket: ({ commit }, payload) => {
    commit('SET_BASIC_INFO', payload)
  }
}

// mutations
const mutations = {
  SET_BASIC_INFO: (state, payload) => {
    state.import_history.unshift(payload)
    // state.basic.scanning = scanning;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
