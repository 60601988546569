<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="input"
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        @blur="onBlur"
        @keypress.enter.prevent="menu = false"
        :clearable="date ? true : false"
        @click:clear="onClear"
      />
    </template>
    <v-date-picker
      v-model="date"
      range
      no-title
      scrollable
    >
      <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onClear">
                Clear
            </v-btn>
            <v-btn text color="primary" @click="menu = false">
                Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
            </v-btn> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Date, String, Number],
      default: null
    }
  },
  watch: {
    date (_new) {
      this.$emit('input', _new)
    },
    value (_new) {
      this.date = _new
    }
  },
  data () {
    return {
      date: this.value, // || new Date().toISOString().substr(0, 10),
      menu: false
    }
  },
  methods: {
    onClear () {
      this.$refs.input.blur()
      // this.menu = false;
      // this.date = '';
    },
    onBlur () {
      setTimeout(() => {
        this.menu = false
      }, 500)
    }
  }
}
</script>

<style>

</style>
