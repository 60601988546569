if (location.search) {
  var auth_redirect = new URLSearchParams(location.search)
  if (auth_redirect.has('at') && (!localStorage.getItem('at') || localStorage.getItem('at') === 'null')) {
    localStorage.setItem('at', auth_redirect.get('at'))
  }
  if (auth_redirect.has('rt') && (!localStorage.getItem('rt') || localStorage.getItem('rt') === 'null')) {
    localStorage.setItem('rt', auth_redirect.get('rt'))
  }
  location.search = ''
}

export default {
  // baseURL: 'http://113.254.9.134:1337//',
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('at')}`
  }
}
