import { RBAClient } from 'rba-client'

export default (Vue, store, i18n) => {
  Vue.prototype.$client = RBAClient.getInstance(require('@/api/config'), {
    onTokenRefresh: () => {
      store.commit('auth/SET_TOKEN', { refreshing: true })
      Vue.notify({
        type: 'info',
        text: i18n.t('token.refreshing')
      })
    },
    onTokenRefreshed: () => {
      store.commit('auth/SET_TOKEN', { refreshing: false, access_token: RBAClient.at, refresh_token: RBAClient.rt })
      Vue.notify({
        type: 'info',
        text: i18n.t('token.refreshed')
      })
    },
    onTokenRefreshFailed: () => {
      store.dispatch('auth/logout')
      setTimeout(() => {
        Vue.notify({
          type: 'error',
          text: i18n.t('token.refreshFailed'),
          duration: -1
        })
      }, 500)
    },
    onTokenSet: ({ accessToken }) => {
      store.commit('auth/SET_TOKEN', { access_token: accessToken })
    },
    onTokenRemove: () => {
      store.dispatch('auth/logout')
    }
  })
  if (process.env.NODE_ENV === 'development') {
    console.log(RBAClient, Vue.prototype.$client)
  }
}
