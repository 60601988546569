<template>
  <v-dialog
    v-model="dialog_delete_confirmation"
    max-width="350"
    :data-cy="`${dataCy}_delete_dialog`"
  >
    <template v-slot:activator="{ on }">
      <slot
        v-bind="{
          on: on,
          loading: dialog_delete_confirmation_loading
        }"
      />
    </template>
    <template>
      <v-card>
        <template v-slot:default>
          <v-card-title
            :data-cy="`${dataCy}_delete_title`"
          >
            {{ title || $t('dialogs.delete_confirm.title') }}
          </v-card-title>
          <v-card-text>{{ text || $t('dialogs.delete_confirm.text') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error darken-1"
              text
              :loading="dialog_delete_confirmation_loading"
              @click="onYesClicked"
              :data-cy="`${dataCy}_confirmdlt_btn`"
            >
              {{ yes || $t('dialogs.delete_confirm.buttons.yes') }}
            </v-btn>
            <v-btn
              color="darken-1"
              text
              :disabled="dialog_delete_confirmation_loading"
              @click="dialog_delete_confirmation = false"
              :data-cy="`${dataCy}_cancel_btn`"
            >
              {{ no || $t('dialogs.delete_confirm.buttons.no') }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    yes: {
      type: String,
      default: ''
    },
    no: {
      type: String,
      default: ''
    },
    dataCy: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog_delete_confirmation: false,
      dialog_delete_confirmation_loading: false
    }
  },
  methods: {
    async onYesClicked () {
      this.dialog_delete_confirmation_loading = true
      await new Promise((resolve) => {
        this.$emit('yes', resolve)
      })
      this.dialog_delete_confirmation_loading = false
      this.dialog_delete_confirmation = false // close
    }
  }

}
</script>

<style>

</style>
