export const initialPerson = () => {
  return {
    id: 0,
    tel: '',
    fax: '',
    cr_reference: '',
    dob: null,
    pob: '',
    occupation: '',
    gender: '',
    names: [],
    idens: [],
    hkids: [],
    passports: [],
    addresses: [],
    emails: []
  }
}

export const initialCompany = () => {
  return {
    id: 0,
    ref_id: '',
    coi_number: '',
    coi_name: '',
    incorp_place: '',
    incorp_date: null,
    estab_date: null,
    company_type: 0,
    pps_members: {
      number_of_members: 0,
      details: []
    },
    // presenter_type: 0,
    // presenter_id: 0,
    // presenter: {}
    business_nature: '',
    fund_source: '',
    tel: '',
    contact_person: '',
    contact_person_tel: '',
    contact_fax: '',
    cr_ref: '',
    ma_name: '',
    acc_start_date: null,
    acc_end_date: null,
    acc_no_reason: '',
    is_br_3y: false,
    br_number: '',
    founders: [],
    secretaries: [],
    directors: [],
    shareholders: [],
    names: [],
    emails: [],
    addresses: [],
    auth_reps: [],
    significant_controllers: [],
    bank_infos: []
  }
}

export const addShares = (founder) => {
  founder.shares.push({
    id: 0,
    company_id: 0,
    capacity_type: 0,
    capacity_id: 0,
    type: '',
    currency: '',
    shares_num: 0,
    shares_paid_amount: 0,
    rights: ''
  })
}

export const getNameForm = (_lang, _type) => {
  return {
    uuid: '',
    is_deleted: false,
    name_type: _type || 1,
    capacity_id: 0,
    lang: _lang || 1,
    other: '',
    sur: '',
    effect_date: null,
    create_date: null,
    updated_date: null
  }
}

export const addName = (names, _lang, _type) => {
  // names.push({
  // 	name_type: _type || 0,
  // 	capacity_id: 0,
  // 	lang: _lang || 1,
  // 	other: "",
  // 	sur: "",
  // 	effect_date: null,
  // 	create_date: null,
  // 	updated_date: null
  // })
  names.push(getNameForm(_lang, _type))
}

export const getAddressForm = (address_type) => {
  return {
    address_type: address_type || 1,
    capacity_type: 0,
    capacity_id: 0,
    address_1: '',
    address_2: '',
    address_3: '',
    address_4: '',
    effect_date: null,
    create_date: null,
    updated_date: null
  }
}

export const addAddress = (addresses, address_type) => {
// addresses.push({
// 	address_type: address_type || 1,
// 	capacity_type: 0,
// 	capacity_id: 0,
// 	address_1: "",
// 	address_2: "",
// 	address_3: "",
// 	address_4: "",
// 	effect_date: null,
// 	create_date: null,
// 	updated_date: null
// })
  addresses.push(getAddressForm(address_type))
}

export const getEmailForm = (email_type) => {
  var _email_type = email_type || 1

  return {
    capacity_type: 0,
    capacity_id: 0,
    email: '',
    email_type: _email_type,
    effect_date: null,
    create_date: null,
    updated_date: null
  }
}

export const addEmail = (emails, email_type) => {
// emails.push({
// 	capacity_type: 0,
// 	capacity_id: 0,
// 	email: "",
// 	effect_date: null,
// 	create_date: null,
// 	updated_date: null
// })
  emails.push(getEmailForm(email_type))
}

export const addGuarantee = (company) => {
  company.pps_members.details.push({
    id: 0,
    company_id: 0,
    member_class: '',
    currency: '',
    amount: 0
  })
}

// export const getHKIDForm = () => {
// 	return {
// 		id: 0,
// 		np_id: 0,
// 		id_number: '',
// 		effect_date: null,
// 		is_deleted: false,
// 		uuid: '',
// 	};
// }

// export const addHKID = (HKIDs) => {
// 	// HKIDs.push({
// 	// 	id: 0,
// 	// 	np_id: 0,
// 	// 	id_number: '',
// 	// 	effect_date: null,
// 	// })
// 	HKIDs.push(getHKIDForm());
// };

// export const getPassportForm = () => {
// 	return {
// 		uuid: '',
// 		id: 0,
// 		np_id: 0,
// 		country: '',
// 		id_number: '',
// 		id_number_security_num: '',
// 		expiry_date: null,
// 		expiry_date_security_num: '',
// 		effect_date: null,
// 		create_date: null,
// 		updated_date: null,
// 		is_deleted: false,
// 	};
// }

// export const addPassport = (passports) => {
// 	// passports.push({
// 	// 	id: 0,
// 	// 	np_id: 0,
// 	// 	country: '',
// 	// 	id_number: '',
// 	// 	expiry_date: null,
// 	// 	effect_date: null,
// 	// 	create_date: null,
// 	// 	updated_date: null,
// 	// })
// 	passports.push(getPassportForm());
// };

export const getIdenForm = () => {
  return {
    uuid: '',
    id: 0,
    np_id: 0,
    type: 1,
    country: '',
    id_number: '',
    id_number_security_num: '',
    expiry_date: null,
    expiry_date_security_num: '',
    effect_date: null,
    create_date: null,
    updated_date: null,
    is_deleted: false
  }
}

export const addIden = (iden) => {
  iden.push(getIdenForm())
}

export const getBankInfoForm = () => {
  return {
    uuid: '',
    id: 0,
    capacity_type: 0,
    capacity_id: 1,
    acc_bank: '',
    acc_type: '',
    acc_number: '',
    acc_currency: '',
    acc_open_date: null,
    acc_close_date: null,
    acc_status: '',
    acc_remarks: '',
    create_date: null,
    updated_date: null,
    is_deleted: false
  }
}

export const addBankInfo = (bank_info) => {
  bank_info.push(getBankInfoForm())
}
