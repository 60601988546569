<template>
  <v-container
    fluid
    data-cy="natural_person_detail"
  >
    <loading
      :active.sync="requestLoading"
      :is-full-page="false"
    />
    <v-row v-if="is_edit_mode">
      <v-col cols="4">
        <!-- <label>Status</label> -->
        <v-select
          v-model="form.status"
          :label="$t('person.details.form.status.label')"
          :items="[
            { value: 0, text: $t('person.details.form.status.options.inactive') },
            { value: 1, text: $t('person.details.form.status.options.active') },
          ]"
        />
      </v-col>
      <!-- <v-col cols="2">
				<label>Pending Alerts</label>
				<div>{{ form.alerts_pending }}</div>
			</v-col>
			<v-col cols="2">
				<label>True Hit Alerts</label>
				<div>{{ form.alerts_true }}</div>
			</v-col>
			<v-col cols="2">
				<label>False Hit Alerts</label>
				<div>{{ form.alerts_false }}</div>
			</v-col> -->
      <v-spacer />
      <v-col cols="2">
        <label>{{ $t('person.details.form.risk_level.label') }}</label>
        <div :style="{ 'color' : (risk_level == 'High Risk' ? 'red' : (risk_level == 'Low Risk' ? 'green' : (risk_level == 'Medium Risk' ? 'orange' : ''))) }">
          {{ risk_loading ? $t('person.details.form.risk_level.loading') : $t('person.details.form.risk_level.options')[risk_level] }}
          ({{ Math.max(risk_detail ? risk_detail : 0, 0) }})
        </div>
        <!-- <label>{{ $t('person.details.form.risk_level.label') }}</label>
				<div :style="{ 'color' : (risk_level == 'High Risk' ? 'red' : (risk_level == 'Low Risk' ? 'green' : (risk_level == 'Medium Risk' ? 'orange' : ''))) }">{{ risk_loading ? 'calculating...' : risk_level }} ({{ Math.max(risk_detail ? risk_detail : 0, 0) }})</div> -->
      </v-col>
    </v-row>
    <component
      :is="is_edit_mode ? 'div' : 'v-stepper'"
      v-bind="is_edit_mode ? {} : { 'alt-labels': true, 'flat': true }"
      v-model="step"
    >
      <v-tabs
        v-if="is_edit_mode"
        v-model="tab"
        class="mb-5"
        show-arrows
      >
        <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
        <v-tab
          data-cy="np_names_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.name_form.$anyError || $v.form.names.$error"
          >
            <span :class="($v.name_form.$anyError || $v.form.names.$error) ? 'error--text' : ''">{{ $t('person.details.tabs.names') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          data-cy="np_iden_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.iden_form.$anyError"
          >
            <span :class="$v.iden_form.$anyError ? 'error--text' : ''">{{ $t('person.details.tabs.idens') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          data-cy="np_contact_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.address_form.$anyError || $v.email_form.$anyError"
          >
            <span :class="($v.address_form.$anyError || $v.email_form.$anyError) ? 'error--text' : ''">{{ $t('person.details.tabs.contact') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          v-if="$slots.roles"
          data-cy="np_roles_tab"
        >
          {{ $t('person.details.tabs.role') }}
        </v-tab>
        <v-tab
          data-cy="np_alerts_tab"
        >
          {{ $t('person.details.tabs.alerts') }}
        </v-tab>
        <v-tab
          data-cy="np_bankinfo_tab"
        >
          <v-badge
            left
            color="error"
            dot
            :value="$v.form.bank_infos.$anyError"
          >
            <span :class="($v.form.bank_infos.$anyError) ? 'error--text' : ''">{{ $t('company.details.tabs.bank') }}</span>
          </v-badge>
        </v-tab>
        <v-tab
          data-cy="np_atta_tab"
        >
          {{ $t('person.details.tabs.attachment') }}
        </v-tab>
      </v-tabs>
    </component>
    <component
      :is="is_edit_mode ? 'v-tabs-items' : 'form-wizard'"
      v-model="tab"
      v-bind="is_edit_mode ? { options: { useUrlFragment: false } } : { title: '', subtitle: '', color: '#3c8dbc' }"
      @changed="validateAsync"
      @on-change="onChange"
      @on-complete="onComplete"
    >
      <component
        ref="name_tab"
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('person.details.tabs.names'), suffix: !isTabActive('name_tab') && $v.form.names.$error ? ' <span class=\'badge bg-red\'>&nbsp;</span>' : '' } : { title: $t('person.details.tabs.names') }"
        :before-change="validateAsync"
      >
        <v-row>
          <v-col cols="4">
            <v-text-field
              :label="$t('person.details.form.ref_id.label')"
              autocomplete="off"
              transition="scale-transition"
              v-model="form.ref_id"
              :data-cy="`ref_id`"
            />
          </v-col>
        </v-row>
        <div
          v-for="(name, form_key, i) in name_form"
          :key="form_key"
        >
          <!-- <v-card-subtitle class="px-0">{{ form_key.replace('_', ' ') | ucwords }}</v-card-subtitle> -->
          <v-card-subtitle class="px-0 pb-0">
            {{ $t('person.details.form_name.titles')[form_key] }}
          </v-card-subtitle>
          <v-menu
            auto
            :offset-y="true"
            :value="like.name_form[form_key].is_show"
            :close-on-content-click="false"
            :close-on-click="false"
            :open-on-click="false"
          >
            <template v-slot:activator="{ attrs }">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('person.details.form_name.sur.label')"
                    autocomplete="off"
                    transition="scale-transition"
                    v-bind="{...attrs}"
                    v-model="name.sur"
                    @focus="toggleSimilarDropdown(like.name_form[form_key], true)"
                    @change="toggleSimilarDropdown(like.name_form[form_key], name.sur.length > 0)"
                    @blur="toggleSimilarDropdown(like.name_form[form_key], false)"
                    :error="$v.name_form[form_key].sur.$error || $v.name_form[form_key].$error || $v.form.names.$error"
                    :error-messages="($v.name_form[form_key].sur.$error || $v.name_form[form_key].$error || $v.form.names.$error) ?
                      (!$v.form.names.at_least_one_name ? $t('person.details.form_name.validate.at_least_one_name') :
                        (!$v.name_form[form_key].sur.required ? $t('validate.required', { field: $t('person.details.form_name.sur.label') }) : (
                          !$v.name_form[form_key].duplicate_local ? $t('person.details.form_name.validate.duplicate_local') : (
                            !$v.name_form[form_key].duplicate_db ? $t('person.details.form_name.validate.duplicate_db') : (
                              !$v.name_form[form_key].sur.chinese_words ? $t('person.details.form_name.validate.chinese_words') : ''))))) : ''"
                    :data-cy="`${form_key}_surname_textfield`"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('person.details.form_name.other.label')"
                    autocomplete="off"
                    v-bind="{...attrs}"
                    v-model="name.other"
                    @focus="toggleSimilarDropdown(like.name_form[form_key], true)"
                    @change="toggleSimilarDropdown(like.name_form[form_key], name.other.length > 0)"
                    @blur="toggleSimilarDropdown(like.name_form[form_key], false)"
                    :error="$v.name_form[form_key].other.$error || $v.name_form[form_key].$error || $v.form.names.$error"
                    :error-messages="($v.name_form[form_key].other.$error || $v.name_form[form_key].$error || $v.form.names.$error) ?
                      (!$v.form.names.at_least_one_name ? $t('person.details.form_name.validate.at_least_one_name') : (
                        !$v.name_form[form_key].other.required ? $t('validate.required', { field: $t('person.details.form_name.other.label') }) : (
                          !$v.name_form[form_key].duplicate_local ? $t('person.details.form_name.validate.duplicate_local') : (
                            !$v.name_form[form_key].duplicate_db ? $t('person.details.form_name.validate.duplicate_db') : (
                              !$v.name_form[form_key].other.chinese_words ? $t('person.details.form_name.validate.chinese_words') : ''))))) : ''"
                    :data-cy="`${form_key}_othername_textfield`"
                  />
                </v-col>
                <v-col cols="4">
                  <InputDate
                    v-model="name.effect_date"
                    :label="$t('person.details.form_name.effect_date.label')"
                    :data-cy="`${form_key}_eff_date`"
                    ref="name_effect_date"
                  />
                </v-col>
                <!-- <v-col cols="12"> -->
                <!-- </v-col> -->
              </v-row>
            </template>
            <v-card :loading="!!name_similar_check_timeout">
              <v-card-subtitle>{{ $t('person.details.form_name.dropdown.title') }}</v-card-subtitle>
              <v-card-text v-if="like.name_form[form_key].options.length == 0">
                {{ $t('person.details.form_name.dropdown.no_records') }}
              </v-card-text>
              <v-list v-else>
                <v-list-item
                  v-for="(name_1, key) in like.name_form[form_key].options"
                  @mousedown="switchPersonDetails(name_1.person.id)"
                  :key="key"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ [name_1.sur, name_1.other].filter(Boolean).join(' ') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <v-btn-toggle dense>
            <v-menu
              offset-y
              v-if="(form.names || []).filter(_name => _name.name_type == name.name_type && _name.lang == name.lang && !_name.is_deleted).length"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :data-cy="`${form_key}_name_edit_btn`"
                >
                  <span v-if="name.uuid">{{ $t('person.details.form_name.buttons.edit', { value: [name.sur, name.other].filter(Boolean).join(' ') }) }}</span>
                  <span v-else>
                    <span>{{ $t('person.details.form_name.buttons.select') }}
                      <!-- <i class="fa fa-caret-down"></i> -->
                    </span>
                    (<span class="badge bg-aqua">{{ (form.names || []).filter(_name => _name.name_type == name.name_type && _name.lang == name.lang && !_name.is_deleted).length }}</span>)
                  </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(_name, index) in (form.names || []).filter(_name => _name.name_type == name.name_type && _name.lang == name.lang && !_name.is_deleted)"
                  @click="name_form[form_key] = _name"
                  :key="index"
                >
                  <v-list-item-title
                    :data-cy="`${form_key}_name_${index}`"
                  >
                    <table>
                      <tr>
                        <td
                          class="col-xs-1"
                          style="vertical-align: top;"
                        >
                          #{{ index + 1 }}
                        </td>
                        <td class="col-xs-6">
                          <span>{{ _name.sur }} {{ _name.other }}</span>
                        </td>
                        <td
                          class="col-xs-1"
                          style="vertical-align: top;"
                        >
                          <span v-if="_name.effect_date"> [eff. <timeago
                            :datetime="_name.effect_date"
                            :auto-update="60"
                          />]</span>
                        </td>
                      </tr>
                    </table>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              :disabled="$v.name_form[form_key].$pending"
              @click=" $refs.name_effect_date[i].correctDate?
                saveRow(form.names, name, () => {
                  $v.name_form[form_key].$touch();
                  return $v.name_form[form_key].$invalid;
                }, () => {
                  name_form[form_key] = getNameForm(name.lang, name.name_type);
                  $v.name_form[form_key].$reset();
                }): null"
              :data-cy="`${form_key}_name_save_btn`"
            >
              {{ name.uuid ? $t('person.details.form_name.buttons.update') : $t('person.details.form_name.buttons.add') }}
            </v-btn>
            <v-btn
              @click="removeRow(form.names, name, () => { name_form[form_key] = getNameForm(name.lang, name.name_type); })"
              v-if="name.uuid"
              :data-cy="`${form_key}_name_remove_btn`"
            >
              {{ $t('person.details.form_name.buttons.remove') }}
            </v-btn>
          </v-btn-toggle>
          <v-divider class="mt-2 mb-1" />
        </div>
      </component>
      <component
        ref="id_tab"
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('person.details.tabs.idens') } : { title: $t('person.details.tabs.idens') }"
        :before-change="validateAsync"
      >
        <v-card-subtitle
          class="px-0"
          data-cy="person_iden_title"
        >
          {{ $t('person.details.form_iden.title') }}
        </v-card-subtitle>
        <v-row>
          <v-col
            cols="4"
            data-cy="iden_type_col"
          >
            <!-- <v-select label="Type" v-model="iden_form.type" :items="[
							{ value: 0, text: 'Others' },
							{ value: 1, text: 'HKID' },
							{ value: 2, text: 'Passport' },
						]"></v-select> -->
            <v-select
              :label="$t('person.details.form_iden.type.label')"
              v-model="iden_form.type"
              :items="[
                { value: 0, text: $t('person.details.form_iden.type.options')[0] },
                { value: 1, text: $t('person.details.form_iden.type.options')[1] },
                { value: 2, text: $t('person.details.form_iden.type.options')[2] },
              ]"
              data-cy="iden_type_select"
            >
              <template
                v-slot:selection="items"
              >
                <div
                  data-cy="selected_iden_type"
                >
                  {{ items.item.text }}
                </div>
              </template>
              <template
                v-slot:item="items"
              >
                <div
                  :data-cy="`iden_type_${items.item.value}`"
                >
                  {{ items.item.text }}
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="4"
            v-if="[0,1,2].indexOf(iden_form.type) != -1"
          >
            <v-menu
              auto
              :offset-y="true"
              :value="like.iden_form.is_show"
              :close-on-content-click="false"
              :close-on-click="false"
              :open-on-click="false"
            >
              <template v-slot:activator="{ attrs }">
                <v-text-field
                  v-bind="{...attrs}"
                  :label="$t('person.details.form_iden.id_number.label')"
                  v-model="iden_form.id_number"
                  :error="$v.iden_form.id_number.$error"
                  :error-messages="$v.iden_form.id_number.$dirty?(($v.iden_form.id_number.$error) ? (!$v.iden_form.id_number.hkid_valid_format ? 'Please input valid HKID format (e.g A123456(7))' : (
                    (!$v.iden_form.id_number.hkid_checksum ? 'Please input valid HKID checkdigit' :
                      (!$v.iden_form.duplicate_db ? 'record is duplicated in database' :
                        (!$v.iden_form.duplicate_local ? 'record is duplicated' :
                          (!$v.iden_form.id_number.required ? $t('validate.required', { field: $t('person.details.form_iden.id_number.label') }) : '')))))) : '') : ''"
                  @focus="toggleSimilarDropdown(like.iden_form, true)"
                  @blur="toggleSimilarDropdown(like.iden_form, false)"
                  @change="() => {
                    $v.iden_form.$touch();
                  }"
                  data-cy="iden_number"
                />
              </template>
              <v-card :loading="!!iden_similar_check_timeout">
                <v-card-subtitle>{{ $t('person.details.form_iden.dropdown.title') }}</v-card-subtitle>
                <v-card-text v-if="like.iden_form.options.length == 0">
                  {{ $t('person.details.form_iden.dropdown.no_records') }}
                </v-card-text>
                <v-list v-else>
                  <v-list-item
                    v-for="(iden, key) in like.iden_form.options"
                    @mousedown="switchPersonDetails(iden.parent.id)"
                    :key="key"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle>{{ iden.id_number }}</v-list-item-subtitle>
                      <v-list-item-title>{{ $t(`person.details.form_iden.type.options[${iden.type}]`) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
          <v-col
            cols="2"
            v-if="[0,2].indexOf(iden_form.type) != -1"
          >
            <v-text-field
              :label="$t('person.details.form_iden.id_number_security_num.label')"
              :error="$v.iden_form.id_number_security_num.$error"
              :error-messages="($v.iden_form.id_number_security_num.$dirty && !$v.iden_form.id_number_security_num.required) ? $t('validate.required', { field: $t('person.details.form_iden.id_number_security_num.label') }) : ''"
              v-model="iden_form.id_number_security_num"
              data-cy="iden_no_sec_number"
            />
          </v-col>
          <v-col
            cols="4"
            v-if="[0,2].indexOf(iden_form.type) != -1"
          >
            <InputDate
              :label="$t('person.details.form_iden.expiry_date.label')"
              :error="$v.iden_form.expiry_date.$error"
              :error-messages="$v.iden_form.expiry_date.$error ? $t('validate.required', { field: $t('person.details.form_iden.expiry_date.label') }) : ''"
              v-model="iden_form.expiry_date"
              title="Expiry Date"
              data-cy="iden_exp_date"
              ref="iden_expiry_date"
            />
          </v-col>
          <v-col
            cols="2"
            v-if="[0,2].indexOf(iden_form.type) != -1"
          >
            <v-text-field
              :label="$t('person.details.form_iden.expiry_date_security_num.label')"
              :error="$v.iden_form.expiry_date_security_num.$error"
              :error-messages="($v.iden_form.expiry_date_security_num.$dirty && !$v.iden_form.expiry_date_security_num.required) ? $t('validate.required', { field: $t('person.details.form_iden.expiry_date_security_num.label') }) : ''"
              v-model="iden_form.expiry_date_security_num"
              data-cy="iden_exp_sec_number"
            />
          </v-col>
          <v-col
            cols="3"
            v-if="[0,2].indexOf(iden_form.type) != -1"
          >
            <CountrySelector
              :label="$t('person.details.form_iden.country.label')"
              :error="$v.iden_form.country.$error"
              :error-messages="$v.iden_form.country.$error ? $t('validate.required', { field: $t('person.details.form_iden.country.label') }) : ''"
              v-model="iden_form.country"
              data-cy="iden_country_selector"
            />
          </v-col>
          <v-col :cols="iden_form.type == 1 ? 4: 3">
            <InputDate
              v-model="iden_form.effect_date"
              :label="$t('person.details.form_iden.effect_date.label')"
              data-cy="iden_eff_date"
              ref="iden_effect_date"
            />
          </v-col>
          <v-col cols="12">
            <v-btn-toggle dense>
              <v-menu
                offset-y
                v-if="(form.idens || []).filter(iden => !iden.is_deleted).length"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    data-cy="iden_edit_button"
                  >
                    <span v-if="iden_form.uuid">{{ $t('person.details.form_iden.buttons.edit', { value: iden_form.id_number }) }}</span>
                    <span v-else>
                      <span>{{ $t('person.details.form_iden.buttons.select') }}
                        <!-- <i class="fa fa-caret-down"></i> -->
                      </span>
                      (<span class="badge bg-aqua">{{ form.idens.filter(iden => !iden.is_deleted).length }}</span>)
                    </span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(iden, index) in form.idens.filter(iden => !iden.is_deleted)"
                    @click="iden_form = iden"
                    :key="index"
                  >
                    <v-list-item-title
                      :data-cy="`iden_${index}`"
                    >
                      <table>
                        <tr>
                          <td
                            class="col-xs-1"
                            style="vertical-align: top;"
                          >
                            #{{ index + 1 }}
                          </td>
                          <td class="col-xs-6">
                            <span>{{ iden.id_number }}</span>
                          </td>
                          <td
                            class="col-xs-1"
                            style="vertical-align: top;"
                          >
                            <span v-if="iden.effect_date"> [eff. <timeago
                              :datetime="iden.effect_date"
                              :auto-update="60"
                            />]</span>
                          </td>
                        </tr>
                      </table>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                :loading="$v.iden_form.$pending"
                @click="
                  saveRow(form.idens, iden_form, () => {
                    $v.iden_form.$touch();
                    return $v.iden_form.$invalid && $refs.iden_effect_date.correctDate && ([0,2].indexOf(iden_form.type) != -1? $refs.iden_expiry_date.correctDate : true);;
                  }, () => {
                    iden_form = getIdenForm()
                    $v.iden_form.$reset();
                  })"
                data-cy="iden_save_btn"
              >
                {{ iden_form.uuid ? $t('person.details.form_iden.buttons.update') : $t('person.details.form_iden.buttons.add') }}
              </v-btn>
              <v-btn
                @click="removeRow(form.idens, iden_form, () => { iden_form = getIdenForm() })"
                v-if="iden_form.uuid"
                data-cy="iden_remove_btn"
              >
                {{ $t('person.details.form_iden.buttons.remove') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </component>
      <component
        ref="address_tab"
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('person.details.tabs.contact') } : { title: $t('person.details.tabs.contact') }"
        :before-change="validateAsync"
      >
        <v-row>
          <v-col cols="6">
            <v-card-subtitle
              class="px-0"
              data-cy="person_add_title"
            >
              {{ $t('person.details.form_address.title') }}
            </v-card-subtitle>
            <v-text-field
              :label="$t('person.details.form_address.address_1.label')"
              v-model="address_form.address_1"
              :error="$v.address_form.address_1.$error"
              :error-messages="$v.address_form.address_1.$error ? (!$v.address_form.address_1.required ? $t('validate.required', { field: $t('person.details.form_address.address_1.label') }) : '') : ''"
              data-cy="np_address_1"
            />
            <v-text-field
              :label="$t('person.details.form_address.address_2.label')"
              v-model="address_form.address_2"
              data-cy="np_address_2"
            />
            <v-text-field
              :label="$t('person.details.form_address.address_3.label')"
              v-model="address_form.address_3"
              data-cy="np_address_3"
            />
            <country-selector
              :label="$t('person.details.form_address.address_4.label')"
              v-model="address_form.address_4"
              :error="$v.address_form.address_4.$error"
              :error-messages="$v.address_form.address_4.$error ? (!$v.address_form.address_4.required ? $t('validate.required', { field: $t('person.details.form_address.address_4.label') }) : '') : ''"
              data-cy="np_address_4"
            />
            <InputDate
              v-model="address_form.effect_date"
              :label="$t('person.details.form_address.effect_date.label')"
              data-cy="np_address_eff_date"
              ref="address_effect_date"
            />
            <v-btn-toggle dense>
              <v-menu
                offset-y
                v-if="(form.addresses || []).filter(address => !address.is_deleted).length"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    data-cy="edit_np_address_btn"
                  >
                    <span v-if="address_form.uuid">{{ $t('person.details.form_address.buttons.edit', { value: address_form.address_1 }) }}</span>
                    <span v-else>
                      <span>{{ $t('person.details.form_address.buttons.select') }}
                        <!-- <i class="fa fa-caret-down"></i> -->
                      </span>
                      (<span class="badge bg-aqua">{{ form.addresses.filter(address => !address.is_deleted).length }}</span>)
                    </span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(address, index) in form.addresses.filter(address => !address.is_deleted)"
                    @click="address_form = address"
                    :key="index"
                  >
                    <v-list-item-title
                      :data-cy="`address_${index}`"
                    >
                      <table>
                        <tr>
                          <td
                            class="col-xs-1"
                            style="vertical-align: top;"
                          >
                            #{{ index + 1 }}
                          </td>
                          <td class="col-xs-6">
                            <div>{{ address.address_1 }}</div>
                          </td>
                          <td
                            class="col-xs-1"
                            style="vertical-align: top;"
                          >
                            <span v-if="address.effect_date"> [eff. <timeago
                              :datetime="address.effect_date"
                              :auto-update="60"
                            />]</span>
                          </td>
                        </tr>
                      </table>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                @click=" $refs.address_effect_date.correctDate?
                  saveRow(form.addresses, address_form, () => {
                    $v.address_form.$touch();
                    return $v.address_form.$error;
                  }, () => {
                    address_form = getAddressForm(1)
                    $v.address_form.$reset();
                  }): null"
                data-cy="save_np_address_btn"
              >
                {{ address_form.uuid ? $t('person.details.form_address.buttons.update') : $t('person.details.form_address.buttons.add') }}
              </v-btn>
              <v-btn
                @click="removeRow(form.addresses, address_form, () => { address_form = getAddressForm(1) })"
                v-if="address_form.uuid"
                data-cy="remove_np_address_btn"
              >
                {{ $t('person.details.form_address.buttons.remove') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="6">
            <v-card-subtitle class="px-0">
              {{ $t('person.details.form_email.title') }}
            </v-card-subtitle>
            <v-text-field
              :label="$t('person.details.form_email.email.label')"
              v-model="email_form.email"
              :error="$v.email_form.email.$error"
              :error-messages="$v.email_form.email.$error ? (
                !$v.email_form.email.required ? $t('validate.required', { field: $t('person.details.form_email.email.label') }) : (
                  !$v.email_form.email.email ? $t('validate.email') : '')) : ''"
              data-cy="np_email_textfield"
            />
            <InputDate
              v-model="email_form.effect_date"
              :label="$t('person.details.form_email.effect_date.label')"
              data-cy="np_email_eff_date"
              ref="email_effect_date"
            />
            <v-btn-toggle dense>
              <v-menu
                offset-y
                v-if="(form.emails || []).filter(email => !email.is_deleted).length"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    data-cy="edit_np_email_btn"
                  >
                    <span v-if="email_form.uuid">{{ $t('person.details.form_email.buttons.edit', { value: email_form.email }) }}</span>
                    <span v-else>
                      <span>{{ $t('person.details.form_email.buttons.select') }}
                        <!-- <i class="fa fa-caret-down"></i> -->
                      </span>
                      (<span class="badge bg-aqua">{{ form.emails.filter(email => !email.is_deleted).length }}</span>)
                    </span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(email, index) in form.emails.filter(email => !email.is_deleted)"
                    @click="email_form = email"
                    :key="index"
                  >
                    <v-list-item-title
                      :data-cy="`email_${index}`"
                    >
                      <table>
                        <tr>
                          <td
                            class="col-xs-1"
                            style="vertical-align: top;"
                          >
                            #{{ index + 1 }}
                          </td>
                          <td class="col-xs-6">
                            <span>{{ email.email }}</span>
                          </td>
                          <td
                            class="col-xs-1"
                            style="vertical-align: top;"
                          >
                            <span v-if="email.effect_date"> [eff. <timeago
                              :datetime="email.effect_date"
                              :auto-update="60"
                            />]</span>
                          </td>
                        </tr>
                      </table>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                @click=" $refs.email_effect_date.correctDate?
                  saveRow(form.emails, email_form, () => {
                    $v.email_form.$touch();
                    return $v.email_form.$error;
                  }, () => {
                    email_form = getEmailForm(2)
                    $v.email_form.$reset();
                  }): null"
                data-cy="save_np_email_btn"
              >
                {{ email_form.uuid ? $t('person.details.form_email.buttons.update') : $t('person.details.form_email.buttons.add') }}
              </v-btn>
              <v-btn
                @click="removeRow(form.emails, email_form, () => { email_form = getEmailForm(2) })"
                v-if="email_form.uuid"
                data-cy="remove_np_email_btn"
              >
                {{ $t('person.details.form_email.buttons.remove') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-5" />
        <v-row>
          <v-col cols="6">
            <vue-tel-input-vuetify
              :label="$t('person.details.form.tel.label')"
              placeholder=""
              v-model="form.tel"
              :disabled-fetching-country="true"
              default-country="HK"
              :preferred-countries="['HK']"
              data-cy="np_tel"
            />
          </v-col>
          <v-col cols="6">
            <vue-tel-input-vuetify
              :label="$t('person.details.form.fax.label')"
              placeholder=""
              v-model="form.fax"
              :disabled-fetching-country="true"
              default-country="HK"
              :preferred-countries="['HK']"
              data-cy="np_fax"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-5" />
        <v-row>
          <v-col cols="6">
            <InputDate
              v-model="form.dob"
              :label="$t('person.details.form.dob.label')"
              data-cy="np_dob"
              ref="dob"
            />
          </v-col>
          <v-col cols="6">
            <country-selector
              :label="$t('person.details.form.pob.label')"
              v-model="form.pob"
              ref="pob"
              :hint="$t('person.details.form.pob.hint')"
              data-cy="np_pob"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="form.gender"
              menu-props="auto"
              :label="$t('person.details.form.gender.label')"
              :items="[
                { value: '', text: $t('person.details.form.gender.options.empty') },
                { value: 'Male', text: $t('person.details.form.gender.options.male') },
                { value: 'Female', text: $t('person.details.form.gender.options.female') },
                { value: 'Others', text: $t('person.details.form.gender.options.others') },
              ]"
            />
          </v-col>
        </v-row>
      </component>
      <component
        :is="is_edit_mode ? 'v-tab-item' : 'tab-content'"
        v-bind="is_edit_mode ? { name: $t('person.details.tabs.role') } : { title: $t('person.details.tabs.role') }"
        v-if="$slots.roles"
        :before-change="validateAsync"
      >
        <slot name="roles" />
      </component>
      <v-tab-item
        v-if="is_edit_mode && personId"
        :name="$t('person.details.tabs.alerts')"
        :suffix=" form.alerts_pending > 0 ? (' <span class=\'badge bg-red\'>' + form.alerts_pending + '</span>') : ''"
      >
        <loading
          :active.sync="alerts_table.requestLoading"
          :is-full-page="false"
        />
        <div
          data-cy="alert_table"
        >
          <v-server-table
            ref="alerts_table"
            :name="'alerts/alertsTable' + _uid"
            :columns="alerts_table.columns"
            :options="alerts_table_options"
            @loading="alerts_table.requestLoading = true"
            @loaded="alerts_table.requestLoading = false"
          >
            <!-- <div slot="service_id" slot-scope="props">{{ props.row.service_id }}</div> -->
            <div
              slot="create_date"
              slot-scope="props"
            >
              <DateFormat
                :date="props.row.create_date"
                :showtime="true"
              />
            </div>
            <div
              slot="hit_name"
              slot-scope="props"
            >
              {{ props.row.hit_name }}
            </div>
            <!-- <div slot="service_hit" slot-scope="props">
              <span :class="{ 'label label-danger': props.row.service_hit.toUpperCase() == 'Y' }">{{ props.row.service_hit.toUpperCase() == 'Y' ? 'Pending' : 'Complete' }}</span>
            </div> -->
            <!-- <div slot="hitcompany" slot-scope="props">{{ props.row.hitcompany }}</div> -->
            <!-- <div slot="hitcompanystat" slot-scope="props">{{ props.row.hitcompanystat }}</div> -->
            <!-- <div slot="hit_status" slot-scope="props">{{ parseInt(props.row.hit_status) == 1 ? 'True Hit' : (parseInt(props.row.hit_status) == 2 ? 'False Hit' : 'Pending' )  }}</div> -->
            <div
              slot="hit_status"
              slot-scope="props"
            >
              {{ (hit_status_options.find(x => x.value == props.row.hit_status) || { text: '' }).text }}
            </div>
            <div
              slot="exposition"
              slot-scope="props"
              style="max-width: 300px;"
            >
              {{ (exposition_options(props.row.hit_status).find(x => x.value == props.row.exposition) || { text: '' }).text }}
            </div>
            <div
              slot="remarks"
              slot-scope="props"
            >
              {{ props.row.remarks }}
            </div>
            <!-- <div slot="link" slot-scope="props"><a :href="props.row.link">Info</a></div> -->
            <div
              slot="action"
              slot-scope="props"
            >
              <v-btn
                icon
                @click="popup_alert_detail(props.row);"
              >
                <v-icon>{{ props.row.hit_status == 0 ? 'mdi-pencil' : 'mdi-eye' }}</v-icon>
              </v-btn>
            </div>
          </v-server-table>
        </div>
        <v-dialog
          v-model="dialog_alert_detail"
          max-width="90%"
        >
          <v-card>
            <PopupAlertDetail
              :records="dialog_alert_detail_record"
              @onSave="(form) => {
                $refs.alerts_table.getData();
                dialog_alert_detail = false;
                dialog_alert_detail_record = null;
              }"
            />
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item
        :if="is_edit_mode"
        :name="$t('company.details.tabs.bank')"
      >
        <BankInfo
          v-if="personId"
          :parent-id="personId"
          parent-type="person"
        />
      </v-tab-item>
      <v-tab-item
        :if="is_edit_mode"
        :name="$t('person.details.tabs.attachment')"
      >
        <Attachment
          v-if="personId"
          :parent-id="personId"
          parent-type="person"
        />
      </v-tab-item>
    </component>
  </v-container>
</template>
<script>
import uuidv4 from 'uuid/v4'
import CountrySelector from '@/components/forms/CountrySelector'
import InputDate from '@/components/forms/InputDate'
import * as helper from '@/store/helper'
// import Loading from 'vue-loading-overlay'
import Vuelidate from 'vuelidate'
import { unusedHelpers, required, unusedBetween, requiredIf, unusedMinValue, email, unusedMaxLength, unusedMinLength } from 'vuelidate/lib/validators'
import Vue from 'vue'
import PopupAlertDetail from '@/components/popups/PopupAlertDetail'
import { mapActions, mapGetters } from 'vuex'
import { VTabsItems, VStepper } from 'vuetify/lib'
// import mrtd from 'mrtd'
import Attachment from '@/components/forms/Attachment'
import DateFormat from '@/components/common/DateFormat'
import BankInfo from '@/components/forms/BankInfo'

Vue.use(Vuelidate)

export default {
  components: {
    CountrySelector,
    InputDate,
    PopupAlertDetail,
    VTabsItems,
    VStepper,
    Attachment,
    DateFormat,
    BankInfo
  },
  store: window.$store,
  props: {
    personId: {
      type: Number,
      default: null
    }
  },
  watch: {
    personId () {
      // console.log(arguments);
      this.loadPersonDetail()
    },
    // 'hkid_form.id_number' (new_id) {
    // 	this.hkid_form.id_number = new_id
    // 		.replace(/^[^\w\d]*([A-Za-z])[^\w\d]*([A-Za-z]?)[^\w\d]*(\d)[^\w\d]*(\d)[^\w\d]*(\d)[^\w\d]*(\d)[^\w\d]*(\d)[^\w\d]*(\d)[^\w\d]*(\d)[^\w\d]*$/, "$1$2$3$4$5$6$7$8$9")
    // 		.toUpperCase();
    // },
    // 'iden_form.id_number' (new_id) {
    // 	this.iden_form.id_number = new_id.toUpperCase();
    // }
    'form.pob' (unusedPob) {
      this.risk_loading = true
      this.risk_level = this.$t('person.details.form.risk_level.options.Not In List')
      this.risk_detail = 0
      // this.risk_loading = false;
      this.$store.dispatch('risk/get_risk_info', this.form.pob).then((results) => {
        if (results && results.status === 200) {
          // $store.dispatch('risk/get_risk_info', 'Hong Kong')
          this.risk_level = results.data.risk_level || this.$t('person.details.form.risk_level.options.Not In List')
          this.risk_detail = results.data.risk_score || 0
        }
      })
        .finally(() => {
          this.risk_loading = false
        })
    },
    'name_form.chinese': {
      deep: true,
      handler (name_form) {
        this.name_similar_check('chinese', name_form)
      }
    },
    'name_form.english': {
      deep: true,
      handler (name_form) {
        this.name_similar_check('english', name_form)
      }
    },
    'name_form.english_alias': {
      deep: true,
      handler (name_form) {
        this.name_similar_check('english_alias', name_form)
      }
    },
    'name_form.chinese_alias': {
      deep: true,
      handler (name_form) {
        this.name_similar_check('chinese_alias', name_form)
      }
    },
    'iden_form.id_number': {
      deep: true,
      handler (id_number) {
        this.iden_similar_check(id_number)
      }
    }
  },
  computed: {
    // form () {
    // 	return this.value || helper.initialPerson();
    // },
    // ...mapGetters('setting', {
    // 	countries: 'country_table',
    // }),
    ...mapGetters({
      hit_status_options: 'alerts/hit_status_options',
      exposition_options: 'alerts/exposition_options'
    }),
    is_edit_mode () {
      return this.form.id
    },
    alerts_table_options () {
      return {
        requestFunction: (request) => {
          console.log('requestFunction', request)

          if (!this.personId) {
            return false
          }

          clearTimeout(this.alerts_table.requestTimeout)
          return new Promise((resolve, reject) => {
            this.alerts_table.requestTimeout = setTimeout(async () => {
              try {
                var criteria = {
                  // where: request.query, // Todo: parse where query
                  where: (((query) => {
                    var _where = { person: this.personId }

                    if (query.hit_name) _where.hit_name = { contains: query.hit_name }
                    if (query.id) _where.id = query.id
                    if (query.service_id) _where.service_id = { contains: query.service_id }
                    if (query.exposition) _where.exposition = { contains: query.exposition }
                    if (query.remarks) _where.remarks = { contains: query.remarks }
                    if (query.hit_status != null) _where.hit_status = query.hit_status
                    if (query.create_date) { // _where.create_date = query.create_date
                      _where.and = [
                        { create_date: { '>': new Date(query.create_date.start).getTime() } },
                        { create_date: { '<': new Date(query.create_date.end).getTime() } }
                      ]
                    }

                    return _where
                  })(request.query)),
                  sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                  limit: request.limit,
                  skip: request.limit * (request.page - 1)
                }
                var count = await this.$store.dispatch('alerts/count', criteria)
                var results = await this.$store.dispatch('alerts/findWhere', criteria)

                resolve({ data: results.data, count: count.data.count }) // https://matanya.gitbook.io/vue-tables-2/server-table
              } catch (error) {
                console.log('Alerts History: ' + error)
                reject(error)
              }
            }, 800)
          })
        },
        columnsClasses: {
          id: 'col-xs-1',
          service_id: 'col-xs-1',
          create_date: 'col-xs-2',
          hit_name: 'col-xs-2',
          hit_status: 'col-xs-1',
          exposition: 'col-xs-2',
          // remarks: 'col-xs-3',
          action: 'col-xs-1'
        },
        sortable: ['id', 'service_id', 'create_date', 'hit_name', 'hit_status', 'exposition'],
        headings: {
          id: this.$t('alert.details.form.id.label'),
          service_id: this.$t('alert.details.form.service_id.label'),
          hit_name: this.$t('alert.details.form.hit_name.label'), // 'Company Name',
          hit_status: this.$t('alert.details.form.hit_status.label'), // 'Status',
          create_date: this.$t('alert.details.form.create_date.label'), // 'Date Created',
          updated_date: this.$t('alert.details.form.updated_date.label'), // 'Date Updated',
          exposition: this.$t('alert.details.form.exposition.label'),
          action: this.$t('listing.table.action.label')
        },
        // sortable: [],
        filterable: ['id', 'service_id', 'create_date', 'hit_name', 'hit_status', 'exposition'],
        filterByColumn: true,
        dateColumns: ['create_date'],
        // dateFormat: 'DD/MMM/YYYY',
        // datepickerOptions: {
        // 	// alwaysShowCalendars: true,
        // 	ranges: {
        // 		[this.$t('listing.table.datepicker.today')]: [moment(), moment()],
        // 		[this.$t('listing.table.datepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        // 		[this.$t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [moment().subtract(6, 'days'), moment()],
        // 		[this.$t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [moment().subtract(29, 'days'), moment()],
        // 		[this.$t('listing.table.datepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
        // 		[this.$t('listing.table.datepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        // 	}
        // },
        texts: {
          count: this.$t('listing.table.texts.count'),
          first: this.$t('listing.table.texts.first'),
          last: this.$t('listing.table.texts.last'),
          filter: this.$t('listing.table.texts.filter'),
          filterPlaceholder: this.$t('listing.table.texts.filterPlaceholder'),
          limit: this.$t('listing.table.texts.limit'),
          page: this.$t('listing.table.texts.page'),
          noResults: this.$t('listing.table.texts.noResults'),
          noRequest: this.$t('listing.table.texts.noRequest'),
          filterBy: this.$t('listing.table.texts.filterBy'),
          loading: this.$t('listing.table.texts.loading'),
          defaultOption: this.$t('listing.table.texts.defaultOption'),
          columns: this.$t('listing.table.texts.columns')
        },
        listColumns: {
          hit_status: [
            {
              id: 0,
              text: this.$t('alert.details.form.hit_status.options[0]')// 'Pending'
            },
            {
              id: 1,
              text: this.$t('alert.details.form.hit_status.options[1]') // 'True hit',
            },
            {
              id: 2,
              text: this.$t('alert.details.form.hit_status.options[2]') // 'False hit',
            }
          ]
        }
        // see the options API
      }
    }
  },
  data () {
    return {
      risk_loading: false,
      risk_level: this.$t('person.details.form.risk_level.options.Not In List'),
      risk_detail: 0,
      step: 0,
      requestLoading: false,
      name_form: {
        english: helper.getNameForm(1, 1),
        chinese: helper.getNameForm(2, 1),
        english_alias: helper.getNameForm(1, 2),
        chinese_alias: helper.getNameForm(2, 2)
      },
      // hkid_form: helper.getHKIDForm(),
      iden_form: helper.getIdenForm(),
      // passport_form: helper.getPassport`Form(),
      address_form: helper.getAddressForm(1),
      email_form: helper.getEmailForm(2),
      form: helper.initialPerson(),
      like: {
        name_form: {
          english: {
            options: [],
            is_show: false
          },
          chinese: {
            options: [],
            is_show: false
          },
          english_alias: {
            options: [],
            is_show: false
          },
          chinese_alias: {
            options: [],
            is_show: false
          }
        },
        iden_form: {
          options: [],
          is_show: false
        }
      },
      alerts_table: {
        requestTimeout: null,
        requestLoading: false,
        columns: ['id', 'service_id', 'create_date', 'hit_name', 'hit_status', 'exposition', 'action']
      },
      tab: '',
      dialog_alert_detail: false,
      dialog_alert_detail_record: null,
      name_similar_check_timeout: null,
      iden_similar_check_timeout: null,
      active_penal: [0]
    }
  },
  validations () {
    return {
      name_form: {
        english: {
          sur: {
            required: requiredIf(() => !this.name_form.english.other)
          },
          other: {
            required: requiredIf(() => !this.name_form.english.sur)
          },
          duplicate_local (name) {
            return new Promise((resolve) => {
              var duplicate = this.form.names.filter((_name) => {
                return !_name.is_deleted &&
										_name.other === name.other &&
										_name.sur === name.sur &&
										_name.lang === name.lang &&
										_name.name_type === name.name_type &&
										_name.uuid !== name.uuid
              })
              resolve(!(duplicate && duplicate.length > 0))
            })
          }
          // duplicate_db (name) {
          // 	return validateNameDuplicate('english', this.form.id, name)
          // }
        },
        chinese: {
          sur: {
            required: requiredIf(() => !this.name_form.chinese.other),
            chinese_words (surname) {
              if (surname === '') return true

              // return new Promise((resolve, reject) => {
              // 	resolve(/[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/ug.test(surname))
              // });
              return true
            }
          },
          other: {
            required: requiredIf(() => !this.name_form.chinese.sur),
            chinese_words (other_name) {
              if (other_name === '') return true

              // return new Promise((resolve, reject) => {
              // 	resolve(/[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/ug.test(other_name))
              // });
              return true
            }
          },
          duplicate_local (name) {
            return new Promise((resolve) => {
              var duplicate = this.form.names.filter((_name) => {
                return !_name.is_deleted &&
										_name.other === name.other &&
										_name.sur === name.sur &&
										_name.lang === name.lang &&
										_name.name_type === name.name_type &&
										_name.uuid !== name.uuid
              })
              resolve(!(duplicate && duplicate.length > 0))
            })
          }
          // duplicate_db (name) {
          // 	return validateNameDuplicate('chinese', this.form.id, name)
          // }
        },
        english_alias: {
          sur: {
            required: requiredIf(() => !this.name_form.english_alias.other)
          },
          other: {
            required: requiredIf(() => !this.name_form.english_alias.sur)
          },
          duplicate_local (name) {
            return new Promise((resolve) => {
              var duplicate = this.form.names.filter((_name) => {
                return !_name.is_deleted &&
										_name.other === name.other &&
										_name.sur === name.sur &&
										_name.lang === name.lang &&
										_name.name_type === name.name_type &&
										_name.uuid !== name.uuid
              })
              resolve(!(duplicate && duplicate.length > 0))
            })
          }
          // duplicate_db (name) {
          // 	return validateNameDuplicate('english_alias', this.form.id, name)
          // }
        },
        chinese_alias: {
          sur: {
            required: requiredIf(() => !this.name_form.chinese_alias.other),
            chinese_words (surname_alias) {
              if (surname_alias === '') return true

              return true
              // return new Promise((resolve, reject) => {
              // 	resolve(/[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/ug.test(surname_alias))
              // });
            }
          },
          other: {
            required: requiredIf(() => !this.name_form.chinese_alias.sur),
            chinese_words (other_alias) {
              if (other_alias === '') return true

              return true
              // return new Promise((resolve, reject) => {
              // 	resolve(/[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/ug.test(other_alias))
              // });
            }
          },
          duplicate_local (name) {
            return new Promise((resolve) => {
              var duplicate = this.form.names.filter((_name) => {
                return !_name.is_deleted &&
										_name.other === name.other &&
										_name.sur === name.sur &&
										_name.lang === name.lang &&
										_name.name_type === name.name_type &&
										_name.uuid !== name.uuid
              })
              resolve(!(duplicate && duplicate.length > 0))
            })
          }
          // duplicate_db (name) {
          // 	return validateNameDuplicate('chinese_alias', this.form.id, name)
          // }
        }
      },
      iden_form: {
        id_number: {
          required,
          iden_checksum (/* security_num */) {
            return true

            // if (security_num === '' || this.iden_form.expiry_date === '' || this.iden_form.type !== 2) {
            //  return true
            // }

            // return new Promise((resolve, reject) => {
            //  const FILLER = '<'
            //  const check = [7, 3, 1]

            //  let sum = 0
            //  var date = this.iden_form.expiry_date instanceof Date ? this.iden_form.expiry_date : new Date(this.iden_form.expiry_date)
            //  var day = String(date.getDate() || 0).padStart(2, '0')
            //  var month = String(date.getMonth() + 1 || 0).padStart(2, '0')
            //  var year = String(date.getFullYear() % 100 || 0).padStart(2, '0')
            //  var input = year + month + day

            //  for (let i = 0; i < input.length; i++) {
            //    let val = parseInt(input[i])

            //    if (Number.isNaN(val)) {
            //      if (input[i] == FILLER) {
            //        val = 0
            //      } else {
            //        val = input[i].toUpperCase().charCodeAt(0) - 55 // A starts as 10
            //      }
            //    }
            //    sum += val * check[i % 3]
            //  }

            //  return resolve(sum % 10 == security_num)
            // })
          },
          hkid_valid_format (hkid_num) {
            if (hkid_num === '' || this.iden_form.type !== 1) {
              return true
            }

            return new Promise((resolve) => {
              // var match = hkid_num.match(/^[A-Za-z]+\d{6}\(?\d{1}\)?$/);
              // resolve(match && match.length)
              var match = hkid_num.toUpperCase().match(/([A-Z]{1,2})(\d)(\d)(\d)(\d)(\d)(\d)\(?([\dA])\)?/)
              resolve(match && match.length)
            })
          },
          hkid_checksum (id_number) {
            if (id_number === '' || this.iden_form.type !== 1) {
              return true
            }
            return new Promise((resolve) => {
              var hkids = id_number.toUpperCase().match(/([A-Z]{1,2})(\d)(\d)(\d)(\d)(\d)(\d)\(?([\dA])\)?/)
              var valid
              if (hkids) {
                var sum = 0
                sum = sum + 2 * hkids[7]
                sum = sum + 3 * hkids[6]
                sum = sum + 4 * hkids[5]
                sum = sum + 5 * hkids[4]
                sum = sum + 6 * hkids[3]
                sum = sum + 7 * hkids[2]
                sum = sum + 8 * (hkids[1].charCodeAt(hkids[1].length === 2 ? 1 : 0) - 55)
                sum = sum + 9 * (hkids[1].length === 2 ? (hkids[1].charCodeAt(0) - 55) : 58)
                var checkdigit = sum % 11
                valid = '0A987654321'.indexOf(hkids[8]) === checkdigit
              } else {
                valid = false
              }

              console.log(hkids, sum, checkdigit, valid)
              resolve(valid)
            })
          }
        },
        id_number_security_num: {
          required: requiredIf(() => this.iden_form.type === 2)
        },
        expiry_date: {
          required: requiredIf(() => this.iden_form.type === 2)
        },
        expiry_date_security_num: {
          required: requiredIf(() => this.iden_form.type === 2)
        },
        country: {
          required: requiredIf(() => this.iden_form.type === 2 || this.iden_form.type === 0)
        },
        duplicate_local (iden_form) {
          return new Promise((resolve) => {
            var duplicate = this.form.idens.filter((_iden) => {
              return !_iden.is_deleted &&
									_iden.type === iden_form.type &&
									_iden.id_number === iden_form.id_number &&
									_iden.id_number_security_num === iden_form.id_number_security_num &&
									(_iden.expiry_date || 0) === (iden_form.expiry_date || 0) &&
									_iden.expiry_date_security_num === iden_form.expiry_date_security_num &&
									_iden.country === iden_form.country &&
									(_iden.effect_date || 0) === (iden_form.effect_date || 0) &&
									_iden.uuid !== iden_form.uuid
            })
            resolve(!(duplicate && duplicate.length > 0))
          })
        },
        duplicate_db (iden_form) {
          if (iden_form.id_number === '') { return true }

          return new Promise((resolve) => {
            // console.log(id_number, this.iden_form.type, this.form.id);
            this.$store.dispatch('natural_person/similarIdentity', {
              person_id: this.form.id,
              id_type: iden_form.type,
              id_number: iden_form.id_number
            })
              .then((results) => {
                if (results.status === 200) {
                  // console.log(results);
                  // this.like.iden_form.options = results.data;
                  return resolve(results.data.filter(x => x.person !== null).length === 0)
                }
                resolve(true)
              }).catch((unusedError) => {
                resolve(false)
              })
          })
        }
        // effect_date: null,
        // create_date: null,
        // updated_date: null,
        // is_deleted: false,
      },
      address_form: {
        address_1: {
          required
        },
        // address_2: {
        // 	required
        // },
        // address_3: {
        // 	required
        // },
        address_4: {
          required
        }
      },
      email_form: {
        email: {
          required,
          email
        }
      },
      form: {
        names: {
          at_least_one_name (names) {
            return (names || []).filter((name) => { return !name.is_deleted }).length !== 0
          }
        },
        bank_infos: {
          $each: {
            acc_number: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    uuidv4,
    ...helper,
    ...mapActions({
      updateAlertInfo: 'scan/updateAlertInfo'
    }),
    isTabActive (tab_ref_name) {
      if (!this.is_edit_mode) {
        return true
      }
      return this.$refs[tab_ref_name] ? this.$refs[tab_ref_name].isActive : false
    },
    saveRow (form_field, row_for_save, callback_validation, callback_after_save) {
      console.log(form_field, row_for_save, callback_validation, callback_after_save)
      // Callback
      if (callback_validation && typeof callback_validation === 'function') {
        if (callback_validation(form_field, row_for_save)) {
          this.validateAsync()
          return
        }
      }
      // Save Row
      if (row_for_save.uuid) {
        // Update Row
        var update_index = form_field.findIndex((row) => {
          return row.uuid === row_for_save.uuid
        })
        if (update_index !== -1) {
          form_field[update_index] = row_for_save
        }
      } else {
        // Insert Row
        row_for_save.uuid = uuidv4()
        form_field.push(row_for_save)
      }
      // Callback
      if (callback_after_save && typeof callback_after_save === 'function') {
        callback_after_save(form_field, row_for_save)
      }
    },
    removeRow (form_field, row_for_remove, callback_after_remove) {
      // Remove Row
      // var remove_index = form_field.findIndex((row) => {
      // 		return row.uuid == row_for_remove.uuid;
      // 	});
      // if (remove_index != -1) {
      // 	form_field.splice(remove_index, 1);
      // }
      row_for_remove.is_deleted = true
      // Callback
      if (callback_after_remove && typeof callback_after_remove === 'function') {
        callback_after_remove(form_field, row_for_remove)
      }
    },
    loadPersonDetail () {
      this.$v.$reset()
      this.form = this.initialPerson()
      if (this.personId) {
        this.requestLoading = true
        this.$store.dispatch('natural_person/findOne', this.personId)
          .then((results) => {
            if (results.status === 200) {
              // this.form = results.data
              Object.assign(this.form, results.data)
              this.$emit('onLoad', this.form)
              this.$notify({
                type: 'success',
                title: this.$t('person.details.msg.load.title'), // 'Load Natural Person Detail',
                text: this.$t('person.details.msg.load.success') // 'Successfully Loaded',
              })
              this.prepareFormData()
            } else {
              this.$notify({
                type: 'error',
                title: this.$t('person.details.msg.load.title'), // 'Load Natural Person Detail',
                text: this.$t('person.details.msg.load.success') // 'Load Detail Failed',
              })
            }
            this.requestLoading = false
          })
      }
    },
    checkAllDateFormat () {
      let correctDate = true
      switch (this.tab !== '' ? this.tab : this.step) {
        case 0:
          correctDate = (this.$refs.address_effect_date || { correctDate: true }).correctDate
          break
        case 1:
          correctDate = (this.$refs.address_effect_date || { correctDate: true }).correctDate &&
            this.$refs.iden_effect_date.correctDate &&
            ([0, 2].indexOf(this.iden_form.type) !== -1 ? this.$refs.iden_expiry_date.correctDate : true)
          break
        default:
          correctDate = (this.$refs.address_effect_date || { correctDate: true }).correctDate &&
            (this.$refs.iden_effect_date || { correctDate: true }).correctDate &&
            ([0, 2].indexOf(this.iden_form.type) !== -1 ? (this.$refs.iden_expiry_date || { correctDate: true }).correctDate : true) &&
            // (this.$refs.address_effect_date || { correctDate: true }).correctDate &&
            (this.$refs.email_effect_date || { correctDate: true }).correctDate &&
            (this.$refs.dob || { correctDate: true }).correctDate
          break
      }
      return correctDate
    },
    async onComplete () {
      // const test = true
      // console.log('on complete', !this.$slots.roles[0].context.checkAllDateFormat())
      this.$v.form.$touch()
      if (this.$v.form.$invalid ||
        (this.$refs.dob !== undefined ? !this.$refs.dob.correctDate : false) ||
        (this.$slots.roles && !this.$slots.roles[0].context.checkAllDateFormat()) ||
        !this.checkAllDateFormat()
      ) {
        return this.$notify({
          type: 'error',
          title: this.$t('person.details.msg.valid.title'), // 'Validate Person Detail',
          text: this.$t('person.details.msg.valid.error') // 'Person detail is invalid'
        })
      } else {
        this.$notify({
          type: 'success',
          title: this.$t('person.details.msg.valid.title'), // 'Validate Person Detail',
          text: this.$t('person.details.msg.valid.success') // 'Person detail is valid'
        })
      }

      return this.$store.dispatch('natural_person/save', this.form).then(results => {
        if (results.status === 200) {
          this.form = results.data
          this.prepareFormData()
          // this.VB2form(results.data);
          this.$notify({
            type: 'success',
            title: this.$t('person.details.msg.save.title'), // 'Save Natural Person',
            text: this.$t('person.details.msg.save.success') // 'Successfully Saved',
          })
          this.$emit('onSave', this.form)
        } else {
          this.$notify({
            type: 'error',
            title: this.$t('person.details.msg.save.title'), // 'Save Natural Person',
            text: this.$t('person.details.msg.save.error') // 'Save Failed',
          })
        }
      }, (error) => {
        this.$notify({
          type: 'error',
          title: this.$t('person.details.msg.save.title'),
          text: (
            error.response && error.response.data && error.response.data.code === 'E_UNIQUE'
              ? this.$t('person.details.msg.save.error_unique')
              : this.$t('person.details.msg.save.exception')
          )
        })
      })
    },
    prepareFormData () {
      // Reset All Form
      this.name_form.english = this.getNameForm(1, 1)
      this.name_form.chinese = this.getNameForm(2, 1)
      this.name_form.english_alias = this.getNameForm(1, 2)
      this.name_form.chinese_alias = this.getNameForm(2, 2)
      // this.hkid_form = this.getHKIDForm();
      this.iden_form = this.getIdenForm()
      this.address_form = this.getAddressForm(1)
      this.email_form = this.getEmailForm(2)

      // Reset All Validation
      this.$v.name_form.$reset()
      // this.$v.hkid_form.$reset();
      this.$v.iden_form.$reset()
      this.$v.address_form.$reset()
      this.$v.email_form.$reset()

      // Reset All Similar Options
      this.like.name_form.english.options.splice(0)
      this.like.name_form.english.is_show = false
      this.like.name_form.english_alias.options.splice(0)
      this.like.name_form.english_alias.is_show = false
      this.like.name_form.chinese.options.splice(0)
      this.like.name_form.chinese.is_show = false
      this.like.name_form.chinese_alias.options.splice(0)
      this.like.name_form.chinese_alias.is_show = false
      this.like.iden_form.options.splice(0)
      this.like.iden_form.is_show = false

      // Setup Temp UUID
      this.form.names.map((name) => {
        name.uuid = uuidv4()
        return name
      })

      this.form.emails.map((email) => {
        email.uuid = uuidv4()
        return email
      })

      this.form.addresses.map((address) => {
        address.uuid = uuidv4()
        return address
      })

      this.form.hkids.map((hkid) => {
        hkid.uuid = uuidv4()
        return hkid
      })

      this.form.idens.map((iden) => {
        iden.uuid = uuidv4()
        return iden
      })
    },
    onChange (fromStep, toStep) {
      // console.log('onChangeStep', fromStep, toStep);
      this.step = toStep
    },
    validateAsync () {
      this.$v.email_form.$reset()
      this.$v.name_form.$reset()
      this.$v.iden_form.$reset()
      this.$v.address_form.$reset()
      return new Promise((resolve) => {
        this.$v.form.$touch()
        resolve(!this.$v.form.$invalid && this.checkAllDateFormat())
      })
    },
    popup_alert_detail (row) {
      this.dialog_alert_detail_record = row
      this.dialog_alert_detail = true
    },
    switchPersonDetails (person_id) {
      console.log('switchToID', person_id)
      this.$emit('switchToID', person_id)
    },
    toggleSimilarDropdown (field, is_show) {
      clearTimeout(field.timeout)
      field.timeout = setTimeout(() => {
        field.is_show = is_show
      }, 100)
    },
    // pobChanged (pob) {
    // 	// alert(pob)
    // 	this.risk_loading = true;
    // 	var countrys = this.countries.filter((country) => country.country == pob);

    // 	if (countrys.length) {
    // 		// alert(countrys[0].abbreviation);
    // 		// alert( this.$store.state.risk.records.length)
    // 		var risks = this.$store.state.risk.records.filter((risk) => {
    // 			return risk.ISO2 == countrys[0].abbreviation
    // 		});
    // 		console.log(risks)
    // 		// alert(risks.length)
    // 		if (risks.length) {
    // 			this.risk_loading = false;
    // 			this.risk_level = risks[0].RiskLevel
    // 			this.risk_detail = risks[0].RiskScore
    // 			return pob;
    // 		}
    // 	} else {
    // 		// alert('not in')
    // 	}

    // 	this.risk_level = 'Not In List';
    // 	this.risk_detail = 0;
    // 	this.risk_loading = false;
    // },
    name_similar_check (form_name, name) {
      clearTimeout(this.name_similar_check_timeout)
      this.name_similar_check_timeout = null

      if (!name.sur && !name.other) { return true }

      this.name_similar_check_timeout = setTimeout(() => {
        this.$store.dispatch('natural_person/similarName', {
          person_id: this.form.id,
          lang: name.lang,
          name_type: name.name_type,
          sur: name.sur,
          other: name.other
        })
          .then((results) => {
            if (results.data) { this.like.name_form[form_name].options = results.data.filter(x => x.person != null) }
          }).finally(() => {
            this.name_similar_check_timeout = null
          })
      }, 500)
    },
    iden_similar_check (id_number) {
      clearTimeout(this.iden_similar_check_timeout)
      this.iden_similar_check_timeout = null

      if (id_number === '') { return true }

      this.iden_similar_check_timeout = setTimeout(() => {
        this.$store.dispatch('natural_person/similarIdentity', { person_id: this.form.id, id_type: this.iden_form.type, id_number: id_number })
          .then((results) => {
            if (results.data) { this.like.iden_form.options = results.data.filter(x => x.parent != null) }
          }).catch((unusedError) => {

          }).finally(() => {
            this.iden_similar_check_timeout = null
          })
      }, 500)
    }
  },
  mounted () {
    // console.log('person2', this);
    this.loadPersonDetail()
    this.$store.dispatch('risk/lists')
  }
}

</script>
<style type="text/css">
	.btn .badge {font-size: 1rem !important;}
	.tabs-component-tab-a .badge {
		position: absolute;
		top: -7px;
		right: -7px;
		font-size: 1rem;
		font-weight: 400;
	}
</style>
