import * as JSDiff from 'diff'
import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))
/* global moment */

// console.log(RBAClient);
// localStorage

// initial state
const state = {
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: ['id', 'service_id', 'create_date', 'hit_name', 'hit_identity', 'hit_status', /* 'exposition',/*'remarks', */'action'],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      orderBy: {
        ascending: true,
        column: null
      },
      requestFunction (request) {
        // console.log("Alerts History", request);
        clearTimeout(this.requestTimeout)
        return new Promise((resolve, reject) => {
          this.requestTimeout = setTimeout(async () => {
            try {
              var criteria = {
                // params: {
                // where: request.query, // Todo: parse where query
                where: (((query) => {
                  var _where = {}

                  if (query.hit_name) _where.hit_name = { contains: query.hit_name }
                  if (query.hit_identity) _where.hit_identity = { contains: query.hit_identity }
                  if (query.service_id) _where.service_id = { contains: query.service_id }
                  if (query.id) _where.id = query.id
                  if (query.exposition) _where.exposition = { contains: query.exposition }
                  if (query.remarks) _where.remarks = { contains: query.remarks }
                  if (query.hit_status != null) _where.hit_status = query.hit_status
                  if (query.create_date) { // _where.create_date = query.create_date
                    _where.and = [
                      { create_date: { '>': new Date(query.create_date.start).getTime() } },
                      { create_date: { '<': new Date(query.create_date.end).getTime() } }
                    ]
                  }

                  return _where
                })(request.query)),
                sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                limit: request.limit,
                skip: request.limit * (request.page - 1)
                // propulate: ''
                // }
              }

              Promise.all([
                client.alert.count(criteria),
                client.alert.findWhere(criteria)
              ])
                .then(([{ data: { count } }, { data }]) => {
                  resolve({ data, count })
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (error) {
              console.log('Alerts History: ' + error)
              reject(error)
            }
          }, 800)
        })
      },
      columnsClasses: {
        id: 'col-xs-1',
        service_id: 'col-xs-1',
        create_date: 'col-xs-1',
        hit_name: 'col-xs-2',
        hit_identity: 'col-xs-2',
        hit_status: 'col-xs-1',
        exposition: 'col-xs-3',
        // remarks: 'col-xs-3',
        action: 'col-xs-1'
      },
      // headings: {
      //     service_id:'Alert ID',
      //     create_date:'Scan At',
      //     hit_name:'Scan Name',
      //     hit_status: 'Hit Status',
      //     // hitcompany:'Hit Company',
      //     // hitcompanystat:'Hit Compay Status',
      //     exposition:'Exposition',
      //     // remarks:'Remarks',
      // },
      sortable: ['id', 'service_id', 'create_date', 'hit_name', 'hit_identity', 'hit_status', 'exposition'/*, 'remarks' */],
      filterable: ['id', 'service_id', 'create_date', 'hit_name', 'hit_identity', 'hit_status', 'exposition'],
      filterByColumn: true,
      dateColumns: ['create_date'],
      dateFormat: 'DD/MMM/YYYY'
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     'Today': [moment(), moment()],
      //     'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //     'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //     'This Month': [moment().startOf('month'), moment().endOf('month')],
      //     'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // },
      // listColumns: {
      //   hit_status: [
      //     {
      //       id: 0,
      //       text: 'Pending'
      //     },
      //     {
      //       id: 1,
      //       text: 'True hit',
      //       // hide:true
      //     },
      //     {
      //       id: 2,
      //       text: 'False hit'
      //     },
      //   ]
      // }
      // // see the options API https://matanya.gitbook.io/vue-tables-2/options-api
    }

  },
  audit: {
    requestLoading: false,
    // paging
    total: 0,
    page: 0,
    limit: 5,
    is_more: false,
    // records
    alert: null,
    trails: [],
    changes: []
  }
}

// getters
const getters = {
  listing_options: (state, getters, rootState, { i18n }) => {
    const { initFilters, initialPage, perPage, orderBy, requestFunction, columnsClasses, sortable, filterable, filterByColumn, dateColumns, dateFormat } = state.listing.options

    return {
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction,
      columnsClasses,
      sortable,
      headings: {
        id: i18n.t('alert.details.form.id.label'),
        service_id: i18n.t('alert.details.form.service_id.label'),
        hit_name: i18n.t('alert.details.form.hit_name.label'), // 'Company Name',
        hit_status: i18n.t('alert.details.form.hit_status.label'), // 'Status',
        hit_identity: i18n.t('alert.details.form.hit_identity.label'),
        create_date: i18n.t('alert.details.form.create_date.label'), // 'Date Created',
        updated_date: i18n.t('alert.details.form.updated_date.label'), // 'Date Updated',
        exposition: i18n.t('alert.details.form.exposition.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      dateFormat,
      dateColumns,
      // datepickerOptions,
      // datepickerOptions: {
      //   // alwaysShowCalendars: true,
      //   ranges: {
      //     [i18n.t('listing.table.datepicker.today')]: [moment(), moment()],
      //     [i18n.t('listing.table.datepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 7 })]: [moment().subtract(6, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.last_n_days', { num_of_days: 30 })]: [moment().subtract(29, 'days'), moment()],
      //     [i18n.t('listing.table.datepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
      //     [i18n.t('listing.table.datepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      //   }
      // },
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      },
      listColumns: {
        hit_status: [
          {
            id: 0,
            text: i18n.t('alert.details.form.hit_status.options')[0]// 'Pending'
          },
          {
            id: 1,
            text: i18n.t('alert.details.form.hit_status.options')[1] // 'True hit',
          },
          {
            id: 2,
            text: i18n.t('alert.details.form.hit_status.options')[2] // 'False hit',
          }
        ]
      }
    }
  },
  hit_status_options: (state, getters, rootState, { i18n }) => {
    return [
      { value: 0, text: i18n.t('alert.details.form.hit_status.options[0]') },
      { value: 1, text: i18n.t('alert.details.form.hit_status.options[1]') },
      { value: 2, text: i18n.t('alert.details.form.hit_status.options[2]') }
    ]
    // return [
    //   'Pending', // 0
    //   'True hit', // 1
    //   'False hit', // 2
    // ];
  },
  suggested_remarks: (state, getters, rootState, { i18n }) => {
    return i18n.t('alert.details.form.remarks.options')
    // return [
    //   'Enhanced CDD has been completed with AML/CFT risks assessed to be acceptable.  Allows to be on board with high risk rating and recording the fact in application form if required.',
    //   'Enhanced CDD has been completed with AML/CFT risks assessed to be UNACCEPTABLE.  No service would be provided.',
    //   'Suspicious transaction report has been prepared and filed to the authority.',
    // ]
  },
  exposition_options: (state, getters, rootState, { i18n }) => (hit_status) => {
    if (parseInt(hit_status) === 1) {
      return [
        { value: 0, text: i18n.t('alert.details.form.exposition.options_true[0]') },
        { value: 1, text: i18n.t('alert.details.form.exposition.options_true[1]') },
        { value: 2, text: i18n.t('alert.details.form.exposition.options_true[2]') },
        { value: 3, text: i18n.t('alert.details.form.exposition.options_true[3]') },
        { value: 4, text: i18n.t('alert.details.form.exposition.options_true[4]') },
        { value: 5, text: i18n.t('alert.details.form.exposition.options_true[5]') }
      ]
    } else if (parseInt(hit_status) === 2) {
      return [
        { value: 0, text: i18n.t('alert.details.form.exposition.options_false[0]') },
        { value: 1, text: i18n.t('alert.details.form.exposition.options_false[1]') }
      ]
    }
    return []
  }

}

// actions
const actions = {
  findOne (unusedContext, payload) {
    return client.alert.findOne(payload)
  },
  findWhere (unusedContext, criteria) {
    return client.alert.findWhere(criteria)
  },
  count (unusedContext, criteria) {
    return client.alert.count(criteria)
  },
  findAudits ({ commit, state, getters }, payload) {
    commit('RESET_AUDIT')
    commit('SET_AUDIT', { requestLoading: true })
    // var criteria =

    Promise.all([
      client.audit_traces.count({
        where: {
          table: 'alerts_v2',
          parent: payload.id
        }
      }),
      client.audit_traces.findWhere({
        where: {
          table: 'alerts_v2',
          parent: payload.id
        },
        sort: 'create_date DESC',
        skip: 0,
        limit: state.audit.limit
      })
    ]).then(([{ status: status0, data: { count } }, { status: status1, data }]) => {
      if (status0 !== 200 || status1 !== 200) { return commit('SET_AUDIT', { requestLoading: false }) }

      // console.log(status0, count, status1, data);

      commit('SET_AUDIT', {
        requestLoading: false,
        alert: payload,
        total: count,
        page: 0,
        trails: data, // data.map(x => x.archive_object.hit_status_text = i18n.t('alert.details.form.hit_status.options')[x.archive_object.hit_status]),
        hit_status_options: getters.hit_status_options,
        exposition_options: getters.exposition_options
      })
    }).catch((unusedError) => {
      commit('SET_AUDIT', { requestLoading: false })
    })
  },
  moreAudits ({ state, commit, getters }) {
    console.log('more', state.audit.is_more, state.audit.requestLoading)
    if (state.audit.is_more && !state.audit.requestLoading) {
      commit('SET_AUDIT', { requestLoading: true })
      client.audit_traces.findWhere({
        where: {
          table: 'alerts_v2',
          parent: state.audit.alert.id
        },
        sort: 'create_date DESC',
        skip: (state.audit.page + 1) * state.audit.limit,
        limit: state.audit.limit
      })
        .then(({ status, data }) => {
          if (status !== 200) { return commit('SET_AUDIT', { requestLoading: false }) }

          commit('SET_AUDIT', {
            requestLoading: false,
            page: state.audit.page + 1,
            trails: data,
            hit_status_options: getters.hit_status_options,
            exposition_options: getters.exposition_options
          })
        })
        .catch((unusedError) => {
          commit('SET_AUDIT', { requestLoading: false })
        })
    }
  },
  save (context, payload) {
    return new Promise((resolve, reject) => {
      try {
        payload.person = payload.person && typeof payload.person === 'object' ? payload.person.id : payload.person
        payload.company = payload.company && typeof payload.company === 'object' ? payload.company.id : payload.company
        payload.attachments = payload.attachments.map(attachment => attachment.id)
        if (payload.id) { client.alert.update(payload.id, payload).then(resolve).catch(reject) } else { client.alert.create(payload).then(resolve).catch(reject) }
      } catch (error) {
        reject(error)
      }
    })
  }
}

// mutations
const mutations = {
  'alertsTable/SET_DATA' (state, data) {
    console.log('alertsTable/SET_DATA', data)
  },
  'alertsTable/PAGINATION' (state, data) {
    console.log('alertsTable/PAGINATION', data)
  },
  'alertsTable/FILTER' (state, data) {
    console.log('alertsTable/FILTER', data)
  },
  'alertsTable/SORTED' (state, data) {
    console.log('alertsTable/SORTED', data)
  },
  'alertsTable/LOADING' (state, payload) {
    console.log('alertsTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.create_date) {
      state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start)
      state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    state.listing.requestLoading = true
  },
  'alertsTable/LOADED' (state, data) {
    console.log('alertsTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'alertsTable/LIMIT' (state, data) {
    console.log('alertsTable/LIMIT', data)
  },
  'alertsTable/ERROR' (state, data) {
    console.log('alertsTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'alertsTable/ROW_CLICK' (state, data) {
    console.log('alertsTable/ROW_CLICK', data)
  },
  RESET_AUDIT (state) {
    state.audit.requestLoading = false
    state.audit.is_more = false
    state.audit.alert = null
    state.audit.total = 0
    state.audit.page = 0
    state.audit.trails.splice(0)
    state.audit.changes.splice(0)
  },
  SET_AUDIT (state, { requestLoading, alert, total, page, trails, hit_status_options, exposition_options }) {
    console.log(requestLoading, alert, total, page, trails)
    state.audit.requestLoading = requestLoading === undefined ? state.audit.requestLoading : requestLoading
    // paging
    state.audit.total = total || state.audit.total
    state.audit.page = page || state.audit.page
    state.audit.is_more = (state.audit.page + 1) * state.audit.limit < state.audit.total
    // records
    state.audit.alert = alert || state.audit.alert
    state.audit.trails = state.audit.trails.concat(trails || [])
    state.audit.changes.splice(0)
    state.audit.trails.map(x => x.archive_object).reduce((a, b, index) => {
      var hit_status_to_word = alert => (hit_status_options.find(x => x.value === alert.hit_status) || { text: '' }).text
      var expoistion_to_word = alert => (exposition_options(alert.hit_status).find(x => String(x.value) === String(alert.exposition)) || { text: '' }).text

      state.audit.changes.push({
        id: index,
        datetime: a.updated_date,
        a,
        b,
        hit_status: JSDiff.diffWordsWithSpace(hit_status_to_word(b), hit_status_to_word(a)),
        exposition: JSDiff.diffWordsWithSpace(expoistion_to_word(b), expoistion_to_word(a)),
        remarks: JSDiff.diffTrimmedLines(b.remarks, a.remarks)
      })

      return b
    }, state.audit.alert)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
