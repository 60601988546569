<template>
  <v-dialog width="800">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        class="ma-1"
        v-on="on"
        :loading="edit_dialog.loading"
        :data-cy="`edit_bank_info_btn_${dataCy}`"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card :loading="edit_dialog.loading">
        <v-card-title
          data-cy="bankinfo_edit_title"
        >
          {{ $t('company.details.form_bankinfo.buttons.add') }}
          <v-spacer />
          <v-btn
            @click="() => {
              if ($refs.acc_open_date.correctDate && $refs.acc_close_date.correctDate) {
                edit_dialog.loading = true
                $store.dispatch('bankinfo/update', { ...edit_dialog.bank_info, capacity_type: parentType == 'company' ? 1 : 2, capacity_id: parentId }).then(() => {

                }).catch(() => {

                }).finally(() => {
                  edit_dialog.loading = false;
                  dialog.value = false;
                  $emit('updated')
                })
              }
            }"
            outlined
            color="primary"
          >
            {{ $t('company.details.buttons.save') }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="edit_dialog.bank_info.acc_bank"
                :label="$t('company.details.form_bankinfo.acc_bank.label')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="edit_dialog.bank_info.acc_type"
                :label="$t('company.details.form_bankinfo.acc_type.label')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="edit_dialog.bank_info.acc_number"
                :label="$t('company.details.form_bankinfo.acc_number.label')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="edit_dialog.bank_info.acc_currency"
                :label="$t('company.details.form_bankinfo.acc_currency.label')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <InputDate
                v-model="edit_dialog.bank_info.acc_open_date"
                :label="$t('company.details.form_bankinfo.acc_open_date.label')"
                ref="acc_open_date"
              />
              <InputDate
                v-model="edit_dialog.bank_info.acc_close_date"
                :label="$t('company.details.form_bankinfo.acc_close_date.label')"
                ref="acc_close_date"
              />
            </v-col>
            <v-col cols="6">
              <v-textarea
                :label="$t('company.details.form_bankinfo.acc_remarks.label')"
                counter
                v-model="edit_dialog.bank_info.acc_remarks"
                auto-grow
                rows="1"
                row-height="15"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import InputDate from '@/components/forms/InputDate'
import * as helper from '@/store/helper'

export default {
  props: {
    bankInfo: {
      type: Object,
      default: null
    },
    parentId: {
      type: [String, Number],
      required: true
    },
    parentType: {
      type: String,
      required: true,
      default: 'company',
      validator: function (value) {
        // The value must match one of these strings
        return ['company', 'person'].indexOf(value) !== -1
      }
    },
    dataCy: {
      type: String,
      default: ''
    }
  },
  watch: {
    bankInfo (new_value) {
      if (new_value) { Object.assign(this.edit_dialog.bank_info, JSON.parse(JSON.stringify(new_value))) }
    }
  },
  components: {
    InputDate
  },
  data () {
    return {
      edit_dialog: {
        loading: false,
        bank_info: this.getBankInfoForm() // JSON.parse(JSON.stringify(props.row))
      }
    }
  },
  methods: {
    ...helper
  },
  mounted () {
    Object.assign(this.edit_dialog.bank_info, JSON.parse(JSON.stringify(this.bankInfo)))
  }
}
</script>
