import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))
/* global moment */

var requestTimeout = null

// initial state
const state = {
  listing: {
    requestTimeout: null,
    requestLoading: false,
    columns: [
      'file_name',
      'description',
      'create_date',
      'file_mime',
      'action'
    ],
    options: {
      initFilters: [],
      initialPage: 1,
      perPage: 10,
      // 'orderBy.ascending': 1,
      // 'orderBy.column': '',
      orderBy: {
        ascending: true,
        column: null
      },
      columnsClasses: {
        file_name: 'col-xs-2',
        description: 'col-xs-4',
        create_date: 'col-xs-1',
        file_mime: 'col-xs-2',
        action: 'col-xs-1'
      },
      sortable: [
        'file_name',
        'description',
        'create_date',
        'file_mime'
      ],
      filterable: [
        'file_name',
        'description',
        'create_date',
        'file_mime'
      ],
      filterByColumn: true,
      dateColumns: ['create_date']
    }
  }
}

// getters
const getters = {
  options (state, unusedGetters, unusedRootState, rootGetters) {
    const { i18n } = rootGetters
    const { initFilters, initialPage, perPage, orderBy, columnsClasses, sortable, filterable, filterByColumn, dateColumns } = state.listing.options

    return ({ company_id, person_id }) => ({
      initFilters,
      initialPage,
      perPage,
      orderBy,
      requestFunction (request) {
        clearTimeout(requestTimeout)
        return new Promise((resolve, reject) => {
          requestTimeout = setTimeout(async () => {
            try {
              var criteria = {
                // params: {
                where: ((query) => {
                  var _where = { }

                  if (query.file_name) _where.file_name = { contains: query.file_name }
                  if (query.description) _where.description = { contains: query.description }
                  if (query.file_mime) _where.file_mime = { contains: query.file_mime }
                  if (query.create_date) {
                    _where.and = [
                      { create_date: { '>': new Date(query.create_date.start).getTime() } },
                      { create_date: { '<': new Date(query.create_date.end).getTime() } }
                    ]
                  }

                  return _where
                })(request.query),
                sort: request.orderBy ? `${request.orderBy} ${request.ascending === 1 ? 'ASC' : 'DESC'}` : 'create_date DESC', // Todo: parse sort query
                limit: request.limit,
                skip: request.limit * (request.page - 1)
                // }
              }

              Promise.all([
                (company_id ? client.corporate.attachment.count(company_id, criteria) : (person_id ? client.individual.attachment.count(person_id, criteria) : client.attachment.count(criteria))),
                (company_id ? client.corporate.attachment.findWhere(company_id, criteria) : (person_id ? client.individual.attachment.findWhere(person_id, criteria) : client.attachment.findWhere(criteria)))
              ])
                .then(([{ data: { count } }, { data }]) => {
                  resolve({ data, count })
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (error) {
              reject(error)
            }
          }, 1500)
        }).catch((e) => {
          this.dispatch('error', e)
        })
      },
      columnsClasses,
      sortable,
      headings: {
        file_name: i18n.t('attachment.listing.table.file_name.label'),
        description: i18n.t('attachment.listing.table.description.label'),
        create_date: i18n.t('attachment.listing.table.create_date.label'),
        file_mime: i18n.t('attachment.listing.table.file_mime.label'),
        action: i18n.t('listing.table.action.label')
      },
      filterable,
      filterByColumn,
      dateColumns,
      texts: {
        count: i18n.t('listing.table.texts.count'),
        first: i18n.t('listing.table.texts.first'),
        last: i18n.t('listing.table.texts.last'),
        filter: i18n.t('listing.table.texts.filter'),
        filterPlaceholder: i18n.t('listing.table.texts.filterPlaceholder'),
        limit: i18n.t('listing.table.texts.limit'),
        page: i18n.t('listing.table.texts.page'),
        noResults: i18n.t('listing.table.texts.noResults'),
        noRequest: i18n.t('listing.table.texts.noRequest'),
        filterBy: i18n.t('listing.table.texts.filterBy'),
        loading: i18n.t('listing.table.texts.loading'),
        defaultOption: i18n.t('listing.table.texts.defaultOption'),
        columns: i18n.t('listing.table.texts.columns')
      },
      listColumns: {}
    })
  }
}

// actions
const actions = {
  upload (unusedContext, { file, description, company_ids, person_ids, alert_ids }) {
    if (!file) return Promise.reject(new Error('file is required'))

    return client.attachment.upload({
      file,
      description: description || '',
      company_ids: company_ids || undefined,
      person_ids: person_ids || undefined,
      alert_ids: alert_ids || undefined
    })
  }
}

// mutations
const mutations = {
  'attachmentTable/SET_DATA' (state, data) {
    console.log('attachmentTable/SET_DATA', data)
  },
  'attachmentTable/PAGINATION' (state, data) {
    console.log('attachmentTable/PAGINATION', data)
  },
  'attachmentTable/FILTER' (state, data) {
    console.log('attachmentTable/FILTER', data)
  },
  'attachmentTable/SORTED' (state, data) {
    console.log('attachmentTable/SORTED', data)
  },
  'attachmentTable/LOADING' (state, payload) {
    console.log('attachmentTable/LOADING', payload)
    state.listing.options.initFilters = payload.query
    if (state.listing.options.initFilters.create_date) {
      state.listing.options.initFilters.create_date.start = moment(state.listing.options.initFilters.create_date.start)
      state.listing.options.initFilters.create_date.end = moment(state.listing.options.initFilters.create_date.end)
    }
    state.listing.options.initialPage = payload.page
    state.listing.options.perPage = payload.limit
    state.listing.options.orderBy.ascending = payload.ascending
    state.listing.options.orderBy.column = payload.orderBy
    state.listing.requestLoading = true
  },
  'attachmentTable/LOADED' (state, data) {
    console.log('attachmentTable/LOADED', data)
    state.listing.requestLoading = false
  },
  'attachmentTable/LIMIT' (state, data) {
    console.log('attachmentTable/LIMIT', data)
  },
  'attachmentTable/ERROR' (state, data) {
    console.log('attachmentTable/ERROR', data)
    state.listing.requestLoading = false
  },
  'attachmentTable/ROW_CLICK' (state, data) {
    console.log('attachmentTable/ROW_CLICK', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
