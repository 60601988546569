import VueI18n from 'vue-i18n'
import en from '@/langs/en'

export default (Vue, unusedStore) => {
  Vue.use(VueI18n)

  // ISO 639-1 Language Codes: https://www.w3schools.com/tags/ref_language_codes.asp
  const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: {
      en // set locale messages
    }
  })

  const loadedLanguages = ['en'] // our default language that is preloaded

  function setI18nLanguage (lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    localStorage.lang = lang
    try {
      // try to trigger components recalculation
      window.dispatchEvent(new Event('resize'))
    } catch (unusedError) {

    }
    return lang
  }

  function loadLanguageAsync (lang) {
    // If the same language
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@/langs/${lang}.js`).then(
      messages => {
        i18n.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      }
    )
  }

  // console.log('loadLanguageAsync', loadLanguageAsync);

  Vue.mixin({
    methods: {
      loadLanguageAsync
    }
  })

  if (!localStorage.lang) {
    localStorage.lang = 'en'
  } else {
    loadLanguageAsync(localStorage.lang)
  }

  // Hot updates
  // if (module.hot) {
  //     module.hot.accept(context.id, () => {
  //     const { messages: newMessages } = loadMessages();

  //     Object.keys(newMessages)
  //         .filter((locale) => messages[locale] !== newMessages[locale])
  //         .forEach((locale) => {
  //         messages[locale] = newMessages[locale];
  //         i18n.setLocaleMessage(locale, messages[locale]);
  //         });
  //     });
  // }

  return i18n
}
