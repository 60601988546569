<template>
  <div class="pt-2">
    <v-text-field
      @keyup="e => props.search(props.debounce)(e)"
      :class="props.theme.input"
      :name="props.getColumnName(props.column)"
      :value="props.query[props.column]"
      type="text"
      :placeholder="props.display('filterBy', {column: props.getHeading(props.column)})"
      autocomplete="off"
      dense
    />
  </div>
</template>

<script>
export default {
  name: 'VtTextFilter',
  props: {
    props: {
      type: Object,
      default: null
    }
  }
  // mounted () {
  //     console.log(this, this.props);
  // }
}
</script>
